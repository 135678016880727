import React, { useState, useEffect, useRef } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo_pdf from './josh_logo_dup.png';
import checkmarkImage from './Correct.png';
import checkmarkImageFalse from './Cross.png';
import styled from 'styled-components';
import './background_checklists.css';
import Helmet from 'react-helmet';
import imgd from './bkg.webp';
import './index.css';
import { useNavigate } from 'react-router-dom';

const ButtonSelect = styled.button({
  color: 'grey',
  background: 'white',
  width: "36%",
  height: "4.5vh",
  'border-radius': '0.2em',
  border: '1px solid purple',
  color:'purple',
  'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
  'font-size': '100%',
  margin: '0 2em 0 2em',
  // '&:hover':{background: 'gold'},
  cursor: 'pointer', 
  zIndex:'3',
  '&:hover':{transform: 'translateY(5px)'},
  // '&:selection':{background: 'gold'}, 
  '&:active':{transform: 'translateY(4px)'},
  'box-shadow': '0.18em 0.22em black'
});
const ButtonSelect2 = styled.button({
  color: 'grey',
  background: 'white',
  width: "60%",
  height: "4.5vh",
  'border-radius': '0.2em',
  border: '1px solid purple',
  color:'purple',
  'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
  'font-size': '100%',
  margin: '0 2em 0 2em',
  // '&:hover':{background: 'gold'},
  cursor: 'pointer', 
  zIndex:'3',
  '&:hover':{transform: 'translateY(5px)'},
  // '&:selection':{background: 'gold'}, 
  '&:active':{transform: 'translateY(4px)'},
  'box-shadow': '0.18em 0.22em black'
});

const ChecklistPageHomeList = () => {
  const [answers, setAnswers] = useState({
    Howlong: [],

  });
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [activeQuestioncount, setActiveQuestioncount] = useState(0);
  const completedQuestions = useRef([])
  const [skipQuestion, setSkipQuestion] = useState(false);
  const [activeQuestions, setActiveQuestions] = useState(['Howlong']); // Set the initial active questions
  // const [basicQuestions, setBasicQuestions] = useState(['Travelmode', 'BabyMode', 'Lights','Refrigerator']);
  const [showQuestion, setShowQuestion] = useState(false);
  const [introductory, setIntroductory] = useState(true);
  const [showWaiting, setShowWaiting] = useState(false);
  const [complete, setComplete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const container = document.querySelector('.checklist-container');
    container.classList.add('fade-in');

    return () => {
      container.classList.remove('fade-in');
    };
  }, [currentQuestion]);


  // const generatePDF = () => {
  //   const doc = new jsPDF();
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   let yPos = 20;
  
  //   activeQuestions.forEach((questionId) => {
  //     const question = questions.find((q) => q.id === questionId);
  //     if (!question) return;
  
  //     const answer = answers[questionId];
  //     const checkmark = answer ? '\u2714' : '\u2718';
  //     const color = answer ? 'green' : 'red';
  
  //     doc.setFontSize(12);
  //     doc.setTextColor(color);
  
  //     let questionText = `${checkmark} ${question.text}`;
  //     if (question.multi && Array.isArray(answer) && answer.length > 0) {
  //       const selectedAnswers = answer.join(', ');
  
  //       const textWidth = doc.getStringUnitWidth(questionText); // Calculate question text width
  //       const availableWidth = pageWidth - 20; // Subtracting left margin
  
  //       // Check if the question text exceeds the available width
  //       if (textWidth > availableWidth) {
  //         doc.setFontSize(10); // Reduce font size for longer text
  //         doc.text(20, yPos, questionText, { maxWidth: availableWidth }); // Wrap text to new lines
  //         yPos += 5; // Adjust line spacing
  //       } else {
  //         doc.text(20, yPos, questionText); // Print question text
  //       }
  
  //       doc.setFontSize(12); // Reset font size for selected answers
  //       doc.text(20, yPos + 10, selectedAnswers, { maxWidth: availableWidth }); // Print selected answers with wrap
  //       yPos += 20; // Adjust line spacing
  //     } else {
  //       doc.text(20, yPos, questionText); // Print question text
  //       yPos += 10; // Adjust line spacing
  //     }
  //   });
  
  //   doc.save('answers.pdf');
  // };



  
  // const generatePDF = () => {
  //   const doc = new jsPDF();
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const pageHeight = doc.internal.pageSize.getHeight();
  //   let yPos = 20;
  
  //   // Add logo to the first page
  //   const logoPath = 'image.png';
  //   const logoSize = 60;
  //   const logoX = (pageWidth - logoSize) / 2;
  //   const logoY = yPos;
  //   doc.addImage(logo_pdf, 'PNG', logoX, logoY, logoSize, logoSize);
  
  //   // Set font and size for the title on the first page
  //   doc.setFont('Helvetica', 'bold');
  //   doc.setFontSize(20);
  //   doc.setTextColor('#333');
  //   doc.text('Questionnaire Answers', pageWidth / 2, logoY + logoSize + 20, { align: 'center' });
  
  //   // Add space between logo and questions
  //   yPos += logoSize + 50;
  
  //   activeQuestions.forEach((questionId) => {
  //     const question = questions.find((q) => q.id === questionId);
  //     if (!question) return;
  //     const checkmarkSymbol = '\u2713';
  //     const answer = answers[questionId];
  //     const checkmark = answer ? '\u2713' : 'X'; 
  //     const color = (answer==='yes'||answer===true) ? 'green' : question.multi?'black':'red';
  
  //     doc.setFont('arial', 'normal');
  //     doc.setFontSize(12);
  //     doc.setTextColor(color);
  
  //     let questionText = checkmark + ' ' + question.text;
  //     if (question.multi && Array.isArray(answer) && answer.length > 0) {
  //       const selectedAnswers = answer.join(', ');
  
  //       const textWidth = doc.getStringUnitWidth(questionText);
  //       const availableWidth = pageWidth - 20;
  
  //       if (textWidth > availableWidth) {
  //         doc.setFontSize(10);
  //         doc.text(20, yPos, questionText, { maxWidth: availableWidth });
  //         yPos += 5;
  //       } else {
  //         doc.text(20, yPos, questionText);
  //       }
  
  //       doc.setFontSize(12);
  //       doc.text(20, yPos + 10, selectedAnswers, { maxWidth: availableWidth });
  //       yPos += 20;
  //     } else {
  //       doc.text(20, yPos, questionText);
  //       yPos += 10;
  //     }
  //   });
  
  //   doc.save('answers.pdf');
  // };
  
  
  // const generatePDF = () => {
  //   const doc = new jsPDF();
  //   var footerText = 'Time of itinerary generation:'+ new Date()
  //   var fontSize = 20;
  //   var lineHeight = fontSize * 1.0;
  //   var addFooter = function() {
  //     var pageCount = doc.internal.getNumberOfPages();
  //     for(var i = 1; i <= pageCount; i++) {
  //       doc.setPage(i);
  //       var footerX = doc.internal.pageSize.width / 2;
  //       var footerY = doc.internal.pageSize.height - (lineHeight * 2);
  //       doc.setFontSize(4);
  //       doc.text(footerText, footerX, footerY, 'center');
  //     }
  //   };

  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const pageHeight = doc.internal.pageSize.getHeight();
  //   let yPos = 20;
  
  //   // Add logo to the first page
  //   const logoPath = 'image.png';
  //   const logoSize = 60;
  //   const logoX = (pageWidth - logoSize) / 2;
  //   const logoY = yPos;
  //   doc.addImage(logo_pdf, 'PNG', logoX, logoY, logoSize, logoSize);
  
  //   // Set font and size for the title on the first page
  //   doc.setFont('Helvetica', 'bold');
  //   doc.setFontSize(20);
  //   doc.setTextColor('#333');
  //   doc.text('Questionnaire Answers', pageWidth / 2, logoY + logoSize + 20, { align: 'center' });
  
  //   // Add space between logo and questions
  //   yPos += logoSize + 50;
  
  //   const checkmarkSize = 10; // Size of the checkmark image
  
  //   activeQuestions.forEach((questionId) => {
  //     const question = questions.find((q) => q.id === questionId);
  //     if (!question) return;
  
  //     const answer = answers[questionId];
  //     const checkmark = answer ? checkmarkImage : 'X'; // Use checkmark image or 'X' for cross
  //     const color = (answer==='yes'||answer===true) ? 'green' : question.multi?'black':'red';
  
  //     doc.setFont('Helvetica', 'normal');
  //     doc.setFontSize(12);
  //     doc.setTextColor(color);
  
  //     const checkmarkX = 20; // X position of the checkmark image
  //     const checkmarkY = yPos + 4; // Y position of the checkmark image
  //     const checkmarkWidth = checkmarkSize; // Width of the checkmark image
  
  //     if (answer==='yes'||answer===true) {
  //       doc.addImage(checkmarkImage, checkmarkX, checkmarkY, checkmarkWidth, checkmarkSize);
  //     } else {

  //       if (!question.multi){
  //           doc.addImage(checkmarkImageFalse, checkmarkX, checkmarkY, checkmarkWidth, checkmarkSize);
  //       }
  //     }
  
  //     const questionTextX = checkmarkX + checkmarkWidth + 5; // X position of the question text
  //     const questionTextY = yPos + 10; // Y position of the question text
  
  //     if (question.multi && Array.isArray(answer) && answer.length > 0) {
  //       const selectedAnswers = answer.join(', ');
  
  //       const textWidth = doc.getStringUnitWidth(selectedAnswers);
  //       const availableWidth = pageWidth - questionTextX - 10;
  
  //       if (textWidth > availableWidth) {
  //         doc.setFontSize(10);
  //         doc.text(questionTextX, questionTextY, question.text, { maxWidth: availableWidth });
  //         yPos += 5;
  //       } else {
  //         doc.text(questionTextX, questionTextY, question.text);
  //       }
  
  //       doc.setFontSize(12);
  //       doc.text(questionTextX, questionTextY + 10, selectedAnswers, { maxWidth: availableWidth });
  //       yPos += 20;
  //     } else {
  //       doc.text(questionTextX, questionTextY, question.text);
  //       yPos += 10;
  //     }
  //   });
  
  //   doc.save('answers.pdf');
  // };
  


const generatePDF = () => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  let yPos = 20;

  // Add logo to the first page
  const logoPath = 'image.png';
  const logoSize = 60;
  const logoX = (pageWidth - logoSize) / 2;
  const logoY = yPos;
  doc.addImage(logo_pdf, 'PNG', logoX, logoY, logoSize, logoSize);

  // Set font and size for the title on the first page
  doc.setFont('Helvetica', 'bold');
  doc.setFontSize(20);
  doc.setTextColor('#333');
  doc.text('Questionnaire Answers', pageWidth / 2, logoY + logoSize + 20, { align: 'center' });

  // Add space between logo and questions
  yPos += logoSize + 50;

  const checkmarkSize = 10; // Size of the checkmark image

  activeQuestions.forEach((questionId) => {
    const question = questions.find((q) => q.id === questionId);
    if (!question) return;

    const answer = answers[questionId];
    const checkmark = answer ? checkmarkImage : 'X'; // Use checkmark image or 'X' for cross
    const color = (answer==='yes'||answer===true) ? 'green' : question.multi?'black':'red';

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(12);
    doc.setTextColor(color);

    const checkmarkX = 20; // X position of the checkmark image
    const checkmarkY = yPos + 4; // Y position of the checkmark image
    const checkmarkWidth = checkmarkSize; // Width of the checkmark image

    if (answer==='yes'||answer===true) {
      doc.addImage(checkmarkImage, checkmarkX, checkmarkY, checkmarkWidth, checkmarkSize);
    } else {

      if (!question.multi){
          doc.addImage(checkmarkImageFalse, checkmarkX, checkmarkY, checkmarkWidth, checkmarkSize);
      }
    }

    const questionTextX = checkmarkX + checkmarkWidth + 5; // X position of the question text
    const questionTextY = yPos + 10; // Y position of the question text

    if (question.multi && Array.isArray(answer) && answer.length > 0) {
      const selectedAnswers = answer.join(', ');

      const textWidth = doc.getStringUnitWidth(selectedAnswers);
      const availableWidth = pageWidth - questionTextX - 10;

      if (textWidth > availableWidth) {
        doc.setFontSize(10);
        if (question.multi || (answer==='yes'||answer===true)){
        doc.text(questionTextX, questionTextY, question.response, { maxWidth: availableWidth });
        }
        // else if (answer==='n/a'){
        //   doc.text(questionTextX, questionTextY, question.responsefalse, { maxWidth: availableWidth }); 
        //   }
        else{
        doc.text(questionTextX, questionTextY, question.responsefalse, { maxWidth: availableWidth }); 
        }

        yPos += 5;
      } else {
        if (question.multi || (answer==='yes'||answer===true)){
          doc.text(questionTextX, questionTextY, question.response);
          }
          else{
          doc.text(questionTextX, questionTextY, question.responsefalse); 
          }
      }

      doc.setFontSize(12);
      doc.text(questionTextX, questionTextY + 10, selectedAnswers, { maxWidth: availableWidth });
      yPos += 20;
    } else {
      if (question.multi || (answer==='yes'||answer===true)){
        doc.text(questionTextX, questionTextY, question.response);
        }
        else{
        doc.text(questionTextX, questionTextY, question.responsefalse); 
        }
      yPos += 10;
    }

    // Check if there's enough space for the footer
    if (yPos > pageHeight - 30) {
      doc.addPage();
      yPos = 20;
    }
  });


// Add footer to every page
const disclaimer = 'Disclaimer: Fivsix.com and its parent company \'NKCHAKSHU UK LTD\', along with any related entities, shall not be held responsible for any issues arising from the use of the checklists provided on this website. For detailed legal information, please refer to the full legal disclaimer on this website. This concise disclaimer serves as a summary and reminder that the Company is not liable for any consequences resulting from the use of the checklists. Please read the full legal disclaimer for complete details.';
const timestamp = new Date().toLocaleString('en-US', { timeZone: 'UTC' });;

const footerX = 10; // X position of the footer
const footerY = pageHeight - 10; // Y position of the footer
const maxFooterWidth = pageWidth - 20; // Maximum width of the footer

doc.setFont('Helvetica', 'normal');
doc.setFontSize(6);
doc.setTextColor('#999');
doc.text(`Generated on (UTC): ${timestamp}`, 5, pageHeight/2, { align: 'left', maxWidth: maxFooterWidth, angle: 90, valign: 'middle' });


doc.setFont('Helvetica', 'italic');
doc.setFontSize(7);
doc.setTextColor('#999');
doc.text(disclaimer, footerX, footerY, { align: 'left', maxWidth: maxFooterWidth });



  doc.save('questionnaire.pdf');
};

  
  // useEffect(() => {
  //   // Filter and set the active questions based on their availability
  //   setActiveQuestions(questions.filter(question => answers[question.id] === null).map(question => question.id));
  // }, [answers]);

  const findActiveQuestionsByPrefix = (questions, prefix) => {
    const activeQuestionsLocal = questions.filter(question => question.id.startsWith(prefix));
    if (activeQuestionsLocal !==[]){
    return activeQuestionsLocal.map(question => question.id);
    }
    else{
      return null
    }
  };
  // const activeQuestions = findActiveQuestionsByPrefix(questions, 'x');  
  const removeDuplicates = (list) => {
    const uniqueList = Array.from(new Set(list));
    return uniqueList;
  };
  const handleAnswerChange = (question, answer, type, checked) => {
    if (type==='multi'){
      setAnswers(prevAnswers => {
        console.log(prevAnswers)
        console.log(answer)
        console.log(prevAnswers[question])
        if (prevAnswers[question].includes(answer) && !checked) {
          // Remove the answer from the array
          const updatedAnswers = prevAnswers[question].filter(a => a !== answer);
          return {
            ...prevAnswers,
            [question]: updatedAnswers,
          };
        } else {
          // Add the answer to the array
          return {
            ...prevAnswers,
            [question]: [...prevAnswers[question], answer],
          };
        }
      });
  }
  else{
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [question]: answer,
    }));
  }
    console.log(answers);
  };
  const removeValuesFromList = (list, valuesToRemove) => {
    const updatedList = list.filter(item => !valuesToRemove.includes(item));
    return updatedList;
  };
  
  const handleNextQuestion = () => {
    setShowQuestion(false);
    setShowWaiting(true);
    completedQuestions.current.push(currentQuestion);
    setTimeout(() => {
      if (activeQuestioncount < activeQuestions.length - 1){
        var i = currentQuestion;
        while (i < questions.length - 1){
          console.log(currentQuestion)
          console.log('fjk')
        if (activeQuestions.includes(questions[i + 1].id)) {
          setActiveQuestioncount(prevQuestion => prevQuestion + 1);
          i++
          break;
        }
        else{
          console.log(currentQuestion);
          i++;
          
        }
      }
      if (questions[currentQuestion].multi){
        const localActiveqs = []
        const removeOlds = []
        activeQuestions.map(qs=>localActiveqs.push(qs))
        answers[questions[currentQuestion].id].map(categories => (findActiveQuestionsByPrefix(questions, categories).map(found => localActiveqs.push(found))));
        questions[currentQuestion].ans.map(categories => ((!answers[questions[currentQuestion].id].includes(categories))? findActiveQuestionsByPrefix(questions, categories).map(found => removeOlds.push(found)):null));
        const uniqueList = removeDuplicates(localActiveqs);
        console.log(removeOlds)
        const uniqueList2 = removeValuesFromList(uniqueList, removeOlds)
        setActiveQuestions(uniqueList2)
      }
      // setCurrentQuestion(prevQuestion => prevQuestion + 1);
      setCurrentQuestion(i);
      setShowWaiting(false);
      setShowQuestion(true);
      
    }
      else{
        setComplete(true);
      }
      console.log(activeQuestions)
      console.log(questions[currentQuestion])
    }, 500); // Wait for the fade-out animation to complete (0.5 seconds)
  };

  const handlePreviousQuestion = () => {
    setShowQuestion(false);
    setShowWaiting(true);
    setTimeout(() => {
      var i = currentQuestion;
      while (i > 0){
        if (activeQuestions.includes(questions[i - 1].id)) {
          setActiveQuestioncount(prevQuestion => prevQuestion - 1);
          i--
          break;
          }
        else{
          i--;
        }
      }
      setCurrentQuestion(i);
      setShowWaiting(false);
      setShowQuestion(true);
    }, 500); // Wait for the fade-out animation to complete (0.5 seconds)
  };

  const handleIntroductory = () => {
    setIntroductory(false);
    setShowQuestion(true);
  }

  const questions = [
    {
      id: 'Howlong',
      text: 'How long will your trip be?',
      multi: true,
      single: false,
      ans: ['Day trip', 'Short trip', 'Week long', 'Extremely long'],
      anshidden: ['House', 'House', 'House', 'House'],
      response: 'Your selected:',
      responsefalse: 'Please secure all doors and windows.',
      additionaldata: '',
      adLabel: 'None',
    },
      {
        id: 'HouseSecurity',
        text: 'Secured all doors and windows?',
        multi: false,
        single: true,
        ans: [],
        response: 'Doors and windows secured.',
        responsefalse: 'Please secure all doors and windows.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseAppliances',
        text: 'Turned off and unplugged appliances (e.g., TV, computer)?',
        multi: false,
        single: true,
        ans: [],
        response: 'Appliances turned off and unplugged.',
        responsefalse: 'Please turn off and unplug all appliances.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseThermostat',
        text: 'Adjusted thermostat to an energy-saving setting?',
        multi: false,
        single: true,
        ans: [],
        response: 'Thermostat adjusted to an energy-saving setting.',
        responsefalse: 'Please adjust the thermostat to an energy-saving setting.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseLights',
        text: 'Turned off all lights?',
        multi: false,
        single: true,
        ans: [],
        response: 'Lights turned off.',
        responsefalse: 'Please turn off all lights.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseWater',
        text: 'Turned off main water supply?',
        multi: false,
        single: true,
        ans: [],
        response: 'Main water supply turned off.',
        responsefalse: 'Please turn off the main water supply.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseGas',
        text: 'Turned off gas supply (if applicable)?',
        multi: false,
        single: true,
        ans: [],
        response: 'Gas supply turned off.',
        responsefalse: 'Please turn off the gas supply.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseSecuritySystem',
        text: 'Enabled home security system (if available)?',
        multi: false,
        single: true,
        ans: [],
        response: 'Home security system enabled.',
        responsefalse: 'Please enable the home security system.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseMail',
        text: 'Arranged mail forwarding or requested a neighbor to collect it?',
        multi: false,
        single: true,
        ans: [],
        response: 'Mail forwarding arranged or neighbor requested.',
        responsefalse: 'Please arrange mail forwarding or request a neighbor to collect it.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HousePlants',
        text: 'Arranged for plant care (watering, etc.)?',
        multi: false,
        single: true,
        ans: [],
        response: 'Plant care arranged.',
        responsefalse: 'Please arrange for plant care (watering, etc.).',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseFridge',
        text: 'Emptied and cleaned out the refrigerator?',
        multi: false,
        single: true,
        ans: [],
        response: 'Refrigerator emptied and cleaned out.',
        responsefalse: 'Please empty and clean out the refrigerator.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseTrash',
        text: 'Disposed of all trash and garbage?',
        multi: false,
        single: true,
        ans: [],
        response: 'Trash and garbage disposed of.',
        responsefalse: 'Please dispose of all trash and garbage.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseLaundry',
        text: 'Finished all laundry and put it away?',
        multi: false,
        single: true,
        ans: [],
        response: 'Laundry finished and put away.',
        responsefalse: 'Please finish all laundry and put it away.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseWindowsCoverings',
        text: 'Closed curtains/blinds for privacy and energy savings?',
        multi: false,
        single: true,
        ans: [],
        response: 'Curtains/blinds closed.',
        responsefalse: 'Please close curtains/blinds for privacy and energy savings.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseValuables',
        text: 'Stored valuable items securely?',
        multi: false,
        single: true,
        ans: [],
        response: 'Valuable items stored securely.',
        responsefalse: 'Please store valuable items securely.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseEmergencyContact',
        text: 'Provided emergency contact information to a trusted person?',
        multi: false,
        single: true,
        ans: [],
        response: 'Emergency contact information provided.',
        responsefalse: 'Please provide emergency contact information to a trusted person.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseApartment',
        text: 'Notified building management (if living in an apartment)?',
        multi: false,
        single: true,
        ans: [],
        response: 'Building management notified.',
        responsefalse: 'Please notify building management (if living in an apartment).',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseNeighbors',
        text: 'Informed trustworthy neighbors about your absence?',
        multi: false,
        single: true,
        ans: [],
        response: 'Trustworthy neighbors informed.',
        responsefalse: 'Please inform trustworthy neighbors about your absence.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseFireAlarm',
        text: 'Checked smoke detectors and replaced batteries if needed?',
        multi: false,
        single: true,
        ans: [],
        response: 'Smoke detectors checked and batteries replaced if needed.',
        responsefalse: 'Please check smoke detectors and replace batteries if needed.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseKeys',
        text: 'Stored spare keys in a secure place?',
        multi: false,
        single: true,
        ans: [],
        response: 'Spare keys stored in a secure place.',
        responsefalse: 'Please store spare keys in a secure place.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HousePets',
        text: 'Arranged for pet care (feeding, walking, etc.)?',
        multi: false,
        single: true,
        ans: [],
        response: 'Pet care arranged.',
        responsefalse: 'Please arrange for pet care (feeding, walking, etc.).',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseAlarmSystem',
        text: 'Activated home alarm system (if available)?',
        multi: false,
        single: true,
        ans: [],
        response: 'Home alarm system activated.',
        responsefalse: 'Please activate the home alarm system.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseGarage',
        text: 'Locked the garage and ensured it\'s secure?',
        multi: false,
        single: true,
        ans: [],
        response: 'Garage locked and secure.',
        responsefalse: 'Please lock the garage and ensure it\'s secure.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseElectronics',
        text: 'Turned off and unplugged electronic devices (e.g., gaming consoles, chargers)?',
        multi: false,
        single: true,
        ans: [],
        response: 'Electronic devices turned off and unplugged.',
        responsefalse: 'Please turn off and unplug electronic devices.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseFreezer',
        text: 'Checked and cleaned out the freezer?',
        multi: false,
        single: true,
        ans: [],
        response: 'Freezer checked and cleaned out.',
        responsefalse: 'Please check and clean out the freezer.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseBathroom',
        text: 'Closed and sealed drains in the bathroom?',
        multi: false,
        single: true,
        ans: [],
        response: 'Drains in the bathroom closed and sealed.',
        responsefalse: 'Please close and seal drains in the bathroom.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'HouseInsurance',
        text: 'Ensured home insurance coverage is up to date?',
        multi: false,
        single: true,
        ans: [],
        response: 'Home insurance coverage up to date.',
        responsefalse: 'Please ensure home insurance coverage is up to date.',
        additionaldata: '',
        adLabel: 'None',
      },
  
    
  ];
  useEffect(() => {
    // Run the code when the component mounts or reloads
    let klp = ['Howlong'];
    findActiveQuestionsByPrefix(questions, 'House').map((obj) => klp.push(obj));
    console.log(klp);
    setActiveQuestions(klp);
  }, []); 
  // setActiveQuestions(klp);

  const returnHomepage = () => {
    let path = '/checklists_homepage';
    navigate(path);
  }

  const renderQuestion = (question, index) => {
    const { id, text, multi, ans, single } = question;
    const answer = answers[id];

    if (!activeQuestions.includes(id)) {
      console.log(id);
      return null; // Skip rendering if the question is not in activeQuestions
    }

        return (
      <div key={id}>
        <h3>{text}</h3>
        {multi?
         single?
        <form>
          {ans.map(val=>
          <label key={val}>
            <input
              type="radio"
              name={id}
              value={val}
              checked={answer.includes(val)}
              // checked={answer === 'yes'}
              onChange={(e) => (handleAnswerChange(id, e.target.checked),handleNextQuestion)}
            />
            {val}
          </label>)
          }
        </form>
        :
        <form>
        {ans.map(val=>
        <label key={val}>
          <input
            type="checkbox"
            // name={id}
            value={val}
            checked={answer.includes(val)}
            // checked={answer === 'yes'}
            onChange={(e) => handleAnswerChange(id, e.target.value, 'multi', e.target.checked)}
          />
          {val}
        </label>)}
        {/* <input type="checkbox" value='House' checked={true} hidden={true} onLoad={(e) => handleAnswerChange(id, e.target.value, 'multi', e.target.checked)}/> */}
      </form>
        :
        <div>
          <form>
          <label>
            <input
              type="radio"
              name={id}
              value="yes"
              checked={answer === 'yes'}
              onChange={(e) => (handleAnswerChange(id, 'yes'), handleNextQuestion())}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name={id}
              value="no"
              checked={answer === 'no'}
              onChange={(e) => (handleAnswerChange(id, 'no'), handleNextQuestion())}
            />
            No
          </label>
          <label>
            <input
              type="radio"
              name={id}
              value="n/a"
              checked={answer === 'n/a'}
              onChange={(e) => (handleAnswerChange(id, 'n/a'), handleNextQuestion())}
            />
            N/A
          </label>
          </form>
        </div>
        
        }
      </div>
    );
  };

  // return (
  //   <div className="checklist-container">
  //     {showQuestion && renderQuestion(questions[currentQuestion], currentQuestion)}
  //     <button disabled={currentQuestion === 0} onClick={handlePreviousQuestion}>
  //       Back
  //     </button>
  //     {currentQuestion < activeQuestions.length - 1 ? (
  //       <button onClick={handleNextQuestion}>Next</button>
  //     ) : (
  //       <button>Submit</button>
  //     )}
  //   </div>
  // );
  const yht = {
    background: 'bkg.webp'
  }
  const [backgroundImage, setBackgroundImage] = React.useState('./ijk.webp');
  const rft = `url('/Users/neerajkavanchakshu/My Drive/R&D/TravelAI/Website/frontend/src/ijk.webp')`
const introductoryMessage = 'Scientific checklist to help you secure home before leaving for holidays.'
const introductoryTitle = ' Secure home gist-a-list'
const introductoryWarning =  'By clicking on Start, you confirm that you have read and agreed to our Terms and Conditions, as well as our disclaimers.'
const PageContainer = styled.div`
  background-image: url(${props => props.backgroundImage});
  /* Additional styles for the page container */
`;
  if (complete){
    return(
      <div className="checklist-container">
      <Helmet bodyAttributes={{style: `background-color: ${rft}`}}/>
      <h1>Hooray!</h1>
      <br></br>
      Gist-a-list Complete! Enjoy your trip!

      <ButtonSelect2 onClick={generatePDF}>Download PDF</ButtonSelect2>
      <ButtonSelect2 onClick={returnHomepage}>Use other gist-a-lists</ButtonSelect2>
      </div>
        

    )
  }

  return (
<div>
<Helmet bodyAttributes={{style: `background-image : url('bkg.webp')`}}/>
   {/* <Helmet>
             <style>
                {`
                   html, body {
                       height: 100%;
                  }

                 body {
                    background-image: url("bkg.webp");
                    background-size: 80% 100%, 101% 100%;
                    background-repeat: no-repeat;
                    background-position: 1% 60%, 80% 110%;
                  }
                `}
            </style>
         </Helmet> */}
         
    <div className="checklist-container">
      {showWaiting &&
    <div class="lds-ripple"><div></div><div></div></div>
      }
      
      {introductory && <h3>{introductoryTitle}</h3>}
      {introductory && <div><h5>{introductoryMessage}</h5><br></br><br></br></div>}
      {introductory && <ButtonSelect onClick={handleIntroductory}>Start</ButtonSelect>}

      {introductory && <p className='fineprint'>{introductoryWarning}</p>}

      {showQuestion && renderQuestion(questions[currentQuestion], currentQuestion)}


      { showQuestion &&
      (((activeQuestioncount < activeQuestions.length - 1) && questions[currentQuestion].multi) || completedQuestions.current.includes(currentQuestion)) ? (
        <ButtonSelect onClick={handleNextQuestion}>Next</ButtonSelect>
      ) :  showQuestion && questions[currentQuestion].multi? (
        <ButtonSelect onClick={handleNextQuestion}>
          {(activeQuestioncount < activeQuestions.length - 1) ? 'Next' : 'Submit'}
        </ButtonSelect>
        
      ):null}
            {
      showQuestion && currentQuestion>0?
      <ButtonSelect disabled={currentQuestion === 0} onClick={handlePreviousQuestion}>
        Back
      </ButtonSelect>:null
      }
      

    
    </div>
    </div>
  );
 

};

export default ChecklistPageHomeList;



