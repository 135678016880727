import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import './bootstrap-grid.css'

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
//   const navigate = useNavigate();

//   const handleClick = (link) => {
//     let path = link; 
//     navigate(path);
//   };

  const carouselItems = [
    { imageSrc: 'landing_page_1.webp', pageLink: '/checklists', pageName: 'The compulsory essential gist-a-list' },
    { imageSrc: 'Landing_page_2.webp', pageLink: '/checklists_packing', pageName: 'The packing gist-a-list' },
    { imageSrc: 'secure_home_1_PNG.png', pageLink: '/checklists_homelist', pageName: 'The secure home before leaving '},
  ];

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? carouselItems.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1));
  };
  useEffect(() => {
    const interval = setInterval(goToNext, 5000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="carousel">
      <button className="arrow previous" onClick={goToPrevious}>
        &lt;
      </button>

      <div className="carousel-items" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {carouselItems.map((item, index) => (
          <div key={index} className="carousel-item">
            <img src={item.imageSrc} alt={`Image ${index + 1}`} />
            {/* <a href={item.pageLink} className="carousel-button"> */}
            <Link to={item.pageLink} className="carousel-button">{item.pageName}</Link>
              {/* Page {index + 1} */}
              {/* {item.pageName} */}
            {/* </a> */}
          </div>
        ))}
      </div>

      <button className="arrow next" onClick={goToNext}>
        &gt;
      </button>
    </div>
  );
};

export default Carousel;

