import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// import Helmet from 'react-helmet';
import LazyLoad from 'react-lazy-load';
import logo from './josh_logo_dup.webp';

const ButtonSelect = styled.button({
  color: 'grey',
  background: 'white',
  width: "100%",
  height: "110%",
  'border-radius': '0.5em',
  border: '1px solid purple',
  color:'purple',
  'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
  'font-size': '100%',
  margin: '3em 2em 0 2em',
  padding: '1em 0em 1em 0em',
  '&:hover':{background: 'gold'},
  '&:hover':{transform: 'translateY(2px)'},
  '&:selection':{background: 'gold'}, 
  '&:active':{transform: 'translateY(4px)'},
  'box-shadow': '0.18em 0.22em black',
  textAlign:'center',
  alignContent:'center',
  justifyContent:'center'
});

const ChecklistsMenuPage = () => {
  const navigate = useNavigate();
  const handleButtonClick = (option) => {
    console.log(`Selected option: ${option}`);
    if (option==='ck1'){
      let path = '/checklists'; 
      navigate(path);

    }
    else if (option==='ck2'){
      let path = '/checklists_homelist';
      navigate(path);
    }
    else if (option==='ck3'){
      let path = '/checklists_packing';
      navigate(path);
    }
    // Perform additional actions based on the selected option
  };

  return (
  <div>
  <LazyLoad>
  <img class="main_image" src={logo}/>
  </LazyLoad>
    {/* <Helmet bodyAttributes={{style: `background-image : url('checklist_homepage.webp')`}}/> */}
<div className='checklist-container-homepage'>
      <div style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
        <h2>Travel Gist-A-Lists</h2>
        <h3>Hot scientific checklists to make your travel smooth</h3>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign:'center' }}>
          <ButtonSelect onClick={() => handleButtonClick('ck1')}>
            The essential travel gist-a-list
          </ButtonSelect>
          <ButtonSelect onClick={() => handleButtonClick('ck2')}>
            Secure home before you leave
          </ButtonSelect>
          <ButtonSelect onClick={() => handleButtonClick('ck3')}>
            Packing gist-a-list
          </ButtonSelect>
        </div>
      </div>
    </div>
  </div>
  );
};

export default ChecklistsMenuPage;
