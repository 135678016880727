export function getUserName() {
    const username = sessionStorage.getItem('username');
    if (!username || username === 'undefined' || username === undefined) {
        return null;
    }
    else {
        return JSON.parse(username);
    }
}
export function getRevolvingID() {
    const rid = sessionStorage.getItem('rid');
    if (!rid || rid === 'undefined' || rid === undefined) {
        return null;
    }
    else {
        return JSON.parse(rid);
    }
}
export function getToken() {
    return sessionStorage.getItem('token');
}
export function getMoolah() {
    return sessionStorage.getItem('moolah');
}
export function setUserSession(username, rid, token, moolah) {
    sessionStorage.setItem('username', JSON.stringify(username));
    sessionStorage.setItem('rid', JSON.stringify(rid));
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('moolah', moolah);
}
export function resetUserSession() {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('rid');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('moolah');
    window.location.reload(false);
}