import React, {useRef, useState, useEffect} from 'react';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import {loadHistory, setHistory} from "./service/itihasa";
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import tp_full from './tp_full.jpg';
import { getToken, getUserName, getRevolvingID, setUserSession} from "./service/AuthService";
const askUrl = 'https://66plz5argg.execute-api.eu-west-2.amazonaws.com/beta/api/v1/itihasakelu/';
const setUrl = 'https://66plz5argg.execute-api.eu-west-2.amazonaws.com/beta/api/v1/itihasahaku/';

const ButtonSelect = styled.button({
  color: 'grey',
  background: 'transparent',
  width: "30%",
  height: "110%",
  'border-radius': '0.2em',
  border: '1px solid purple',
  color:'purple',
  'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
  'font-size': '100%',
  margin: '0 2em 0 2em',
  '&:hover':{background: 'gold'},
  '&:hover':{transform: 'translateY(2px)'},
  '&:selection':{background: 'gold'}, 
  '&:active':{transform: 'translateY(4px)'},
  'box-shadow': '0.18em 0.22em black'
});

const User = () => {
  const username = getUserName();
  const localToken = getToken();
  const revolvingID = getRevolvingID();
  const [sessionInvalid, setSessionInvalid] = useState(false);
  const loadingContent = useRef(true);
  const contentEmpty= useRef(true);
  const content = useRef([])
  const navigate = useNavigate();
  let home = '/';
  
  const OldLoadHandler = (event) => {
    sessionStorage.setItem('contentSelect', JSON.stringify(content.current[event.target.value].Itinerary));
    let path = '/resultsold';
    // navigate(path); 
    console.log(event.target.value)
    navigate(path); 

  }
  const planHandler = (event) => {navigate(home);}

  useEffect(() => {

    loadHistory(username, revolvingID, localToken).then(()=>{

    
    setSessionInvalid(sessionStorage.getItem('sessionInvalid'));
    console.log(sessionInvalid);
    contentEmpty.current = sessionStorage.getItem('contentEmptyFlag');
    console.log(sessionStorage.getItem('contentEmptyFlag'));
    console.log(contentEmpty.current);
    content.current = JSON.parse(sessionStorage.getItem('content'));
    console.log(content.current);
    loadingContent.current = false;})
  }, []);



    if (sessionInvalid===true){
    return(
      <div>It's not you, it's us! Your session has expired. Please login again.</div>
    )
    }
    else{
    return (
    <div>
      
    <div className='home-logo'>
      {/* <div className='home2-outer'>
      <div className='home2-user'> */}
    <span className='heading-h1-user'> Welcome {username} !<br></br></span>
    {/* </div>
    </div> */}
    </div>
    <br></br>
    <div className='home2-outer'>
      <div className='home2-user'>
    <span className='heading-h10 home2-outer'>Wanna go somewhere?</span><br></br>
      <span className='home2-outer'><ButtonSelect onClick={planHandler}>Let's plan</ButtonSelect></span>
      </div>
      </div>

    <div className='home2-outer-user'>
  
 

    <div className='home2-user'>
      <p className='heading-h1'>Your saved trips</p>
    { 
      loadingContent.current?
      <p>Loading your trips ...</p>
      :<div>
        { contentEmpty.current==='true'?<p>No itineraries saved</p> :
        (<div>
     
        {/* <form> */}
        {
          
          content.current.map((trips, index)=>(
            console.log(trips.Title),
            console.log('olaola'),

            <label className='container-home2'><input type='radio' onClick={OldLoadHandler} name="tp" value={index}/><figure className='menu-figure'><img class='menu-logo' src={tp_full}/> <figcaption>{trips.Date}<br></br>{trips.Title}<br></br></figcaption></figure> </label>
            
            

            
         
          ))
        }
        
        {/* </form> */}
        </div>
    )
    }
      </div>

    }
    </div>
    </div>
    {/* <div>
      <p className='heading-h1'>Get inspired</p>
    </div> */}
</div>)
} 
}

export default User;