// import React, { useState, useEffect } from 'react';

// const ChecklistPage = () => {
//   const [answers, setAnswers] = useState({
//     lights: null,
//     refrigerator: null,
//     passport: null,
//   });
//   const [currentQuestion, setCurrentQuestion] = useState(0);
//   const [activeQuestions] = [];
//   const [showQuestion, setShowQuestion] = useState(true);
//   useEffect(() => {
//     const container = document.querySelector('.checklist-container');
//     container.classList.add('fade-in');

//     return () => {
//       container.classList.remove('fade-in');
//     };
//   }, [currentQuestion]);
//   const handleAnswerChange = (question, answer) => {
//     setAnswers(prevAnswers => ({
//       ...prevAnswers,
//       [question]: answer,
//     }));
//   };

//   const handleNextQuestion = () => {
//     setShowQuestion(false);

//     setTimeout(() => {
//       setCurrentQuestion(prevQuestion => prevQuestion + 1);
//       setShowQuestion(true);
//     }, 500); // Wait for the fade-out animation to complete (0.5 seconds)
//   };


//   const handlePreviousQuestion = () => {
//     setShowQuestion(false);

//     setTimeout(() => {
//       setCurrentQuestion(prevQuestion => prevQuestion - 1);
//       setShowQuestion(true);
//     }, 500); // Wait for the fade-out animation to complete (0.5 seconds)
//   };

//   const questions = [
//     {
//       id: 'travelmode',
//       text: 'What modes of transport wiil you be taking for this trip?',
//       multi: true,
//       single: false,
//       ans: ['Car (Own)', 'Train', 'Car (Rented)', 'Bus', 'Flights'],
//       adLabel: None
//     },
//     {
//       id: 'lights',
//       text: 'Switched off all the lights?',
//       multi: false,
//       single: true,
//       ans: [],
//       adLabel: None
//     },
//     {
//       id: 'refrigerator',
//       text: 'Closed the refrigerator door?',
//       multi: false,
//       single: true,
//       ans: [],
//       adLabel: None
//     },
//     {
//       id: 'passport',
//       text: 'Did you get your passport?',
//       multi: false,
//       single: true,
//       ans: [],
//       adLabel: None
//     },
//   ];


//   const renderQuestion = (question, index) => {
//     const { id, text, multi, ans, single } = question;
//     const answer = answers[id];

//     return (
//       <div key={id}>
//         <h3>{text}</h3>
//         {multi?
//          single?
//         <form>
//           {ans.map(val=>
//           <label key={val}>
//             <input
//               type="radio"
//               name={id}
//               value={val}
//               // checked={answer.includes(val)}
//               // checked={answer === 'yes'}
//               onChange={(e) => (handleAnswerChange(id, e.target.checked),handleNextQuestion)}
//             />
//             {val}
//           </label>)
//           }
//         </form>
//         :
//         <form>
//         {ans.map(val=>
//         <label key={val}>
//           <input
//             type="checkbox"
//             // name={id}
//             value={val}
//             // checked={answer.includes(val)}
//             // checked={answer === 'yes'}
//             onChange={(e) => handleAnswerChange(id, e.target.checked)}
//           />
//           {val}
//         </label>)
//         }
//       </form>
//         :
//         <div>
//           <label>
//             <input
//               type="radio"
//               name={id}
//               value="yes"
//               checked={answer === 'yes'}
//               onChange={(e) => (handleAnswerChange(id, 'yes'), handleNextQuestion())}
//             />
//             Yes
//           </label>
//           <label>
//             <input
//               type="radio"
//               name={id}
//               value="no"
//               checked={answer === 'no'}
//               onChange={(e) => (handleAnswerChange(id, 'no'), handleNextQuestion())}
//             />
//             No
//           </label>
//         </div>
        
//         }
//       </div>
//     );
//   };

//   return (
//     <div className="checklist-container">
//       {renderQuestion(questions[currentQuestion], currentQuestion)}
//       <button disabled={currentQuestion === 0} onClick={handlePreviousQuestion}>
//         Back
//       </button>
//       {currentQuestion < questions.length - 1 ? (
//         <button onClick={handleNextQuestion}>Next</button>
//       ) : (
//         <button>Submit</button>
//       )}
//     </div>
//   );
// };

// export default ChecklistPage;

import React, { useState, useEffect, useRef } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo_pdf from './josh_logo_dup.png';
import checkmarkImage from './Correct.png';
import checkmarkImageFalse from './Cross.png';
import styled from 'styled-components';
import './background_checklists.css';
import Helmet from 'react-helmet';
import imgd from './bkg.webp';
import './index.css';
import { useNavigate } from 'react-router-dom';

const ButtonSelect = styled.button({
  color: 'grey',
  background: 'white',
  width: "42%",
  height: "4.5vh",
  'border-radius': '0.2em',
  border: '1px solid purple',
  color:'purple',
  'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
  'font-size': '100%',
  margin: '0 2em 0 2em',
  // '&:hover':{background: 'gold'},
  cursor: 'pointer', 
  zIndex:'3',
  '&:hover':{transform: 'translateY(5px)'},
  // '&:selection':{background: 'gold'}, 
  '&:active':{transform: 'translateY(4px)'},
  'box-shadow': '0.18em 0.22em black'
});
const ButtonSelect2 = styled.button({
  color: 'grey',
  background: 'white',
  width: "60%",
  height: "4.5vh",
  'border-radius': '0.2em',
  border: '1px solid purple',
  color:'purple',
  'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
  'font-size': '100%',
  margin: '0 2em 0 2em',
  // '&:hover':{background: 'gold'},
  cursor: 'pointer', 
  zIndex:'3',
  '&:hover':{transform: 'translateY(5px)'},
  // '&:selection':{background: 'gold'}, 
  '&:active':{transform: 'translateY(4px)'},
  'box-shadow': '0.18em 0.22em black'
});

const ChecklistPage = () => {
  const [answers, setAnswers] = useState({
    Travelmode: [],
    BabyMode: [],
    Lights: null,
    Refrigerator: null,
    Passport: null,
  });
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [activeQuestioncount, setActiveQuestioncount] = useState(0);
  const completedQuestions = useRef([])
  const [skipQuestion, setSkipQuestion] = useState(false);
  const [activeQuestions, setActiveQuestions] = useState(['Travelmode', 'BabyMode','Passport', 'Visa', 'TravelMoney', 'TravelInsurance','BankDocuments','ReservationEmails','Chargers','Powerbanks', 'MotionSicknessMedicine']); // Set the initial active questions
  // const [basicQuestions, setBasicQuestions] = useState(['Travelmode', 'BabyMode', 'Lights','Refrigerator']);
  const [showQuestion, setShowQuestion] = useState(false);
  const [introductory, setIntroductory] = useState(true);
  const [showWaiting, setShowWaiting] = useState(false);
  const [complete, setComplete] = useState(false);
  const navigate = useNavigate();


  const handleIntroductory = () => {
    setIntroductory(false);
    setShowQuestion(true);
  }

  useEffect(() => {
    const container = document.querySelector('.checklist-container');
    container.classList.add('fade-in');

    return () => {
      container.classList.remove('fade-in');
    };
  }, [currentQuestion]);


  // const generatePDF = () => {
  //   const doc = new jsPDF();
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   let yPos = 20;
  
  //   activeQuestions.forEach((questionId) => {
  //     const question = questions.find((q) => q.id === questionId);
  //     if (!question) return;
  
  //     const answer = answers[questionId];
  //     const checkmark = answer ? '\u2714' : '\u2718';
  //     const color = answer ? 'green' : 'red';
  
  //     doc.setFontSize(12);
  //     doc.setTextColor(color);
  
  //     let questionText = `${checkmark} ${question.text}`;
  //     if (question.multi && Array.isArray(answer) && answer.length > 0) {
  //       const selectedAnswers = answer.join(', ');
  
  //       const textWidth = doc.getStringUnitWidth(questionText); // Calculate question text width
  //       const availableWidth = pageWidth - 20; // Subtracting left margin
  
  //       // Check if the question text exceeds the available width
  //       if (textWidth > availableWidth) {
  //         doc.setFontSize(10); // Reduce font size for longer text
  //         doc.text(20, yPos, questionText, { maxWidth: availableWidth }); // Wrap text to new lines
  //         yPos += 5; // Adjust line spacing
  //       } else {
  //         doc.text(20, yPos, questionText); // Print question text
  //       }
  
  //       doc.setFontSize(12); // Reset font size for selected answers
  //       doc.text(20, yPos + 10, selectedAnswers, { maxWidth: availableWidth }); // Print selected answers with wrap
  //       yPos += 20; // Adjust line spacing
  //     } else {
  //       doc.text(20, yPos, questionText); // Print question text
  //       yPos += 10; // Adjust line spacing
  //     }
  //   });
  
  //   doc.save('answers.pdf');
  // };



  
  // const generatePDF = () => {
  //   const doc = new jsPDF();
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const pageHeight = doc.internal.pageSize.getHeight();
  //   let yPos = 20;
  
  //   // Add logo to the first page
  //   const logoPath = 'image.png';
  //   const logoSize = 60;
  //   const logoX = (pageWidth - logoSize) / 2;
  //   const logoY = yPos;
  //   doc.addImage(logo_pdf, 'PNG', logoX, logoY, logoSize, logoSize);
  
  //   // Set font and size for the title on the first page
  //   doc.setFont('Helvetica', 'bold');
  //   doc.setFontSize(20);
  //   doc.setTextColor('#333');
  //   doc.text('Questionnaire Answers', pageWidth / 2, logoY + logoSize + 20, { align: 'center' });
  
  //   // Add space between logo and questions
  //   yPos += logoSize + 50;
  
  //   activeQuestions.forEach((questionId) => {
  //     const question = questions.find((q) => q.id === questionId);
  //     if (!question) return;
  //     const checkmarkSymbol = '\u2713';
  //     const answer = answers[questionId];
  //     const checkmark = answer ? '\u2713' : 'X'; 
  //     const color = (answer==='yes'||answer===true) ? 'green' : question.multi?'black':'red';
  
  //     doc.setFont('arial', 'normal');
  //     doc.setFontSize(12);
  //     doc.setTextColor(color);
  
  //     let questionText = checkmark + ' ' + question.text;
  //     if (question.multi && Array.isArray(answer) && answer.length > 0) {
  //       const selectedAnswers = answer.join(', ');
  
  //       const textWidth = doc.getStringUnitWidth(questionText);
  //       const availableWidth = pageWidth - 20;
  
  //       if (textWidth > availableWidth) {
  //         doc.setFontSize(10);
  //         doc.text(20, yPos, questionText, { maxWidth: availableWidth });
  //         yPos += 5;
  //       } else {
  //         doc.text(20, yPos, questionText);
  //       }
  
  //       doc.setFontSize(12);
  //       doc.text(20, yPos + 10, selectedAnswers, { maxWidth: availableWidth });
  //       yPos += 20;
  //     } else {
  //       doc.text(20, yPos, questionText);
  //       yPos += 10;
  //     }
  //   });
  
  //   doc.save('answers.pdf');
  // };
  
  
  // const generatePDF = () => {
  //   const doc = new jsPDF();
  //   var footerText = 'Time of itinerary generation:'+ new Date()
  //   var fontSize = 20;
  //   var lineHeight = fontSize * 1.0;
  //   var addFooter = function() {
  //     var pageCount = doc.internal.getNumberOfPages();
  //     for(var i = 1; i <= pageCount; i++) {
  //       doc.setPage(i);
  //       var footerX = doc.internal.pageSize.width / 2;
  //       var footerY = doc.internal.pageSize.height - (lineHeight * 2);
  //       doc.setFontSize(4);
  //       doc.text(footerText, footerX, footerY, 'center');
  //     }
  //   };

  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const pageHeight = doc.internal.pageSize.getHeight();
  //   let yPos = 20;
  
  //   // Add logo to the first page
  //   const logoPath = 'image.png';
  //   const logoSize = 60;
  //   const logoX = (pageWidth - logoSize) / 2;
  //   const logoY = yPos;
  //   doc.addImage(logo_pdf, 'PNG', logoX, logoY, logoSize, logoSize);
  
  //   // Set font and size for the title on the first page
  //   doc.setFont('Helvetica', 'bold');
  //   doc.setFontSize(20);
  //   doc.setTextColor('#333');
  //   doc.text('Questionnaire Answers', pageWidth / 2, logoY + logoSize + 20, { align: 'center' });
  
  //   // Add space between logo and questions
  //   yPos += logoSize + 50;
  
  //   const checkmarkSize = 10; // Size of the checkmark image
  
  //   activeQuestions.forEach((questionId) => {
  //     const question = questions.find((q) => q.id === questionId);
  //     if (!question) return;
  
  //     const answer = answers[questionId];
  //     const checkmark = answer ? checkmarkImage : 'X'; // Use checkmark image or 'X' for cross
  //     const color = (answer==='yes'||answer===true) ? 'green' : question.multi?'black':'red';
  
  //     doc.setFont('Helvetica', 'normal');
  //     doc.setFontSize(12);
  //     doc.setTextColor(color);
  
  //     const checkmarkX = 20; // X position of the checkmark image
  //     const checkmarkY = yPos + 4; // Y position of the checkmark image
  //     const checkmarkWidth = checkmarkSize; // Width of the checkmark image
  
  //     if (answer==='yes'||answer===true) {
  //       doc.addImage(checkmarkImage, checkmarkX, checkmarkY, checkmarkWidth, checkmarkSize);
  //     } else {

  //       if (!question.multi){
  //           doc.addImage(checkmarkImageFalse, checkmarkX, checkmarkY, checkmarkWidth, checkmarkSize);
  //       }
  //     }
  
  //     const questionTextX = checkmarkX + checkmarkWidth + 5; // X position of the question text
  //     const questionTextY = yPos + 10; // Y position of the question text
  
  //     if (question.multi && Array.isArray(answer) && answer.length > 0) {
  //       const selectedAnswers = answer.join(', ');
  
  //       const textWidth = doc.getStringUnitWidth(selectedAnswers);
  //       const availableWidth = pageWidth - questionTextX - 10;
  
  //       if (textWidth > availableWidth) {
  //         doc.setFontSize(10);
  //         doc.text(questionTextX, questionTextY, question.text, { maxWidth: availableWidth });
  //         yPos += 5;
  //       } else {
  //         doc.text(questionTextX, questionTextY, question.text);
  //       }
  
  //       doc.setFontSize(12);
  //       doc.text(questionTextX, questionTextY + 10, selectedAnswers, { maxWidth: availableWidth });
  //       yPos += 20;
  //     } else {
  //       doc.text(questionTextX, questionTextY, question.text);
  //       yPos += 10;
  //     }
  //   });
  
  //   doc.save('answers.pdf');
  // };



const generatePDF = () => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  let yPos = 20;

  // Add logo to the first page
  const logoPath = 'image.png';
  const logoSize = 60;
  const logoX = (pageWidth - logoSize) / 2;
  const logoY = yPos;
  doc.addImage(logo_pdf, 'PNG', logoX, logoY, logoSize, logoSize);

  // Set font and size for the title on the first page
  doc.setFont('Helvetica', 'bold');
  doc.setFontSize(20);
  doc.setTextColor('#333');
  doc.text('Questionnaire Answers', pageWidth / 2, logoY + logoSize + 20, { align: 'center' });

  // Add space between logo and questions
  yPos += logoSize + 50;

  const checkmarkSize = 10; // Size of the checkmark image

  activeQuestions.forEach((questionId) => {
    const question = questions.find((q) => q.id === questionId);
    if (!question) return;

    const answer = answers[questionId];
    const checkmark = answer ? checkmarkImage : 'X'; // Use checkmark image or 'X' for cross
    const color = (answer==='yes'||answer===true) ? 'green' : question.multi?'black':'red';

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(12);
    doc.setTextColor(color);

    const checkmarkX = 20; // X position of the checkmark image
    const checkmarkY = yPos + 4; // Y position of the checkmark image
    const checkmarkWidth = checkmarkSize; // Width of the checkmark image

    if (answer==='yes'||answer===true) {
      doc.addImage(checkmarkImage, checkmarkX, checkmarkY, checkmarkWidth, checkmarkSize);
    } else {

      if (!question.multi){
          doc.addImage(checkmarkImageFalse, checkmarkX, checkmarkY, checkmarkWidth, checkmarkSize);
      }
    }

    const questionTextX = checkmarkX + checkmarkWidth + 5; // X position of the question text
    const questionTextY = yPos + 10; // Y position of the question text

    if (question.multi && Array.isArray(answer) && answer.length > 0) {
      const selectedAnswers = answer.join(', ');

      const textWidth = doc.getStringUnitWidth(selectedAnswers);
      const availableWidth = pageWidth - questionTextX - 10;

      if (textWidth > availableWidth) {
        doc.setFontSize(10);
        if (question.multi || (answer==='yes'||answer===true)){
        doc.text(questionTextX, questionTextY, question.response, { maxWidth: availableWidth });
        }
        // else if (answer==='n/a'){
        //   doc.text(questionTextX, questionTextY, question.responsefalse, { maxWidth: availableWidth }); 
        //   }
        else{
        doc.text(questionTextX, questionTextY, question.responsefalse, { maxWidth: availableWidth }); 
        }

        yPos += 5;
      } else {
        if (question.multi || (answer==='yes'||answer===true)){
          doc.text(questionTextX, questionTextY, question.response);
          }
          else{
          doc.text(questionTextX, questionTextY, question.responsefalse); 
          }
      }

      doc.setFontSize(12);
      doc.text(questionTextX, questionTextY + 10, selectedAnswers, { maxWidth: availableWidth });
      yPos += 20;
    } else {
      if (question.multi || (answer==='yes'||answer===true)){
        doc.text(questionTextX, questionTextY, question.response);
        }
        else{
        doc.text(questionTextX, questionTextY, question.responsefalse); 
        }
      yPos += 10;
    }

    // Check if there's enough space for the footer
    if (yPos > pageHeight - 30) {
      doc.addPage();
      yPos = 20;
    }
  });


// Add footer to every page
const disclaimer = 'Disclaimer: Fivsix.com and its parent company \'NKCHAKSHU UK LTD\', along with any related entities, shall not be held responsible for any issues arising from the use of the checklists provided on this website. For detailed legal information, please refer to the full legal disclaimer on this website. This concise disclaimer serves as a summary and reminder that the Company is not liable for any consequences resulting from the use of the checklists. Please read the full legal disclaimer for complete details.';
const timestamp = new Date().toLocaleString('en-US', { timeZone: 'UTC' });;

const footerX = 10; // X position of the footer
const footerY = pageHeight - 10; // Y position of the footer
const maxFooterWidth = pageWidth - 20; // Maximum width of the footer

doc.setFont('Helvetica', 'normal');
doc.setFontSize(6);
doc.setTextColor('#999');
doc.text(`Generated on (UTC): ${timestamp}`, 5, pageHeight/2, { align: 'left', maxWidth: maxFooterWidth, angle: 90, valign: 'middle' });


doc.setFont('Helvetica', 'italic');
doc.setFontSize(7);
doc.setTextColor('#999');
doc.text(disclaimer, footerX, footerY, { align: 'left', maxWidth: maxFooterWidth });



  doc.save('questionnaire.pdf');
};

  
  // useEffect(() => {
  //   // Filter and set the active questions based on their availability
  //   setActiveQuestions(questions.filter(question => answers[question.id] === null).map(question => question.id));
  // }, [answers]);

  const findActiveQuestionsByPrefix = (questions, prefix) => {
    const activeQuestionsLocal = questions.filter(question => question.id.startsWith(prefix));
    if (activeQuestionsLocal !==[]){
    return activeQuestionsLocal.map(question => question.id);
    }
    else{
      return null
    }
  };

  const returnHomepage = () => {
    let path = '/checklists_homepage';
    navigate(path);
  }

  // const activeQuestions = findActiveQuestionsByPrefix(questions, 'x');  
  const removeDuplicates = (list) => {
    const uniqueList = Array.from(new Set(list));
    return uniqueList;
  };
  const handleAnswerChange = (question, answer, type, checked) => {
    if (type==='multi'){
      setAnswers(prevAnswers => {
        console.log(prevAnswers)
        console.log(answer)
        console.log(prevAnswers[question])
        if (prevAnswers[question].includes(answer) && !checked) {
          // Remove the answer from the array
          const updatedAnswers = prevAnswers[question].filter(a => a !== answer);
          return {
            ...prevAnswers,
            [question]: updatedAnswers,
          };
        } else {
          // Add the answer to the array
          return {
            ...prevAnswers,
            [question]: [...prevAnswers[question], answer],
          };
        }
      });
  }
  else{
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [question]: answer,
    }));
  }
    console.log(answers);
  };
  const removeValuesFromList = (list, valuesToRemove) => {
    const updatedList = list.filter(item => !valuesToRemove.includes(item));
    return updatedList;
  };
  
  const handleNextQuestion = () => {
    setShowQuestion(false);
    setShowWaiting(true);
    completedQuestions.current.push(currentQuestion);
    setTimeout(() => {
      if (activeQuestioncount < activeQuestions.length - 1){
        var i = currentQuestion;
        while (i < questions.length - 1){
          console.log(currentQuestion)
          console.log('fjk')
        if (activeQuestions.includes(questions[i + 1].id)) {
          setActiveQuestioncount(prevQuestion => prevQuestion + 1);
          i++
          break;
        }
        else{
          console.log(currentQuestion);
          i++;
          
        }
      }
      if (questions[currentQuestion].multi){
        const localActiveqs = []
        const removeOlds = []
        activeQuestions.map(qs=>localActiveqs.push(qs))
        answers[questions[currentQuestion].id].map(categories => (findActiveQuestionsByPrefix(questions, categories).map(found => localActiveqs.push(found))));
        questions[currentQuestion].ans.map(categories => ((!answers[questions[currentQuestion].id].includes(categories))? findActiveQuestionsByPrefix(questions, categories).map(found => removeOlds.push(found)):null));
        const uniqueList = removeDuplicates(localActiveqs);
        console.log(removeOlds)
        const uniqueList2 = removeValuesFromList(uniqueList, removeOlds)
        setActiveQuestions(uniqueList2)
      }
      // setCurrentQuestion(prevQuestion => prevQuestion + 1);
      setCurrentQuestion(i);
      setShowWaiting(false);
      setShowQuestion(true);
      
    }
      else{
        setComplete(true);
      }
      console.log(activeQuestions)
      console.log(questions[currentQuestion])
    }, 500); // Wait for the fade-out animation to complete (0.5 seconds)
  };

  const handlePreviousQuestion = () => {
    setShowQuestion(false);
    setShowWaiting(true);
    setTimeout(() => {
      var i = currentQuestion;
      while (i > 0){
        if (activeQuestions.includes(questions[i - 1].id)) {
          setActiveQuestioncount(prevQuestion => prevQuestion - 1);
          i--
          break;
          }
        else{
          i--;
        }
      }
      setCurrentQuestion(i);
      setShowWaiting(false);
      setShowQuestion(true);
    }, 500); // Wait for the fade-out animation to complete (0.5 seconds)
  };

  const questions = [
    {
      id: 'Travelmode',
      text: 'What modes of transport will you be taking on this trip?',
      multi: true,
      single: false,
      response: 'Modes of transport you\'ll be travelling in:',
      ans: ['Car(Own)', 'Train', 'Bus/Coach', 'Flight'],
      adLabel: 'None',
    },
    {
      id: 'BabyMode',
      text: 'Are you travelling with young ones?',
      multi: true,
      single: false,
      response: 'You\'re travelling with:',
      ans: ['Infants (0-12 months)', 'Toddlers (1-3 years)', 'Children (4-12 years)', 'Teenagers (13-18)', 'No'],
      adLabel: 'None',
    },
    {
      id: 'Lights',
      text: 'Switched off all the lights?',
      multi: false,
      single: true,
      response: 'Switched off all the lights.',
      responsefalse: 'Did not switched off all the lights.',
      ans: [],
      adLabel: 'None',
    },
    {
      id: 'Refrigerator',
      text: 'Closed the refrigerator door?',
      multi: false,
      single: true,
      response: 'Closed the refrigerator.',
      responsefalse: 'Did not close the refrigerator.',
      ans: [],
      adLabel: 'None',
    },
    {
      id: 'Passport',
      text: 'Do you have all of your passports?',
      multi: false,
      single: true,
      response: 'Have the passport(s).',
      responsefalse: 'Don\'t have the passport(s)',
      ans: [],
      adLabel: 'None',
    },
    {
      id: 'Visa',
      text: 'Do you have the legal permissions required to enter and have it on you (Visas, eVisas, Residence permits, Identity Cards, Border office permissions, etc.)?',
      multi: false,
      single: true,
      response: 'Have the legal permission to enter(s) (Visas, eVisas, Residence permits, Identity Cards, Border office permissions, etc.).',
      responsefalse: 'Don\'t have the legal permission to enter(s) (Visas, eVisas, Residence permits, Identity Cards, Border office permissions, etc.).',
      ans: [],
      adLabel: 'None',
    },
        {
      id: 'TravelMoney',
      text: 'Have you kept your travel money ready? (including foreign currencies, travel money cards etc.)',
      multi: false,
      single: true,
      response: 'Travel money ready.',
      responsefalse: 'Travel money not yet ready.',
      ans: [],
      adLabel: 'None',
    },	 	
        {
      id: 'TravelInsurance',
      text: 'Have you bought/kept your travel insurance (including Medical cover)? Please note that travel insurance is mandatory to enter majority of the countries.',
      multi: false,
      single: true,
      response: 'Travel insurance ready.',
      responsefalse: 'Travel insurance not yet ready.',
      ans: [],
      adLabel: 'None',
    },
        {
      id: 'BankDocuments',
      text: 'If you are travelling to a diffrent country, do you have the necessary bank/financial documents (such as statements) to show you can financially sustain during your trip?',
      multi: false,
      single: true,
      response: 'Have the necessary(s) financial documents.',
      responsefalse: 'Don\'t have the necessary(s) financial documents.',
      ans: [],
      adLabel: 'None',
    },
        {
      id: 'ReservationEmails',
      text: 'Do you have all reservations/stay related documents handy(such as hotel bookings etc)? Either in a printed form, specific apps or marked/highlighted emails.',
      multi: false,
      single: true,
      response: 'Have reservations/stay related documents.',
      responsefalse: 'Don\'t have reservations/stay related documents.',
      ans: [],
      adLabel: 'None',
    },
        {
      id: 'Chargers',
      text: 'Have you packed chargers for all of your devices (Phones, Tablets, Laptops, e-Readers etc.)?',
      multi: false,
      single: true,
      response: 'Have packed the chargers for all my devices.',
      responsefalse: 'Have not packed the chargers for all my devices.',
      ans: [],
      adLabel: 'None',
    },
        {
      id: 'Powerbanks',
      text: 'If you prefer, have you packed your powerbanks ? (Make sure you packed it in the right luggage and does not exceed capacity limits, check TSA/local security guidelines for more information.)',
      multi: false,
      single: true,
      response: 'Packed Powerbanks.',
      responsefalse: 'Did not pack Powerbanks',
      ans: [],
      adLabel: 'None',
    },
        {
      id: 'MotionSicknessMedicine',
      text: 'Have you packed basic emergency kit and required/critical medicines (such as Epinephrine/Adrenaline auto-injectors, Asthma inhalers, Motion sickness medication etc)?',
      multi: false,
      single: true,
      response: 'Medicines and emergency kit packed.',
      responsefalse: 'Medicines and emergency kit not yet packed.',
      ans: [],
      adLabel: 'None',
    },
    {
      id: 'TrainTickets',
      text: 'Have you kept your train tickets?',
      multi: false,
      single: true,
      response: 'Have the train ticket(s).',
      responsefalse: 'Do not have the train ticket(s).',
      ans: [],
      additionaldata:'If you have e-tickets, make sure they are accessible. If it\'s in an email put a star to it.',
      adLabel: 'None',
    },
    {
      id: 'TrainRailCard',
      text: 'Have you kept your railcard?',
      multi: false,
      single: true,
      ans: [],
      response: 'Have the railcard(s).',
      responsefalse: ' Do not have the railcard(s).',
      additionaldata:'If you are using RailCard App make sure it is downloaded (or not offloaded) and active.',
      adLabel: 'None',
    },
    {
      id: 'TrainTimes',
      text: 'Have you checked the train times and which station the train departs/arrives from/at?',
      multi: false,
      single: true,
      ans: [],
      response: 'Checked the train times and departure/arrival station.',
      responsefalse: 'Have not checked the train times and departure/arrival station.',
      additionaldata: '',
      adLabel: 'None',
    },
    {
      id: 'TrainDisruption',
      text: 'Have you checked for train disruptions?',
      multi: false,
      single: true,
      ans: [],
      response: 'Have checked for the train disruptions.',
      responsefalse: 'Have not checked for the train disruptions.',
      additionaldata: 'Check for rail disruptions (delays, cancellations, busy periods, major events nearby, strikes)',
      adLabel: 'None',
    },
    {
      id: 'FlightOnlineCheckIn',
      text: 'if online check-in required for your flights, have you checked in?',
      multi: false,
      single: true,
      ans: [],
      response: 'Online check-in complete.',
      responsefalse: 'Have not checked-in online.',
      additionaldata: 'Some airlines need an online check-in and charge extra for an airport check-in. Please check if your airlines for such policies.',
      adLabel: 'None',
    },
    {
      id: 'Bus/CoachTickets',
      text: 'Have you kept your bus/coach tickets?',
      multi: false,
      single: true,
      ans: [],
      response: 'Have kept your flight tickets.',
      responsefalse: 'Have not kept your flight tickets.',
      additionaldata: 'If you have e-tickets, make sure they are accessible. If it\'s in an email put a star to it.',
      adLabel: 'None',
    },
        {
      id: 'Bus/CoachDisruption',
      text: 'Have you checked for bus/coach delays, cancellations or any other disruptions?',
      multi: false,
      single: true,
      ans: [],
      response: 'Have checked for the flight disruptions.',
      responsefalse: 'Have not checked for the flight disruptions.',
      additionaldata: 'Check for bus/coach disruptions (delays, cancellations, busy periods, major events nearby, strikes)',
      adLabel: 'None',
    },
        {
      id: 'FlightTickets',
      text: 'Have you kept your flight tickets?',
      multi: false,
      single: true,
      ans: [],
      response: 'Have kept your flight tickets.',
      responsefalse: 'Have not kept your flight tickets.',
      additionaldata: 'If you have e-tickets, make sure they are accessible. If it\'s in an email put a star to it.',
      adLabel: 'None',
    },
        {
      id: 'FlightDisruption',
      text: 'Have you checked for flight delays, cancellations or any other disruptions?',
      multi: false,
      single: true,
      ans: [],
      response: 'Have checked for the flight disruptions.',
      responsefalse: 'Have not checked for the flight disruptions.',
      additionaldata: 'Check for flight disruptions (delays, cancellations, busy periods, major events nearby, strikes)',
      adLabel: 'None',
    },
        {
      id: 'Flight100ml',
      text: 'Are all liquids in your cabin baggage stored in less than 100ml containers? (they should fit in clear plastic pouches, provided at airport security)',
      multi: false,
      single: true,
      ans: [],
      response: 'Have checked for the train disruptions.',
      responsefalse:'Have not checked for the train disruptions.',
      additionaldata: 'With the advent of new 3D scanners, the 100mL rule is being scrapped slowly, however, majority of the airports still follow it. Please TSA guidelines for further information.',
      adLabel: 'None',
    },
        {
      id: 'FlightBaggage',
      text: 'Have you checked for baggage limits (weight and number) for checked in, as well as cabin baggage?',
      multi: false,
      single: true,
      ans: [],
      response: 'Checked baggage limits',
      responsefalse: 'Have not checked for baggage limits',
      additionaldata: 'Some tickets types do not allow check-in baggage, as well have weight and number of pieces limits. Please refer to your ticket\'s baggage allowance beforehand. Extra baggage can cost you a fortune at the airport.',
      adLabel: 'None',
    },
        {
      id: 'FlightProhibiteditemscarryon',
      text: 'Have you checked for items prohibited on cabin baggage?',
      multi: false,
      single: true,
      ans: [],
      response: 'Checked for items prohibited on cabin baggage.',
      responsefalse: 'Have not checked for items prohibited on cabin baggage',
      additionaldata: 'Check TSA guidelines for detailed list on items prohibited in cabin baggage.',
      adLabel: 'None',
    },
            {
      id: 'FlightProhibiteditemscheckin',
      text: 'Have you checked for items prohibited on check-in baggage?',
      multi: false,
      single: true,
      ans: [],
      response: 'Checked for items prohibited on check-in baggage.',
      responsefalse: 'Have not checked for items prohibited on check-in baggage',
      additionaldata: 'Check TSA guidelines for detailed list on items prohibited in check-in baggage.',
      adLabel: 'None',
    },
        {
      id: 'FlightPrall',
      text: 'Have you checked for items prohibited for air travel (including any baggage)?',
      multi: false,
      single: true,
      ans: [],
      response: 'Checked for items prohibited for air travel (including any baggage)',
      responsefalse: 'Have not checked for items prohibited for air travel (including any baggage)',
      additionaldata: 'Check TSA guidelines for detailed list on items prohibited in air travel',
      adLabel: 'None',
    },
        {
      id: 'FlightTta',
      text: 'Have you checked the travel time to the airport (including delays due to current traffic)?',
      multi: false,
      single: true,
      ans: [],
      response: 'Have checked travel times and delays to reach the airport.',
      responsefalse: 'Have not checked travel times and delays to reach the airport.',
      additionaldata: 'Check for traffic disruptions and time it takes to reach the airport.',
      adLabel: 'None',
    },
    {
      id: 'Car(Own)Fuel',
      text: 'Do you have enough fuel/charge?',
      multi: false,
      single: true,
      ans: [],
      response: 'Have enough fuel.',
      responsefalse: 'Need to fill the tank/ recharge. Do not have enough fuel/charge.',
      additionaldata: 'Check for fuel levels or battery levels for your trip. If you don\'t have enough click no and make plans and adjust travel times for refuelling/recharging.',
      adLabel: 'None',
    },
            {
      id: 'Car(Own)TyrePressure',
      text: 'Have you checked your Car\'s tyre(s)-pressure, winter tyres, spare etc?',
      multi: false,
      single: true,
      ans: [],
      response: 'Car tyres are fine.',
      responsefalse: 'Car tyres need to be looked at.',
      additionaldata: 'Check your car for tyre pressures, flat tyres, spare, type of tyre (winter ones for travelling in cold regions)',
      adLabel: 'None',
    },
            {
      id: 'Car(Own)LicenseRegistration',
      text: 'Are you carrying your license and registration?',
      multi: false,
      single: true,
      ans: [],
      response: 'Yes, I\'m carrying my license and registration.',
      responsefalse: 'No, I\'m not carrying my license and registration',
      additionaldata: 'Make sure you that you are carrying the necessary legal documentation (license, registration, etc.)',
      adLabel: 'None',
    },
            {
      id: 'Car(Own)InsuranceMot',
      text: 'Are your car\'s insurance and MOT valid for the entirety of the trip?',
      multi: false,
      single: true,
      ans: [],
      response: 'Yes, my car has valid insurance and MOT.',
      responsefalse: 'No, my car does not have a valid insurance and/or MOT.',
      additionaldata: '',
      adLabel: 'None',
    },
            {
      id: 'Car(Own)',
      text: 'Have you checked the travel times for your drive (including delays due to current traffic)?',
      multi: false,
      single: true,
      ans: [],
      response: 'Have checked travel times and delays for the drive (car)',
      responsefalse: 'Have not checked travel times and delays for the drive (car)',
      additionaldata: 'Check for traffic disruptions and time it takes to reach your destination or complete you different legs of your drive.',
      adLabel: 'None',
    },
    {
      id: 'Infants (0-12 months)Nappies',
      text: 'Packed baby nappies?',
      multi: false,
      single: true,
      ans: [],
      response: 'Packed baby nappies.',
      responsefalse: 'Have not packed baby nappies yet.',
      additionaldata: '',
      adLabel: 'None',
    },
    	            {
      id: 'Infants (0-12 months)Wipes',
      text: 'Packed baby wipes?',
      multi: false,
      single: true,
      ans: [],
      response: 'Packed baby wipes.',
      responsefalse: 'Have not packed baby wipes yet.',
      additionaldata: '',
      adLabel: 'None',
    },
    	            {
      id: 'Infants (0-12 months)ChangingMat',
      text: 'Packed baby\'s changing mat?',
      multi: false,
      single: true,
      ans: [],
      response: 'Packed baby\'s changing mat.',
      responsefalse: 'Have not yet packed baby\'s changing mat.',
      additionaldata: '',
      adLabel: 'None',
    },
    	            {
      id: 'Infants (0-12 months)Handsanitizer',
      text: 'Packed hand sanitizer/ hand wash (stuff to clean and disinfect your hands before handling your baby)?',
      multi: false,
      single: true,
      ans: [],
      response: 'Packed hand sanitizer/hand wash',
      responsefalse: 'Not yet packed hand sanitizer/hand wash',
      additionaldata: '',
      adLabel: 'None',
    },
    	            {
      id: 'Infants (0-12 months)Cleanclothes',
      text: 'Packed baby\'s cleaning clothes (for example, muslin)?',
      multi: false,
      single: true,
      ans: [],
      response: 'Packed baby\'s cleaning clothes.',
      responsefalse: 'Have not packed baby\'s cleaning clothes yet',
      additionaldata: '',
      adLabel: 'None',
    },
    	            {
      id: 'Infants (0-12 months)Sterilised',
      text: 'Have you prepared and packed baby\'s sterilized bottles, pacifiers, and other feeding equipment ?',
      multi: false,
      single: true,
      ans: [],
      response: 'Packed baby\'s feeding equipment.',
      responsefalse: 'Have not pack baby\'s feeding equipment yet.',
      additionaldata: '',
      adLabel: 'None',
    },
    	            {
      id: 'Infants (0-12 months)Formula',
      text: 'Packed your baby\'s food/formula?',
      multi: false,
      single: true,
      ans: [],
      response: 'Packed baby\'s food/formula',
      responsefalse: 'Baby\'s food/formula not yet packed',
      additionaldata: 'Check for traffic disruptions and time it takes to reach your destination or complete you different legs of your drive.',
      adLabel: 'None',
    },
    	            {
      id: 'Infants (0-12 months)Medicine',
      text: 'Packed your baby\'s medicines (including emergency medicine)? ',
      multi: false,
      single: true,
      ans: [],
      response: 'Baby\'s medicine packed.',
      responsefalse: 'Baby\'s medicine not yet packed.',
      additionaldata: 'Make sure you have your baby\'s medicines packed.',
      adLabel: 'None',
    },
    	            {
      id: 'Infants (0-12 months)Carrier',
      text: 'Packed baby carrier (slings)/prams or kept it ready?',
      multi: false,
      single: true,
      ans: [],
      response: 'Packed baby carrier(s).',
      responsefalse: 'Not packed baby carrier(s).',
      additionaldata: 'Make sure that you have all the means to carry your baby ready.',
      adLabel: 'None',
    },
    	            {
      id: 'Infants (0-12 months)Toys',
      text: 'Packed all your kid(s) toys ?',
      multi: false,
      single: true,
      ans: [],
      response: 'Toys packed',
      responsefalse: 'Toy\'s not yet packed.',
      additionaldata: '',
      adLabel: 'None',
    },
    	 	
    
    
    
  ];

  const renderQuestion = (question, index) => {
    const { id, text, multi, ans, single, additionaldata } = question;
    const answer = answers[id];

    if (!activeQuestions.includes(id)) {
      console.log(id);
      return null; // Skip rendering if the question is not in activeQuestions
    }

        return (
      <div key={id}>
        <h3>{text}</h3>
        {multi?
         single?
        <form>
          {ans.map(val=>
          <label key={val}>
            <input
              type="radio"
              name={id}
              value={val}
              checked={answer.includes(val)}
              // checked={answer === 'yes'}
              onChange={(e) => (handleAnswerChange(id, e.target.checked),handleNextQuestion)}
            />
            {val}
          </label>)
          }
        </form>
        :
        <form>
        {ans.map(val=>
        <label key={val}>
          <input
            type="checkbox"
            // name={id}
            value={val}
            checked={answer.includes(val)}
            // checked={answer === 'yes'}
            onChange={(e) => handleAnswerChange(id, e.target.value, 'multi', e.target.checked)}
          />
          {val}
        </label>)
        }
      </form>
        :
        <div>
          <form>
          <label>
            <input
              type="radio"
              name={id}
              value="yes"
              checked={answer === 'yes'}
              onChange={(e) => (handleAnswerChange(id, 'yes'), handleNextQuestion())}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name={id}
              value="no"
              checked={answer === 'no'}
              onChange={(e) => (handleAnswerChange(id, 'no'), handleNextQuestion())}
            />
            No
          </label>
          <label>
            <input
              type="radio"
              name={id}
              value="n/a"
              checked={answer === 'n/a'}
              onChange={(e) => (handleAnswerChange(id, 'n/a'), handleNextQuestion())}
            />
            N/A
          </label>
          </form>
        </div>
        
        }
        {additionaldata==='' || additionaldata===undefined?null: 
        <h5>"{additionaldata}"</h5>
        }
      </div>
    );
  };

  // return (
  //   <div className="checklist-container">
  //     {showQuestion && renderQuestion(questions[currentQuestion], currentQuestion)}
  //     <button disabled={currentQuestion === 0} onClick={handlePreviousQuestion}>
  //       Back
  //     </button>
  //     {currentQuestion < activeQuestions.length - 1 ? (
  //       <button onClick={handleNextQuestion}>Next</button>
  //     ) : (
  //       <button>Submit</button>
  //     )}
  //   </div>
  // );
  const yht = {
    background: 'bkg.webp'
  }
  const [backgroundImage, setBackgroundImage] = React.useState('./ijk.webp');
  const rft = `url('/Users/neerajkavanchakshu/My Drive/R&D/TravelAI/Website/frontend/src/ijk.webp')`
  const introductoryMessage = 'Scientific checklist to help you make sure you have the essentials you need for a smooth travel.'
  const introductoryTitle = ' The most essential travel gist-a-list'
  const introductoryWarning =  'By clicking on Start, you confirm that you have read and agreed to our Terms and Conditions, as well as our disclaimers.'
const PageContainer = styled.div`
  background-image: url(${props => props.backgroundImage});
  /* Additional styles for the page container */
`;
  if (complete){
    return(
      <div className="checklist-container">
      <Helmet bodyAttributes={{style: `background-color: ${rft}`}}/>
      <h1>Hooray!</h1>
      <br></br>
      Gist-a-list Complete! Enjoy your trip!

      <ButtonSelect2 onClick={generatePDF}>Download PDF</ButtonSelect2>
      <ButtonSelect2 onClick={returnHomepage}>Use other gist-a-lists</ButtonSelect2>
      </div>
        

    )
  }

  return (
<div>
<Helmet bodyAttributes={{style: `background-image : url('bkg.webp')`}}/>
   {/* <Helmet>
             <style>
                {`
                   html, body {
                       height: 100%;
                  }

                 body {
                    background-image: url("bkg.webp");
                    background-size: 80% 100%, 101% 100%;
                    background-repeat: no-repeat;
                    background-position: 1% 60%, 80% 110%;
                  }
                `}
            </style>
         </Helmet> */}
         
    <div className="checklist-container">
      {showWaiting &&
    <div class="lds-ripple"><div></div><div></div></div>
      }
      {introductory && <h3>{introductoryTitle}</h3>}
      {introductory && <div><h5>{introductoryMessage}</h5><br></br><br></br></div>}
      {introductory && <ButtonSelect onClick={handleIntroductory}>Start</ButtonSelect>}

      {introductory && <p className='fineprint'>{introductoryWarning}</p>}

      {showQuestion && renderQuestion(questions[currentQuestion], currentQuestion)}


      { showQuestion &&
      (((activeQuestioncount < activeQuestions.length - 1) && questions[currentQuestion].multi) || completedQuestions.current.includes(currentQuestion)) ? (
        <ButtonSelect onClick={handleNextQuestion}>Next</ButtonSelect>
      ) :  showQuestion && questions[currentQuestion].multi? (
        <ButtonSelect onClick={handleNextQuestion}>
          {(activeQuestioncount < activeQuestions.length - 1) ? 'Next' : 'Submit'}
        </ButtonSelect>
        
      ):null}
            {
      showQuestion && currentQuestion>0?
      <ButtonSelect disabled={currentQuestion === 0} onClick={handlePreviousQuestion}>
        Back
      </ButtonSelect>:null
      }
    </div>
    </div>
  );
 

};

export default ChecklistPage;



