import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

const GoogleAnalyticsInitializer = () => {
  useEffect(() => {
    const TRACKING_ID = "G-69HLY34YRW";

    const initializeAnalytics = () => {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.set({ anonymizeIp: true });
      ReactGA.pageview(window.location.pathname + window.location.search);
    };

    const handleConsent = () => {
      if (window.Cookiebot && window.Cookiebot.consent) {
        if (window.Cookiebot.consent.statistics) {
          initializeAnalytics();
        }
      }
    };

    handleConsent();

    window.addEventListener('CookiebotOnAccept', handleConsent);

    return () => {
      window.removeEventListener('CookiebotOnAccept', handleConsent);
    };
  }, []);

  return null; // This component doesn't render anything
};

export default GoogleAnalyticsInitializer;