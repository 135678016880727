import React, {useState, useRef, useEffect, lazy, Suspense }from 'react';
import backpacklogo from './backpacker.jpg';
import cheaproom from './cheaproom.jpg';
import mediumroom from './mediumroom.jpg';
import axios from 'axios';
import cool from './cool.jpg';
import ritzy from './ritzy-2.jpg';
import tp_full from './tp_full.jpg';
import tp_mid from './tp_mid.jpg';
import tp_business from './tp_business.jpg'
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
// import DatePicker, {CalendarContainer} from "react-datepicker";
import {Accordion as Accordion2} from "react-bootstrap"; 
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styled as styled2} from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import GaugeChart from 'react-gauge-chart';
// import classNames from 'classnames';
// import { List, Accordion, ListItemText, ListItemButton } from '@mui/material';
import Rating from '@mui/material/Rating';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import './cerulean.min.css';
// import './solar.min.css';
// import './sketchy.min.css';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'; 
// import 'react-accessible-accordion/dist/fancy-example.css';
import "react-datepicker/dist/react-datepicker.css";
import NumberPicker from "react-widgets/NumberPicker";
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from './service/Dropdown';
// import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { capitalize } from '@mui/material';
import './index_iphone.css'
import './index_ipad.css'



// import { usePlacesWidget } from "react-google-autocomplete";
// import AddressAutocomplete from './service/AddressAutocomplete';

const planURL = 'https://vio1eqwlce.execute-api.eu-west-2.amazonaws.com/Beta/api/v1/fivsix_plan/'//  'https://qozc5jwvc6.execute-api.eu-west-2.amazonaws.com/api/v1/fivsix_plan/' 'http://localhost:80/api/v1/fivsix_plan/'//

const ButtonSelect = styled.button({
    color: 'grey',
    background: 'transparent',
    width: "30%",
    height: "110%",
    'border-radius': '0.2em',
    border: '1px solid purple',
    color:'purple',
    'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
    'font-size': '100%',
    margin: '0 2em 0 2em',
    '&:hover':{background: 'gold'},
    '&:hover':{transform: 'translateY(2px)'},
    '&:selection':{background: 'gold'}, 
    '&:active':{transform: 'translateY(4px)'},
    'box-shadow': '0.18em 0.22em black'
  });

  function sortItemsByPrice(items) {
    return [...items].sort((a, b) => a.Price - b.Price);
  }

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Earth's radius in km
  
    const dLat = degToRad(lat2 - lat1);
    const dLon = degToRad(lon2 - lon1);
  
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degToRad(lat1)) *
        Math.cos(degToRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
  
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    const distance = R * c; // Distance in km
  
    return distance;
  }
  
  function degToRad(degrees) {
    return degrees * (Math.PI / 180);
  }

  function getFlightTime(departureTime) {
    const hour = new Date(departureTime).getUTCHours();
  
    if ((hour >= 0 && hour < 5)||hour >= 23) {
      return 'Overnight';
    } else if (hour >= 5 && hour < 12) {
      return 'Morning';
    } else if (hour >= 12 && hour < 17) {
      return 'Afternoon';
    } else if (hour >= 17 && hour < 21) {
      return 'Evening';
    } else if (hour >= 21 && hour <= 22) {
      return 'Night';
    } else {
      return 'Invalid time';
    }
  }
  function removeCommas(str) {
    const newStr = str.replace(/,/g, '');
    return newStr;
  }
  function removeUnderscores(str) {
    const newStr = str.replace(/_/g, ' ');
    return newStr;
  }
  function formatDate(utcDate) {
    const options = {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(utcDate);
    const formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate;
  }

  function getDayDifference(timestamp1, timestamp2) {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
  
    // Set the time of both dates to midnight UTC
    date1.setUTCHours(0, 0, 0, 0);
    date2.setUTCHours(0, 0, 0, 0);
  
    // Calculate the difference in milliseconds between the two dates
    const difference = Math.abs(date1.getTime() - date2.getTime());
  
    // Calculate the difference in days
    const days = Math.ceil(difference / (1000 * 3600 * 24));
  
    return days;
  }
//   const autocomplete = new GeocoderAutocomplete( document.getElementById("autocomplete"),  'f25398151728411991ee08b1184d003c', { /* Geocoder options */ });

//   autocomplete.on('select', (location) => {
//   // check selected location here
//   });

//   autocomplete.on('suggestions', (suggestions) => {
//   // process suggestions here
//   });

const toTitleCase = (s)=>{
    if (typeof(s)==='string'&&s.length>0) {
      const words = s.split(' ')
      if (Array.isArray(words)&&words.length>0) {
        if (words.length===1) {
          const word = words[0]
          const matches = word.charAt(0).match(/\w+/i)
          const lines = word.split('\n')
          if (Array.isArray(lines)&&lines.length>1) {
            return lines.map(line=>{
              return toTitleCase(line)
            }).join('\n')
          } else if (Array.isArray(matches)) {
            return word.split('').map((c,i)=>{
              if (i===0) {
                return c.toUpperCase()
              }
              return c.toLowerCase()
            }).join('')
          } else {
            return word.charAt(0).concat(toTitleCase(word.slice(1)))
          }
        } else {
          return words.map(word=>toTitleCase(word)).join(' ')
        }
      }
    }
    return ''
  }
  

const Home2 = () => {

    const [dateRange, setDateRange] = useState([null, null]);
    const [noDays, setNodays] = useState(0);
    const [startDate, setStartdate] = useState(new Date());
    const [endDate, setEnddate] = useState(new Date());
    const [budget, setBudget] = useState(null);
    const [minBudget, setMinbudget] = useState(null);
    const [maxBudget, setMaxbudget] = useState(null);
    const [tp, setTp] = useState('travellerfull');
    const [food, setFood] = useState('normalrest');
    const [ab, setab] = useState(false);
    const [dateFlag, setDateflag] = useState(true);
    const [pointA, setPointA] = useState(null);
    const [hotel, setHotel] = useState(null);
    const [hotelLat, setHlat] = useState(0);
    const [hotelLon, setHlon] = useState(0);
    var needHotel = useRef("True");
    const [message, setMessage] = useState(null);
    const [isMessage, setMessageflag] = useState(false);
    const [budgetFlag, setBudgetflag] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [tpFlag, setTpflag] = useState(false);
    const [foodFlag, setFoodflag] = useState(false);
    const [startTime, setStarttime] = useState(new Date());
    const [currency, setCurrency] = useState('GBP');
    const [endTime, setEndtime] = useState(new Date());
    const [noAdults, setAdults] = useState(1);
    const [noChildren, setChildren] = useState(0);
    const flightitems = useRef([])
    const [flightsFlag, setFlightsflag] = useState(false);
    const hotelitems = useRef([])
    const [filteredhotelitems, setFilteredhotels] = useState([])
    const [hotelsFlag, setHotelsflag] = useState(false);
    const [selFlight, setFlightssel] = useState(0);
    const [selHotel, setHotelssel] = useState(null);
    const [driveOnly, setDriveonly] = useState(false);
    const [flightsactive, setFlightsactive] = useState(true);
    let [nullHotel, setNullhotel] = useState(false);
    let [activeChange, setActivechange] = useState([]);
    var hotelsactive= useRef(true);
    const [hoteloffersavailable, setHoteloffersavailable] = useState(false);
    const [animate, setAnimate] = useState(false);
    const navigate = useNavigate();

    function fhAcc(){ return(<Accordion onChange={uuid=>( setHotelssel(uuid[0]))} class='hotels-list-accordian'>
              
    {filteredhotelitems.map((item, index) => (
              // item.Price.toFixed(2)< (maxBudget) && item.Price.toFixed(2)> (minBudget) ? 
              
              <AccordionItem uuid={index}>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                       {toTitleCase(item.Name)} <Rating name="disabled" className='rating-hotel' size='small' max={item.Rating} value={item.Rating} readOnly /> <span className='gp'><span class='gbp'>{item.Currency+' '+item.Price.toFixed(2)}</span><span className='gz'>per night</span></span>
                       {/* {setHoteloffersavailable(true)} */}
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                      <p>{toTitleCase(removeUnderscores(item.Room_type))}<br></br></p>
                      <p>{'Beds: '+item.No_beds}</p>
                      {/* <div className='speedometer'>
                      <Speedometer
                        height={150}
                        width={150}
                        value={93}
                        max={100}
                       fontFamily='squada-one'
                       accentColor='green'
                       
                      
>
                      <Background color='white'/>
                      <Arc width={10}/>
                      <Needle color='black'/>
                      <Progress arcWidth={10}/>
                      <Marks step={25} lineColor='black'/>
                      <Indicator color='black' fontSize={18}/>
                      </Speedometer>
                      </div> */}
                      {item.UserRating!=='NA'?
                       <div>
                       <div className='speedometer'>
                              <GaugeChart
        id="gauge-chart3"
        nrOfLevels={20}
        colors={["red", "yellow", "green"]}
        arcWidth={0.3}
        percent={item.UserRating/100}
        textColor={'black'}
        // hideText={true} // If you want to hide the text
      />
      <br></br>
      <span>Overall rating ({item.UserRatingNo} users)</span>
      </div>
      <div className='smallerratings'>
      <div className='speedometer2'>
                              <GaugeChart
        id="gauge-chart3"
        nrOfLevels={5}
        colors={["red", "yellow", "green"]}
        arcWidth={0.3}
        percent={item.UserSentiments.location/100}
        textColor={'black'}
        // hideText={true} // If you want to hide the text
      />
      <span>Location</span>
      </div>
      <div className='speedometer2'>
                              <GaugeChart
        id="gauge-chart3"
        nrOfLevels={5}
        colors={["red", "yellow", "green"]}
        arcWidth={0.3}
        percent={item.UserSentiments.roomComforts/100}
        textColor={'black'}
        // hideText={true} // If you want to hide the text
      />
      <span>Room comforts</span>
      </div>
      <div className='speedometer2'>
                              <GaugeChart
        id="gauge-chart3"
        nrOfLevels={5}
        colors={["red", "yellow", "green"]}
        arcWidth={0.3}
        percent={item.UserSentiments.valueForMoney/100}
        textColor={'black'}
        // hideText={true} // If you want to hide the text
      />
      <span>Value for money</span>
      </div>
      </div>
      
      </div>:<p>Oops! looks like we were not able to find review data for this hotel in our data</p>}
                      <ButtonSelect onClick={changeHandler}>Select Hotel</ButtonSelect>
                  </AccordionItemPanel>
                  <br></br>
              </AccordionItem>

              
          // :null
             

    ))}
    </Accordion>)};
    // const MyContainer = ({ className, children }) => {
    //     return (
    //       <div style={{ padding: "16px", background: "#216ba5", color: "#fff" }}>
    //         <CalendarContainer className={className}>
    //           <div style={{ background: "#f0f0f0" }}>
    //             What is your favorite day?
    //           </div>
    //           <div style={{ position: "relative" }}>{children}</div>
    //         </CalendarContainer>
    //       </div>
    //     );
    // }


    // const { ref } = usePlacesWidget({
    //     apiKey: 'AIzaSyD6-MW_M5vDBIBVG2dPw4sRwMowU-HPBUA',
    //     onPlaceSelected: (place) => {
    //       console.log(place);
    //     },
    //     options: {
    //       types: ["(regions)"],
    //       componentRestrictions: { country: "uk" },
    //     },
    //   });
    const hotelHandler = (event)=>{
      if (true){
      needHotel.current="False";
      hotelsactive.current = false;
      //console.log('hherre');
      //console.log(needHotel.current);
      //console.log(hotelsactive.current);
      changeHandler(event);
      }
    }

    const backHandler = (event)=>{
        event.preventDefault();
        if (tpFlag === true){
            setBudgetflag(true);
            setTpflag(false); 
        }
        else if (budgetFlag === true){
            setDateflag(true);
            setBudgetflag(false);
        }
        else if (dateFlag === true){
            let pathback = '/'; 
            navigate(pathback)
        }

    }

    const filterHandler = (event) =>{
      //console.log(minBudget);
      //console.log(maxBudget);
      //console.log(hotelitems);
      //console.log(filteredhotelitems);
      //console.log(nullHotel);
      setNullhotel([]);
      let hji = []
      hotelitems.current.map((item)=>{if (item.Price.toFixed(2)< (maxBudget) && item.Price.toFixed(2)> (minBudget)){ hji.push(item)}})
      if (hji===[]){
        setNullhotel(true)
      }
      else{
        setNullhotel(false)
      }
      hji = sortItemsByPrice(hji);
      setFilteredhotels(hji)
      //console.log(filteredhotelitems);
    }

    const changeHandler = (event) =>{
        event.preventDefault();
        window.resizeTo(window.innerWidth, window.innerHeight);
        const requestConfig = {
          headers: {
              'x-api-key': 'OIhrOHDTcPaq7yaRCElZvEBH36Dw1mk2dXJAbe32'
          }
        }
        if (dateFlag === true){
            if (pointA){
            if (startDate && endDate){ 
            setAnimate(false);
            setTimeout(function() {
                //console.log(endDate);
            }, 1000);
            setMessageflag(false);
            setDateflag(false);
            setBudgetflag(true);
            //console.log(noAdults);
            //console.log(noChildren);
            //console.log(driveOnly)
            const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
            const differentDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
            setNodays(differentDays)
            
            }
            else{
                setMessage('Please select both outward and inward date(s). If it\'s only one day select the outward date again.');
                setMessageflag(true);
            }}
            else{
                setMessage('Please provide the starting location.');
                setMessageflag(true);
            }
            
        }
        else if (budgetFlag === true){  
            // const dc = new Date(startDate)
            // dc.toISOString().substr(0, 10)
            if (budget && !ab){
            setAnimate(false);
            // setTimeout(function() {
            //     console.log(endDate);
            // }, 500);
            setMessageflag(false)
            //console.log(endDate)
            //console.log(endTime)
            setBudgetflag(false);
            setTpflag(true);
          
            }
            else if (!budget && !ab){
             setMessage('Please choose your budget')
             setMessageflag(true)
            }
            else if (ab && !hotel){
                setMessage('Please let use know the place where you\'ll be staying. That location will be erased after this session.')
             setMessageflag(true)
            }
            else if (ab && hotel){
                needHotel.current = "False"
                setHlat(sessionStorage.getItem('h1_lat'))
                setHlon(sessionStorage.getItem('h1_lon'))
                setAnimate(false);
                // setTimeout(function() {
                //     console.log(endDate);
                // }, 500);
                setMessageflag(false)
                //console.log(endDate)
                //console.log(endTime)
                setBudgetflag(false);
                setTpflag(true);

            }
            else{
                setMessageflag(false)
                setAnimate(false);
                // setTimeout(function() {
                //     console.log(endDate);
                // }, 500);
                
                //console.log(endTime)
                setBudgetflag(false);
                setTpflag(true); 
 
            }
        }
        else if (tpFlag === true){
            if (tp){
            setTpflag(false);
            setFoodflag(true);
            const dc = new Date(startDate)
            
            const dc2 = new Date(endDate)
           
            const dct = new Date(startTime)
            // dct.toISOString().substr(11,19)
            const dct2 = new Date(endTime)
            // dct2.toISOString().substr(11,19)

            const requestBody = {
                destination: sessionStorage.getItem('pointB'),
                start_point: sessionStorage.getItem('pointA'),
                d_lat: parseFloat(sessionStorage.getItem('d_lat')),
                d_lon: parseFloat(sessionStorage.getItem('d_lon')),
                s_lat: parseFloat(sessionStorage.getItem('s_lat')),
                s_lon: parseFloat(sessionStorage.getItem('s_lon')),
                s_time: startTime,
                e_time: endTime,
                budget: budget,
                adults: noAdults,
                children: noChildren,
                infants: 0,
                start_date: dc.toISOString().substr(0, 10),
                return_date: dc2.toISOString().substr(0, 10),
                first_run_selector: '0',
                hotel_name: "string",
                hotel_lat: hotelLat,
                hotel_lon: hotelLon,
                drive_only: driveOnly,
                need_hotel: needHotel.current,
                flights_required:driveOnly? "False" : "True",
                flight_selection_done: "False",
                hotel_selection_done: "False",
                sel_flight:"string",
                sel_hotel:"string"
            }
            // console.log(requestBody)
            var places_l1 = []
            var places_l2 = []
            var places_name = []
            let path = '/results'; 
            setLoading(true);
            const resp = axios.post(planURL,requestBody,requestConfig).then(response=>{
                sessionStorage.setItem('sess_flights', response.data.FR)
                if (response.data.FF=='True' && response.data.HF=='True'){

                //console.log(response.data)
                flightitems.current = response.data.FR
                console.log(flightitems.current)
                setTpflag(false)
                setFlightsflag(true)
                setLoading(false)
                                    
                }
                else if(response.data.FF=='False' && response.data.HF=='False'){
                    //console.log('idhhar')
                    //console.log(response.data.FR)
                    setTpflag(false)
                    setFlightsflag(false)
                    setHotelsflag(false)
                    sessionStorage.setItem('itinerary', JSON.stringify(response.data.FR))
                    sessionStorage.setItem('itineraryStorage',JSON.stringify({"Title": pointA+' to '+sessionStorage.getItem('pointB'), "Date": formatDate(startDate)+'-'+ formatDate(endDate), "Itinerary":response.data.FR}))
                    sessionStorage.setItem('selFlight',flightsactive? JSON.stringify(flightitems.current[selFlight]):null)
                    sessionStorage.setItem('selHotel',hotelsactive.current? JSON.stringify(hotelitems.current[selHotel]):null)
                    navigate(path);
                    setLoading(false)
                    setFlightsactive(false)
                    hotelsactive.current = false;
                }
                else if(response.data.FF=='False' && response.data.HF=='True'){
                    setTpflag(false)
                    setFlightsflag(false)
                    sessionStorage.setItem('sess_hotels', response.data.FR)
                    //console.log(response.data.FR)
                    hotelitems.current = response.data.FR
                    let hji = []
                    hotelitems.current.map((item)=>{if (item.Price.toFixed(2)< (maxBudget) && item.Price.toFixed(2)> (minBudget)){ hji.push(item)}})
                    if (hji===[]){
                      setNullhotel(true)
                    }
                    else{
                      setNullhotel(false)
                    }
                    setFilteredhotels(hji)
                    setLoading(false)
                    setHotelsflag(true)
                    setFlightsactive(false)
                    hotelsactive.current = true;
                }


                // response.data.Location.map((Location, index) =>(
                //     places_l1.push(response.data.Location[index][0]),
                //     places_l2.push(response.data.Location[index][1]),
                //     places_name.push(response.data.Name[index])))
                    // imagesLocal.push(response.data.Images[index][0])))
                    // retrieveImage(response.data.Images[index][0])))
                    // console.log('test in');
                    // console.log(places_l1);
                    // console.log(places_l2);
                    // sessionStorage.setItem('l1', JSON.stringify(places_l1));
                    // sessionStorage.setItem('l2', JSON.stringify(places_l2));
                    // sessionStorage.setItem('poi', JSON.stringify(places_name));
                    // navigate(path);
                    // sessionStorage.setItem('images',JSON.stringify(imagesLocal));
                    // imgList.current = Array(places_l1.length);
            })
            }
        else{
            setMessage('Please select the type of trip you want us to plan.');
            setMessageflag(true);
        }
        }
        else if ( budgetFlag === false && tpFlag ===false && flightsFlag===true && hotelsFlag === false){
            setFlightsflag(false);

            const dc = new Date(startDate)
            
            const dc2 = new Date(endDate)
           
            const dct = new Date(startTime)
            // dct.toISOString().substr(11,19)
            const dct2 = new Date(endTime)
            // dct2.toISOString().substr(11,19)
            //console.log(JSON.stringify(flightitems.current[selFlight]))
            const requestBody = {
                destination: sessionStorage.getItem('pointB'),
                start_point: sessionStorage.getItem('pointA'),
                d_lat: parseFloat(sessionStorage.getItem('d_lat')),
                d_lon: parseFloat(sessionStorage.getItem('d_lon')),
                s_lat: parseFloat(sessionStorage.getItem('s_lat')),
                s_lon: parseFloat(sessionStorage.getItem('s_lon')),
                s_time: startTime,
                e_time: endTime,
                budget: budget,
                adults: noAdults,
                children: noChildren,
                infants: 0,
                start_date: dc.toISOString().substr(0, 10),
                return_date: dc2.toISOString().substr(0, 10),
                first_run_selector: '0',
                hotel_name: "string",
                hotel_lat: hotelLat,
                hotel_lon: hotelLon,
                drive_only: driveOnly,
                need_hotel: needHotel.current,
                flights_required:driveOnly? "False" : "True",
                flight_selection_done: "True",
                hotel_selection_done: "False",
                sel_flight: JSON.stringify(flightitems.current[selFlight]),
                sel_hotel:"string"
            }
            // console.log(requestBody)
            var places_l1 = []
            var places_l2 = []
            var places_name = []
            let path = '/results'; 
            setLoading(true);
            const resp = axios.post(planURL,requestBody,requestConfig).then(response=>{

                if (response.data.HF == "False"){
                    setTpflag(false)
                    setFlightsflag(false)
                    setHotelsflag(false)
                    sessionStorage.setItem('itinerary', JSON.stringify(response.data.FR))
                    sessionStorage.setItem('itineraryStorage',JSON.stringify({"Title": pointA+' to '+sessionStorage.getItem('pointB'), "Date": formatDate(startDate)+'-'+ formatDate(endDate), "Itinerary":response.data.FR}))
                    sessionStorage.setItem('selFlight',flightsactive? JSON.stringify(flightitems.current[selFlight]):null)
                    sessionStorage.setItem('selHotel',hotelsactive.current? JSON.stringify(hotelitems.current[selHotel]):null)
                    navigate(path);
                    setLoading(false)
                }
                else{
                sessionStorage.setItem('sess_hotels', response.data.FR)
                //console.log(response.data)
                hotelitems.current = response.data.FR
                let hji = []
                hotelitems.current.map((item)=>{if (item.Price.toFixed(2)< (maxBudget) && item.Price.toFixed(2)> (minBudget)){ hji.push(item)}})
                if (hji===[]){
                  setNullhotel(true)
                }
                else{
                  setNullhotel(false)
                }
                setFilteredhotels(hji)
                setFlightsflag(false)
                setHotelsflag(true)
                setLoading(false)
                }
                    // sessionStorage.setItem('images',JSON.stringify(imagesLocal));
                    // imgList.current = Array(places_l1.length);
            })

        }
        else if( budgetFlag === false && tpFlag ===false && flightsFlag===false && hotelsFlag===true){
            if (selHotel === null){
                needHotel.current="False"
                hotelsactive.current = false
    
            }
            const dc = new Date(startDate)
            
            const dc2 = new Date(endDate)
           
            const dct = new Date(startTime)
            // dct.toISOString().substr(11,19)
            const dct2 = new Date(endTime)
            // dct2.toISOString().substr(11,19)
            //console.log(JSON.stringify(flightitems.current[selFlight]))
            const requestBodyhotel = {
                destination: sessionStorage.getItem('pointB'),
                start_point: sessionStorage.getItem('pointA'),
                d_lat: parseFloat(sessionStorage.getItem('d_lat')),
                d_lon: parseFloat(sessionStorage.getItem('d_lon')),
                s_lat: parseFloat(sessionStorage.getItem('s_lat')),
                s_lon: parseFloat(sessionStorage.getItem('s_lon')),
                s_time: startTime,
                e_time: endTime,
                budget: budget,
                adults: noAdults,
                children: noChildren,
                infants: 0,
                start_date: dc.toISOString().substr(0, 10),
                return_date: dc2.toISOString().substr(0, 10),
                first_run_selector: '0',
                hotel_name: "string",
                hotel_lat: hotelLat,
                hotel_lon: hotelLon,
                drive_only: driveOnly,
                need_hotel: needHotel.current,
                flights_required:driveOnly? "False" : "True",
                flight_selection_done: "True",
                hotel_selection_done: hotelsactive.current? "True":"False",
                sel_flight: flightsactive? JSON.stringify(flightitems.current[selFlight]):"String",
                sel_hotel: hotelsactive.current? JSON.stringify(hotelitems.current[selHotel]):"String"
            }
            //console.log(requestBodyhotel)
            // console.log(requestBody)
            var places_l1 = []
            var places_l2 = []
            var places_name = []
            let path = '/results'; 
            setLoading(true);
            //console.log('dfdf')
            //console.log(JSON.stringify(hotelitems.current[selHotel]))
            const resp = axios.post(planURL,requestBodyhotel,requestConfig).then(response=>{
                console.log(response.data)
                if (needHotel.current==="True"){
                sessionStorage.setItem('itinerary', JSON.stringify(response.data))
                sessionStorage.setItem('itineraryStorage',JSON.stringify({"Title": pointA+' to '+sessionStorage.getItem('pointB'), "Date": formatDate(startDate)+'-'+ formatDate(endDate), "Itinerary":response.data}))
                
                }
                else{
                  sessionStorage.setItem('itinerary', JSON.stringify(response.data.FR))
                  sessionStorage.setItem('itineraryStorage',JSON.stringify({"Title": pointA+' to '+sessionStorage.getItem('pointB'), "Date": formatDate(startDate)+'-'+ formatDate(endDate), "Itinerary":response.data.FR}))
                }
                sessionStorage.setItem('selFlight',flightsactive? JSON.stringify(flightitems.current[selFlight]):null)
                sessionStorage.setItem('selHotel',hotelsactive.current? JSON.stringify(hotelitems.current[selHotel]):null)
                navigate(path);
                // response.data.Location.map((Location, index) =>(
                //     places_l1.push(response.data.Location[index][0]),
                //     places_l2.push(response.data.Location[index][1]),
                //     places_name.push(response.data.Name[index])))
                //     // imagesLocal.push(response.data.Images[index][0])))
                //     // retrieveImage(response.data.Images[index][0])))
                //     console.log('test in');
                //     console.log(places_l1);
                //     console.log(places_l2);
                //     sessionStorage.setItem('l1', JSON.stringify(places_l1));
                //     sessionStorage.setItem('l2', JSON.stringify(places_l2));
                //     sessionStorage.setItem('poi', JSON.stringify(places_name));
                //     navigate(path);
                    // sessionStorage.setItem('images',JSON.stringify(imagesLocal));
                    // imgList.current = Array(places_l1.length);

        })
    }
}

    function _suggestionSelect(result, lat, lng, text) {
        //console.log(result, lat, lng, text)
        setPointA(result)
        sessionStorage.setItem('pointA', result)
        sessionStorage.setItem('s_lat', lat)
        sessionStorage.setItem('s_lon', lng)
      }

      function _suggestionSelectHotel(result, lat, lng, text) {
        //console.log(result, lat, lng, text)
        setHotel(result)
        sessionStorage.setItem('pointHotel1', result)
        sessionStorage.setItem('h1_lat', lat)
        sessionStorage.setItem('h1_lon', lng)
      }
      const AirbnbSlider = styled2(Slider)(({ theme }) => ({
        color: 'purple',
        height: 3,
        padding: '13px 0',
        '& .MuiSlider-thumb': {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid purple',
        '&:hover': {
        boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
        '& .airbnb-bar': {
        height: 9,
        width: 1,
        backgroundColor: 'purple',
        marginLeft: 1,
        marginRight: 1,
        },
        },
        '& .MuiSlider-valueLabel': {
          fontSize: 12,
          fontWeight: 'normal',
          top: -6,
          backgroundColor: 'unset',
          color: theme.palette.text.primary,
          '&:before': {
            display: 'none',
          },
          '& *': {
            background: 'transparent',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
          },
        },
        '& .MuiSlider-track': {
        height: 3,
        },
        '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? 'purple' : '#d8d8d8',
        opacity: theme.palette.mode === 'dark' ? undefined : 1,
        height: 3,
        },
        }));
        function valuetext(value) {
          return value;
          }
        const AirbnbThumbComponent = (props) => {
        const { children, ...other } = props;
        return (
        <SliderThumb {...other}>
        {children}
        <span className="airbnb-bar" />
        <span className="airbnb-bar" />
        <span className="airbnb-bar" />
        </SliderThumb>
        );
        }


      if (isLoading){
        return <div><div className="loading-content"><div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> <span class='tracking-in-expand'> Fivsixing...</span></div></div>

    }

return (
  <div>
    <div className={animate ? "home2-outer home2-outer-exit" : "home2-outer"}>{
        dateFlag &&
        <div class='home2'>
            <div className='home2-logo'>

            <span className='heading-h1'> From where are you starting?</span>

            {/* <form id='firstrun'> */}
            <div class="autocomplete">
            {/* <GeoapifyContext apiKey="f25398151728411991ee08b1184d003c">
            <GeoapifyGeocoderAutocomplete placeholder="Enter address here"
            type={'city'}
            position={'relative'}
            lang={'en'}
            countryCodes={'GB'}
            limit={4}
            
            suggestionsChange={onSuggectionChange}
             />
            </GeoapifyContext> */}
            
       
            <MapboxAutocomplete publicKey='pk.eyJ1IjoibmtjaGFrc2h1IiwiYSI6ImNsZHB3azFrMzAzMWwzeHFzc2xsZmRmY3UifQ.BUZONHzljpdhL_2bMIVrhw'
                    inputClass='form-control search'
                    placeholder="Enter address here"
                    position={'relative'}
                    class="autocomplete"
                    onSuggestionSelect={_suggestionSelect}
                    resetSearch={false}/>
            {/* <div id="autocomplete" ></div> */}
            {/* <AddressAutocomplete   onChange={event => setPointA(event.target.value)}/> */}
            {/* <input  type="text" id="PointA" name="PointA" placeholder="Starting location" onChange={event => setPointA(event.target.value)}/>  */}
            </div>
            {/* </form> */}
        </div>
        <br></br>
        <div className='home2-logo'>
                  <span className='heading-h1'>When do you wish to travel?</span>
            </div>
        <br></br>
        <div className={animate ? "home2-outer home2-outer-exit" : "home2-outer"}>
        <div className='date-container'>
       
            <br></br>
                  <span className='heading-h2'>Leave home at</span>
                  <br></br>
                  <div className='date-container-individual'>
                  {/* <DatePicker id="datepicker" placeholderText='Date(s)' selectsRange={true} startDate={startDate} minDate={new Date()} endDate={endDate} onChange={(update) => {setDateRange(update)}} isClearable={true} /> */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker disablePast 
                    label="Departing date"
                    slotProps={{
                      textField: {
                        helperText: 'MM / DD / YYYY',
                        className:"myDatePicker"
                      },
                    }}
                    onChange={(update) => {setStartdate(new Date(update.getTime() + 86400000))}}
                  />
                  <div className='time-selector'>
                  <MobileTimePicker defaultValue={new Date()} slotProps={{
                      textField: {
                        helperText: 'hh:mm',
                        className:"myDatePicker"
                      },
                    }} minutesStep={5} onChange={(update) => {setStarttime(update)}} label="Departing time" />
                    </div>
                  </LocalizationProvider>
                  </div>
                  <br></br>
                  {pointA && <div className='return-date'>
                  <span className='heading-h2'>Return home by</span>
                  <br></br>
                  </div>
                  }
                  {pointA &&
                  <div className='date-container-individual'>
                    <br></br>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker minDate={calculateDistance(parseFloat(sessionStorage.getItem('s_lat')), parseFloat(sessionStorage.getItem('s_lon')), parseFloat(sessionStorage.getItem('d_lat')), parseFloat(sessionStorage.getItem('d_lon')))<=300.0?startDate.getTime()-86400000:startDate} disablePast 
                    label="Returning date"
                    slotProps={{
                      textField: {
                        helperText: 'MM / DD / YYYY',
                        className:"myDatePicker"
                      }, 
                    }}
                    // renderInput={(params) => (
                    //   <TextField {...params} className="myDatePicker" />
                    // )}
                    onChange={(update) => {setEnddate(new Date(update.getTime() + 86400000))}}
                  />
                  <div className='time-selector'>
                  <MobileTimePicker defaultValue={new Date()} slotProps={{
                      textField: {
                        helperText: 'hh:mm',
                        className:"myDatePicker"
                      },
                    }} minutesStep={5} onChange={(update) => {setEndtime(update)}} label="Return home by" />
                    </div>
                  </LocalizationProvider>
                    </div>}

                  <div className='time-selector'>
                    {/* <label>Start time
                  <DatePicker selected={startTime} onChange={(time) => setStarttime(time)} showTimeSelect showTimeSelectOnly timeIntervals={15} shouldCloseOnSelect={true} timeCaption="Time" dateFormat="h:mm aa" />
                  </label>
                  <label>End time
                  <DatePicker selected={endTime} onChange={(time) => setEndtime(time)} showTimeSelect showTimeSelectOnly timeIntervals={15} shouldCloseOnSelect={true} timeCaption="Time" dateFormat="h:mm aa" />
                  </label> */}
                  </div> 
                  </div>
                           
        </div>
    
        {/* <Checkbox label='Driving only (Select if no flights are to be searched)' checked={event=>(setDriveonly(event.target.checked))}> Driving only (Select if no flights are to be searched) </Checkbox> */}

        <div className='home2-logo'>
       
      <br></br>
      <span className='heading-h2'>Number of Passengers</span><br></br>
        <div class='people-selector'>
            
            <br></br>
                  <label>Adults
                  <NumberPicker className='npicker' defaultValue={0} step={1} max={12} min={1} onChange={(value)=> setAdults(value)}/>
                  </label>
                  <br></br>
                  <label>Children (0 - 17 yrs)
                  <NumberPicker className='npicker' defaultValue={0} step={1} max={10} min={0} onChange={(value)=> setChildren(value)}/>   
                  </label>
                  <br></br>
                  
                  </div>  
                  <br></br>
                  <br></br>
                  <FormControlLabel label="Driving only (Select if no flights are to be searched)"
        control={<Checkbox onChange={(event)=>(setDriveonly(event.target.checked))} />}
      />   
        </div> 
        {/* <DropdownButton
            as={ButtonGroup}
            key='Currency'
            title='Currency'
            id={'dropdown-button-drop-currency'}
            size="sm"
            variant="secondary"
            onSelect={event=>(setCurrency(event))}
            class='bootstrap/dist/css/bootstrap.css'
          >
            <Dropdown.Item value='GBP' eventKey="GBP" >GBP</Dropdown.Item>
            <Dropdown.Item value='USD' eventKey="USD" >USD</Dropdown.Item>
            <Dropdown.Item value='EUR' eventKey="EUR" >EUR</Dropdown.Item>
          </DropdownButton> */}
                          {
            isMessage &&
            <div class='warning'>{message}<br></br> </div>
            
        }
        
        <div className='submit-home2-start'>
        <ButtonSelect onClick={backHandler}>Back</ButtonSelect> 
               <ButtonSelect onClick={changeHandler}>Next</ButtonSelect> 
               </div>
        </div>
        }

        {budgetFlag &&
        <div className='home2'>
        <div className='home2-logo'>
            <span className='heading-h1'>What's your budget?</span><br></br>
            <br></br>
            <span>If you have already booked your hotel please choose the 'Pre-booked' option</span>
       
        <form id='budget' className="home2-budget" onSubmit={changeHandler}>
            <label className='container-home2'><input type='radio' name="budget" onChange={event => {setBudget(event.target.value); setMinbudget(40.00); setMaxbudget(71.00); setab(false)}} value='backpackers'/><figure className='menu-figure'><img class='menu-logo'src={backpacklogo}/> <figcaption>BackPacker(s)<br></br> £40-£70</figcaption></figure></label>
            <label className='container-home2'><input type='radio' name="budget" onChange={event => {setBudget(event.target.value); setMinbudget(71.00); setMaxbudget(111.00); setab(false)}} value='simple'/><figure className='menu-figure'><img class='menu-logo'src={cheaproom}/> <figcaption>Pocket friendly<br></br> £71-£110</figcaption></figure></label>
            <label className='container-home2'><input type='radio' name="budget" onChange={event => {setBudget(event.target.value); setMinbudget(111.00); setMaxbudget(299.0); setab(false)}} value='medium'/><figure className='menu-figure'><img class='menu-logo'src={mediumroom}/> <figcaption>Comfortable<br></br> £111-£299</figcaption></figure></label>
            <label className='container-home2'><input type='radio' name="budget" onChange={event => {setBudget(event.target.value); setMinbudget(299.0); setMaxbudget(2000.00); setab(false)}} value='lavish'/><figure className='menu-figure'><img class='menu-logo'src={ritzy}/> <figcaption>Ritzy<br></br> £300+</figcaption></figure></label>
            <label className='container-home2'><input type='radio' name="budget" onChange={event => {setBudget(event.target.value); setab(true)}} value='ab'/> <figure className='menu-figure'><img class='menu-logo'src={cool}/> <figcaption>Pre-booked<br></br> I'm cool</figcaption></figure></label>
            <br></br>
            {
            ab && 
            
            <div class="autocomplete2">
                <p className='heading-h3'>Please provide us with your hotel location, we'll plan your itinerary accordingly.</p>
            {/* <GeoapifyContext apiKey="f25398151728411991ee08b1184d003c">
            <GeoapifyGeocoderAutocomplete placeholder="Where is your hotel?"
            type={'amenity'}
            position={'relative'}
            lang={'en'}
            countryCodes={'GB'}
            limit={4}
            placeSelect={onPlaceSelect}
            suggestionsChange={onSuggectionChange}
             />
            </GeoapifyContext> */}
            <MapboxAutocomplete publicKey='pk.eyJ1IjoibmtjaGFrc2h1IiwiYSI6ImNsZHB3azFrMzAzMWwzeHFzc2xsZmRmY3UifQ.BUZONHzljpdhL_2bMIVrhw'
                    inputClass='form-control search'
                    placeholder="Where is your hotel?"
                    onSuggestionSelect={_suggestionSelectHotel}
                    resetSearch={false}/>
            
            </div>
        }
                    {
            isMessage &&
            <div class='warning'>{message}</div>
            
        }
            <div className='submit-home2-start'>

        <br></br>
        <ButtonSelect onClick={backHandler}>Back</ButtonSelect> 
            <ButtonSelect type='submit' value='setbudget'> Next</ButtonSelect>
            </div>

        </form>

        </div>
        <p className='home2-credits'>Images by macrovector, storyset, catalyststuff, pikisuperstar and upklyak on Freepik</p>
        </div>
        
        }

        { tpFlag &&   
        <div className={animate ? "home2-outer home2-outer-exit" : "home2-outer"}>
        <div className='home2'>
            <div className='home2-logo'>
            <span className='heading-h1'>What do you wish to do on this trip?</span>
            
        <form className="Home2-travelplan" onSubmit={changeHandler}>
            <label className='container-home2'><input type='radio' name="tp" value="travellerfull"/><figure className='menu-figure'><img class='menu-logo'src={tp_full}/> <figcaption>Surprise me!<br></br><br></br> (I'm a traveller)  </figcaption></figure> </label>
            <label className='container-home2'><input type='radio' name="tp" disabled value="travellerhalf"/><figure className='menu-figure'><img class='menu-logo'src={tp_mid}/> <figcaption>I already know what I want to visit<br></br> (I'm a traveller)</figcaption></figure></label>
            <label className='container-home2'><input type='radio' name="tp" disabled value="businesstrip"/><figure className='menu-figure'><img class='menu-logo'src={tp_business}/> <figcaption>I'm on business<br></br> (know where my meetings are)</figcaption></figure></label>
            <div className='submit-home2-start'>

            {
            isMessage &&
            <div class='warning'>{message}</div>
            
        }
        
        <ButtonSelect onClick={backHandler}>Back</ButtonSelect> 
            <ButtonSelect type='submit' value='setbudget'> Select your plan</ButtonSelect>
            </div>
        </form>
        </div>
        <p className='home2-credits'>Images by storyset on Freepik</p>
        </div>
        </div>
        }
        {/* { foodFlag &&   
            <div>
                <div className='home2-logo'>
            <span className='heading-h1'>Where would you like to have your food?</span>
            
        <form className="Home2-food" onSubmit={changeHandler}>
            <label className='container-home2'><input type='radio' name="food" value='takeaway'/><figure className='menu-figure'><img class='menu-logo'src={cool}/> <figcaption>Takeaway</figcaption></figure></label>
            <label className='container-home2'><input type='radio' name="food" disabled value='streetfood'/><figure className='menu-figure'><img class='menu-logo'src={cool}/> <figcaption>Street Food</figcaption></figure></label>
            <label className='container-home2'><input type='radio' name="food" value='normalrest'/><figure className='menu-figure'><img class='menu-logo'src={cool}/> <figcaption>Pocket-friendly restaurant</figcaption></figure></label>
            <label className='container-home2'><input type='radio' name="food" value='highendrest'/><figure className='menu-figure'><img class='menu-logo'src={cool}/> <figcaption>High-end restaurant</figcaption></figure></label>
            <label className='container-home2'><input type='radio' name="food" disabled value='michelinrest'/><figure className='menu-figure'><img class='menu-logo'src={cool}/> <figcaption>Michelin restaurant</figcaption></figure></label>
            <label className='container-home2'><input type='radio' name="food" value='nr'/><figure className='menu-figure'><img class='menu-logo'src={cool}/> <figcaption>Nah! I'll cook</figcaption></figure></label>
            <div className='submit-home2-start'>
            <ButtonSelect type='submit' value='setbudget'> Choose where to eat</ButtonSelect>
            </div>
            
        </form>
        </div>
        </div>
        } */}
        { flightsFlag &&
          <div className='home2'>
          <div class='home2-logo'>
          <span className='heading-h1'>Which flight do you prefer?</span>
          </div>
          <div className='home2-outer'>
            <div class='flights-list'>
            {/* <List> */}
            <Accordion onChange={uuid=>setFlightssel(uuid[0])} class='flights-list-accordian'>
      {flightitems.current.map((item, index) => (
                //console.log(JSON.stringify(item.departure_airport_full)),
                <AccordionItem uuid={index}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                         <span className='gy'>{(item.airlines_all.length===1 && item.re_airlines_all.length===1?item.airline:" Multiple Airlines")}<br></br><span className="gz">{' Depart: '+getFlightTime(item.departure_time)+' flight '+(item.stops>0?"with "+item.stops+" stop(s)":" and direct")}<br></br>{'Return: '+getFlightTime(item.re_departure_time)+' flight '+(item.re_stops>0?"with "+item.re_stops+" stop(s)":"and direct")}</span></span> <span className='gp'><span class='gbp2'>{item.currency+' '+item.price.toFixed(2)}</span><span className='gz'>round-trip</span></span>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className='outwards'>
                        <span className='gx'>Departing flight  <br></br></span>
                        <br></br>
                        
                        <span className='heading-h3'>{formatDate(item.departure_time)
                        }</span><br></br>
                        <br></br>
                        <span className='heading-h5'>{' '+item.airlines_all.map((ali, fg_index)=>(" "+removeCommas(ali)))}</span>
                        <br></br>
                        <br></br>
                        <span className='hjk'><span className='gw'>{item.departure_airport_full +' ('+item.departure_airport+')'}</span>{'  '}<span className='heading-h3'>{item.departure_time.substring(11,16)}</span>{'----'}<span className='gx'>{item.stops>0?item.stops+' stop':"Direct"}</span>{'---> '}<span className='gw'>{item.arrival_airport_full +' ('+item.arrival_airport+')'}</span>{'  '}<span className='heading-h3'>{item.arrival_time.substring(11,16)}</span> <span className='extraday'>{(getDayDifference(item.arrival_time, item.departure_time)>0?' +'+getDayDifference(item.arrival_time, item.departure_time)+' day(s)':"") + '   '}</span></span>
            
                        {/* {'Stops:'+item.stops} */}
                 
                        {item.stops>0?
    //                     <div class="bootstrapiso">
    //                     <Accordion2>
                        
    //                     <Accordion2.Item eventKey="1">

    //                             <Accordion2.Header><span className='gx'>Details</span></Accordion2.Header>
    //                             <Accordion2.Body>
                                   
                                        
                                        
    //                                     {/* {item.segments} */}
    //                         {/* {'Departure: '+item.departure_airport+' ('+item.departure_time.substring(11,16)+')'+ ' --> Arrival: '+item.arrival_airport+' ('+item.arrival_time.substring(11,16) + ')  Stops:'+item.stops} */}
    //                         {item.segments.map((seg, indexInner) => (
                                
    //   <p>
     
    //     {'Dep:' + seg.dep_ap + '(' + seg.dep + ') --> Arr:' + seg.arr_ap + '(' + seg.arr + ')'}
    //     <br></br>
    //   </p>
    // ))}
                       
    //                     </Accordion2.Body>
    //                     </Accordion2.Item>

    //                    </Accordion2>
                            // </div>
                            <div className="flights-inner-list">
                            <Accordion>
                            <AccordionItem uuid={9999}>
                            <AccordionItemHeading>
                            <AccordionItemButton>
                            Details
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              
                            {item.segments.map((seg, indexInner) => (
                               <p> {seg.dep_ap+'('+seg.dep.substring(11,16)+')'+(getDayDifference(seg.dep, item.departure_time)>0?' (+'+getDayDifference(seg.dep, item.departure_time)+'Day)':"") +'----> '+seg.arr_ap+'('+seg.arr.substring(11,16)+')'+(getDayDifference(seg.arr, item.departure_time)>0?' (+'+getDayDifference(seg.arr, item.departure_time)+'Day)':"")} <br></br></p>
                               
               
                                
))}
                            </AccordionItemPanel>
                            </AccordionItem>
                            </Accordion>
                            </div>
                        :null}
                        {/* {if item.stops>0?'Layover:'+ item.layover:null} */}
                        <br></br>
                        </p>
                        <p className='outwards'>
                        <span className='gx'>Returning flight <br></br></span>
                        <br></br>
                        <span className='heading-h3'>{formatDate(item.re_departure_time)
                        }</span><br></br>
                        <br></br>
                        <span className='heading-h5'>{' '+item.re_airlines_all.map((ali, fg_index)=>(" "+removeCommas(ali)))}</span>

                        <br></br>
                        <br></br>
                        <span className='hjk'><span className='gw'>{item.re_departure_airport_full +' ('+item.re_departure_airport+')'}</span>{'  '}<span className='heading-h3'>{item.re_departure_time.substring(11,16)}</span>{'  ----'}<span className='gx'>{item.re_stops>0?item.re_stops+' stop':"Direct"}</span>{'---> '}<span className='gw'>{item.re_arrival_airport_full+' ('+item.re_arrival_airport+')'}</span>{' '}<span className='heading-h3'>{item.re_arrival_time.substring(11,16)}</span><span className='extraday'>{(getDayDifference(item.re_arrival_time, item.re_departure_time)>0?' +'+getDayDifference(item.re_arrival_time, item.re_departure_time)+' day(s)':"") + ' '}</span></span>
                        <br></br>
                    
                        {/* {'Stops:'+item.re_stops} */}
                        {item.re_stops>0?

                            <div className="flights-inner-list">
                            <Accordion>
                            <AccordionItem uuid={9999}>
                            <AccordionItemHeading>
                            <AccordionItemButton>
                            Details
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              
                            {item.re_segments.map((seg, indexInner) => (
                              <p> {seg.dep_ap+'('+seg.dep.substring(11,16)+')'+(getDayDifference(seg.dep, item.re_departure_time)>0?' (+'+getDayDifference(seg.dep, item.re_departure_time)+'Day)':"") +'----> '+seg.arr_ap+'('+seg.arr.substring(11,16)+')'+(getDayDifference(seg.arr, item.re_departure_time)>0?' (+'+getDayDifference(seg.arr, item.re_departure_time)+'Day)':"")} <br></br></p>
                              // <p><span className='gw'>{seg.dep_ap_full +' ('+seg.dep_ap+')'}</span>{'  '}<span className='heading-h3'>{item.re_departure_time.substring(11,16)}</span>{'  --------> '}<span className='gw'>{item.re_arrival_airport_full+' ('+item.re_arrival_airport+')'}</span>{' '}<span className='heading-h3'>{item.re_arrival_time.substring(11,16)}</span><span className='extraday'>{(getDayDifference(item.re_arrival_time, item.re_departure_time)>0?' +'+getDayDifference(item.re_arrival_time, item.re_departure_time)+' day(s)':"") + ' '}</span></p>
                               
               
                                
))}
                            </AccordionItemPanel>
                            </AccordionItem>
                            </Accordion>
                            </div>
                        :null}
                        <br></br> 
                        </p>
                        <ButtonSelect onClick={changeHandler}>Select flight</ButtonSelect>
                    </AccordionItemPanel>
                </AccordionItem>

      ))}
      </Accordion>
      </div>
    {/* </List> */}
    </div>
    </div>
        }

{ hotelsFlag &&
            <div className='home2'>
            <div class='home2-logo'>
            <span className='heading-h1'>Where would you like to stay?</span>
            </div>
            <div>
          <Dropdown label="Filter">
          <div className='heading-h3'>Price</div>
          
            <Box sx={{ width: 320, border: '1px solid purple', borderRadius: '1em' }}>
              
            <AirbnbSlider
            valueLabelDisplay="on"
            slots={{ thumb: AirbnbThumbComponent}}
            getAriaValueText={(newValue, index) => {
              if (index === 0) {
                setMinbudget(newValue);
  
              } else {
                setMaxbudget(newValue);

              }
            }}
            defaultValue={[minBudget, maxBudget]}
            max={500}
            min={10}
            />
      </Box>
      <ButtonSelect onClick={filterHandler}>Apply Filter</ButtonSelect>
      </Dropdown>
      </div>
            <div className='home2-outer-hotel'>

      <br></br>
            <div class='hotels-list'>
            {/* <List> */}
            
            {nullHotel?<div>Oops! Looks like we couldn't find any available hotels for the given dates and budget.{nullHotel}<br></br></div>:fhAcc()}
      {/* {hoteloffersavailable && <span>Oops! We couldn't find any hotels wihtin your budget for the given dates.</span>} */}
    {/* </List> */}
    </div>
    </div>
    <div class='daybuttons'>
    <ButtonSelect onClick={hotelHandler}>Proceed without a hotel</ButtonSelect>
 
    </div>

    <p className='heading-h5 home2-outer'>Without a hotel, city centre would be taken as your stay reference for overnight stays</p>
    </div>
        }
       
    </div>
    <br></br>
     <div className='ads heading-h5'>
      <div>We're powered by ads, they help us keep our services free. We use a minimal amount of ads to preserve your experience.<br></br></div>
      <br></br>
      <div>
     <iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=288&l=ur1&category=ukecofriendly&banner=12EPWP5FCCQJ0YW5C282&f=ifr&linkID=f5d25393249537d1041667bc08ea958e&t=nkchakshuuklt-21&tracking_id=nkchakshuuklt-21" width={320} height={50} scrolling="no" border={0} marginWidth={0} style={{border: 'none'}} frameBorder={0} sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation" />
     </div>
     </div>
     </div>
)
}

export default Home2;