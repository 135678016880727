import React, {useState, useEffect, useRef, createRef} from 'react';
import axios from 'axios';
import { QRCodeCanvas } from "qrcode.react";
import Cookies from 'universal-cookie';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import PasswordChecklist from "react-password-checklist";

const registerUrl = 'https://66plz5argg.execute-api.eu-west-2.amazonaws.com/beta/api/v1/register/';
const newIdsURL = 'https://66plz5argg.execute-api.eu-west-2.amazonaws.com/beta/api/v1/newids/';
const loginUrl = 'https://66plz5argg.execute-api.eu-west-2.amazonaws.com/beta/api/v1/verify/';


    
    // PointsA.map((Point)=>(window.getElementById(Point).value = Point, document.getElementById(Point).checked=false));
    // PointsB.map((Point)=>(window.getElementById(Point).value = Point));
    // PointsC.map((Point)=>(window.getElementById(Point).value = Point));
    // window.getElementById("table").reset();
    // window.getElementById("t1").reset();
    // window.getElementById("t2").reset();
    // window.getElementById("t3").reset();
    const forge = require('node-forge');
    const encryptData = (publicKeyPath, data) => {
        const publicKey = process.env.REACT_APP_PWD_PUBLIC_SECRET_KEY;
      const publicKeyObj = forge.pki.publicKeyFromPem(publicKey);
      const encryptedBytes = publicKeyObj.encrypt(data, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
      });
      const encrypted = forge.util.encode64(encryptedBytes);
      return encrypted;
    };

    const decryptData = (data) => {
      const privateKey = process.env.REACT_APP_LOGIN_PRIVATE_SECRET_KEY;
      const privateKeyObj = forge.pki.privateKeyFromPem(privateKey);
    //   console.log(data)
      const decrypteddata = forge.util.decode64(data);
      const decryptedBytes = privateKeyObj.decrypt(decrypteddata, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
      });
    //   console.log(decryptedBytes)
      return decryptedBytes;
    };
    
    function getOffset(el) {
        const rect = el.getBoundingClientRect();
        return {
          left: rect.left + window.scrollX,
          top: rect.top + window.scrollY
        };
      }
      const ButtonLogin = styled.button({
        color: 'grey',
        background: 'whitesmoke',
        'border-radius': '0.2em',
        border: '1px solid purple',
        color:'purple',
        'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
        'font-size': '100%',
        margin: '0 0.25em 0 0.25em',
        '&:active':{transform: 'translateY(4px)'},
        '&:hover':{transform: 'translateY(2px)'},
        'box-shadow': '0.18em 0.22em black'
      });    
      const ButtonLoginDisabled = styled.button({
        color: 'grey',
        background: 'lightgray',
        'border-radius': '0.2em',
        border: '1px solid purple',
        color:'purple',
        'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
        'font-size': '100%',
        margin: '0 0.25em 0 0.25em',
        // '&:active':{transform: 'translateY(4px)'},
        // '&:hover':{transform: 'translateY(2px)'},
        'box-shadow': '0.18em 0.22em black'
      });    
const Button = styled.button({
        color: 'grey',
        background: 'transparent',
        width: "30%",
        height: "50%",
        'border-radius': '0.2em',
        border: '1px solid purple',
        color:'purple',
        'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
        'font-size': '100%',
        margin: '0 1em 0 1em',
        '&:hover':{background: 'gold'},
        '&:selection':{background: 'gold'}, 
        '&:active':{transform: 'translateY(4px)'},
        '&:hover':{transform: 'translateY(2px)'},
        'box-shadow': '0.18em 0.22em black'
      });

      const ButtonSelect = styled.button({
        color: 'grey',
        background: 'whitesmoke',
        width: "30%",
        height: "110%",
        'border-radius': '0.2em',
        border: '1px solid purple',
        color:'purple',
        'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
        'font-size': '100%',
        margin: '0 2em 0 2em',
        '&:hover':{background: 'gold'},
        '&:hover':{transform: 'translateY(2px)'},
        '&:selection':{background: 'gold'}, 
        '&:active':{transform: 'translateY(4px)'},
        'box-shadow': '0.18em 0.22em black'
      });

      function getPoints(pattern){

        //console.log('im in')
        const xs = [];
        const ys = [];

        pattern.map((ptn)=>{
            var element = document.getElementById(ptn);
            xs.push(Math.floor(getOffset(element).left));
            ys.push(Math.floor(getOffset(element).top));
            //console.log(xs)
            //console.log(ys)
        })
        if (xs.length > 1){
        //console.log('sdnff')
        var c = document.getElementById("login-canvas");
        var ctx = c.getContext("2d");

        // ctx.width = window.innerWidth;
        // ctx.height = window.innerHeight;

        for (let i = 0; i < xs.length-1; i++) {
 
            ctx.beginPath();
            //ctx.moveTo(0, 0);
            
            ctx.moveTo(xs[i], ys[i]);
            //console.log('pone');
            //
            
            //ctx.lineTo(500, 700);
     
            //console.log(xs[i], ys[i]); 
            //console.log(xs[i+1], ys[i+1]);   
            
            ctx.lineTo(xs[i+1], ys[i+1]);

            ctx.stroke();   
        } 
    }
        

            
}

const Register = () => {
    const [RegisterFlag, setRegisterFlag] = useState(false);
    const [PatternFlag, setPatternFlag] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordvalid, setPasswordValid] = useState(false);
    const [emailId, setEmailId] = useState("");
    const [repassword, setRepassword] = useState("");
    const [CreateIDFlag, setCreateIDFlag] = useState(true);
    const [rememberMe, setRememberme] = useState(false);
    const [isVerifying, setVerifying] = useState(false);
    const [isReseting, setReseting] = useState(false);
    const [UserName, setUserName] = useState(''); 
    const [UserID, setUserID] = useState(''); 
    const [Prashne, setPrashne] = useState(0); 
    const [RevolvingID, setRevolvingID] = useState(''); 
    const [username, setUsername] = useState(''); 
    const [ptn, setPtn] = useState([]);
    const hjl = createRef();
    const [otp, setOtp] = useState(0);
    const [newLogin, setNewLogin] = useState(false);
    const [message, setMessage] = useState(null);
    const [errormessage, setErrorMessage] = useState(null);
    const [url, setUrl] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const PointsA = ['A1', 'A2', 'A3'];
    const PointsB = ['N1', 'N2', 'N3'];
    const PointsC = ['X5', 'X6', 'X7'];
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const cookies = new Cookies();
    var [UserIDs, setUserIDarray] = useState([]);
    const [token, setToken] = useState(false);
    const captchaRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "FivSix Register"
      }, []);

    const onLoad = () => {
        // this reaches out to the hCaptcha JS API and runs the
        // execute function on it. you can use other functions as
        // documented here:
        // https://docs.hcaptcha.com/configuration#jsapi
        captchaRef.current.execute();
      };
      
      useEffect(() => {
      
        if (token)
          console.log('hcaptcha')
        //   console.log(`hCaptcha Token: ${token}`);
      
      }, [token]);

  
    // useEffect(() => {
    //      if (isReseting){
    //          cancelCourse();
    //      }
    //  }, [])

    const qrcodex = (
        <QRCodeCanvas
          id="qrCode"
          value={url}
          size={300}
          bgColor={'white'}
          includeMargin={'true'}
          fgColor={'black'}
          level={"H"}
        />
      );
    
      const ResetHandler = (event) => {
        document.getElementById("pattern").reset();
        setPtn([]);
        setButtonDisabled(false);
        // document.getElementById("pattern").refresh();
        // setReseting(false);
        PointsA.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
        PointsB.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
        PointsC.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
    }
    const gotologinHandler = (event) => {
        let path = '/login';
        navigate(path); 

    }

    const registerHandler = (event) => {

        event.preventDefault();
        // console.log('inputpattern')
        // console.log(ptn)
        setButtonDisabled(true);
        setVerifying(true);
        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        }
    const requestBodyDummy = {}

    const qrCodeEncoder = (e) => {
        // console.log(e.data.url)
        // console.log('Decrypting data')
        // console.log(decryptData(e.data.url))
        setUrl(decryptData(e.data.url));
      };
    var numencrypt = encryptData("fivsix_14623.pub",password)
    const requestBody = {
        UserID: UserID,
        UserName: UserName,
        PatternShape: 'square',
        Pattern: numencrypt,//ptn.join(),
        RevolvingID: RevolvingID,
        Prashne: 0,//prashne,
        Uththara: 'gjj',//uththara
    }

    if (CreateIDFlag === true){
        const resp = axios.post(newIdsURL,requestBodyDummy, requestConfig).then(response=>{
            for (const [i, names] of response.data.UserNames.entries()) {
                UserIDs.push(names);
              };
            setRevolvingID(response.data.RID);
            setUserID(response.data.UserID);
            setRegisterFlag(true);
            setButtonDisabled(false);
            setVerifying(false);
        }).then(setCreateIDFlag(false));

        return;
    }
    else if (CreateIDFlag === false && PatternFlag === false && RegisterFlag === true){

        setRegisterFlag(false);
        setPatternFlag(true);
        setButtonDisabled(false);
        setVerifying(false);
        return;
    }
    else if (CreateIDFlag === false && PatternFlag === true && RegisterFlag === false){
        console.log('bling bling')
    //     const myLock = PatternLock({

    //         $canvas: document.querySelector('#patlock'),
    //         grid: [3, 3],
    //         theme: 'default'
    //   });

    //   myLock.setTheme({
    //     default: {
    //         colors: {
    //             accent: '#1abc9c',     // Accent color for node
    //             primary: '#ffffff',    // Primary node and line color
    //             bg: '#000000',         // Canvas background color
    //         },
    //         dimens: {
    //             node_radius: 20,       // Radius of the outer ring of a node
    //             line_width: 6,         // Thickness of the line joining nodes
    //             node_core: 8,          // Radius of the inner circle of a node
    //             node_ring: 1,          // Outer ring thickness
    //         }
    //     },
    //     success: {
    //         colors: {
    //             accent: '#51e980',     // Green accent on successful match
    //         }
    //     },
    //     failure: {
    //         colors: {
    //             accent: '#e74c3c',     // Red accent on an unsuccessful match
    //         }
    //     },
    //     customState: {                 // Your custom state
    //         dimens: {
    //             node_radius: 25,       // Increases the node radius
    //         }
    //     },
    // });
    //     myLock.onComplete(({ hash }) => ptn);
    //     console.log(ptn)
        if (ptn.join().length < 0 && !RegisterFlag){
            setReseting(true);
            setButtonDisabled(false);
            setVerifying(false);
            setErrorMessage('Pattern must use more than 5 points.');
            setPtn([]);
            document.getElementById("pattern").reset();
            // document.getElementById("pattern").refresh();
            // setReseting(false);
            PointsA.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
            PointsB.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
            PointsC.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
            return;
        }
        setErrorMessage('');
        //console.log('recieved ptn');
        //console.log(ptn.join());
        //console.log(UserName);
        setPatternFlag(false);

        const resp = axios.post(registerUrl,requestBody, requestConfig).then(response=>{
            if (response.status===200){
                setVerifying(false);
                setButtonDisabled(false);
                qrCodeEncoder(response);
                setMessage('Before you complete your registration, multi-factor authentication needs to be set up and verfified.\n Please scan this qr code on a trusted authenticator app for MFA key. Please enter the MFA key on your app to verify.');
            }

    })
    }
    }

    const submitHandler = (event) => {
        event.preventDefault();

        if (UserName.trim() === '' || ptn.join() !== ''){
            setMessage('All fields are required.');
            return;
        }
        if (ptn.join().length < 0){
            setMessage('Pattern must use more than 5 points.');
            return;
        }
        if (otp.length === 6){
            setButtonDisabled(true);
            setVerifying(true);
            setMessage('Authenticating ....')

        }
        //console.log('submit pressed')

    const requestConfig = {
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    }
    var numencrypt = encryptData("fivsix_14623.pub",password)
    const requestBody = {
        UserName: UserName,
        PTN: numencrypt,//ptn.join(),ptn.join(),
        OTP: otp,
        NewLogin: newLogin,
        RevolvingID: RevolvingID,
        RememberMe: rememberMe
        
    }

    axios.post(loginUrl, requestBody, requestConfig).then(response=>{
        if (response.data.status==='MFA'){
            setOtp('');
        }
        else if (response.data.status==='TMD'){
            setMessage('Too many devices are using this account. Only 5 devices are allowed. If you want to connect more devices, please contact info@fivsix.com');
        }
        else if (response.data.status==='invalid_p'){
            setVerifying(false);
            setMessage('Verification Failed. Invalid attempt. If problem persists, please contact info@fivsix.com.');
        }
        else if (response.data.status==='True'){
        setVerifying(false);
        setMessage('MFA verified. User registered successfully.');
        cookies.set('rid-'+UserName, RevolvingID, { path: '/' });
        }
        else if (response.data.status==='False'){
            setVerifying(false);
            setMessage('MFA not verified but user registered. You will be asked to verify when you login.');
        }
        //console.log(response);
        
    }).catch(error=>{
        //console.log(error.response)
        if (error.response.status === 422){
            setMessage(error.response.data.message);
        }
        else{
            setMessage('Oops.... We\'re facing problems on our servers. Please try agian later.')
        }
    })
} 

if (buttonDisabled && isVerifying){
    return <div className="loading-content"><div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
}





return (<div>
    {CreateIDFlag && <div className='register'><form onSubmit={registerHandler}>
        
        At Fivsix we build our products with privacy in mind. Therefore, by default our accounts have anonymous usernames. <br></br>
        By clicking on 'create new user', you agree to our privacy policies and accept to follow our community guidelines.<br></br>
<form>
      <HCaptcha
        sitekey="6c45e609-e1e4-481d-a566-d12c2feda8e4"
        onLoad={onLoad}
        onVerify={setToken}
        ref={captchaRef}
      />
    </form>
    { token &&
        <Button type="submit" value="Create New User">Create New User</Button>
}
        
    </form><div className='register-1'></div></div> }
    {RegisterFlag && <form onSubmit={registerHandler} className='register'>
            <span className='heading-h1'>Choose your Username, Wisely!</span>
            <br></br>
            <br></br>
            <span>Choose one name from five randomly generated usernames </span>
            <div className='circle-container'>
           {UserIDs.map((UserID)=>(
            <label><li><input type="radio" name='username' id={UserID} value={UserID} onChange={event => setUserName(event.target.value)} />{UserID} </li></label>))}
            </div>
            <br></br>
            <ButtonSelect type="submit" value="Select">Select</ButtonSelect>
           </form>
        }
    {PatternFlag && 
    <div className='register'>
        <p className='heading-h2'>Your chosen username is '{UserName}'</p>
        <form id='pattern' ref={hjl} onSubmit={registerHandler}>
        {/* <table class='tablelogin'>
               <canvas className='login-canvas' id='login-canvas'></canvas>  
        <tr>{PointsA.map((Point)=>(<td><label class="container"><input type="radio" name={Point} id={Point} value={Point} onChange={event=>(ptn.push(event.target.value), getPoints(ptn))}/>
        <span class="checkmark"></span></label></td>))}</tr>
        <tr>{PointsB.map((Point)=>(<td><label class="container"><input type="radio" name={Point} id={Point} value={Point} onChange={event=>(ptn.push(event.target.value), getPoints(ptn))}/>
        <span class="checkmark"></span></label></td>))}</tr>    
        <tr>{PointsC.map((Point)=>(<td><label class="container"><input type="radio" name={Point} id={Point} value={Point} onChange={event=>(ptn.push(event.target.value), getPoints(ptn))}/>
        <span class="checkmark"></span></label></td>))}</tr>    
        
        </table>
             <input type="password" onChange={e => setPassword(e.target.value)} />

			<PasswordChecklist
				rules={["minLength","specialChar","number","capital","match"]}
				minLength={5}
				value={password}  
				onChange={(isValid) => {}}
			/>

        <ButtonLogin type="submit" value="Set Pattern">Set Pattern</ButtonLogin>
        <ButtonLogin type="reset" onClick={ResetHandler}> Reset</ButtonLogin> */}
        <p className='heading-h3'>Enter your password</p>
             <input type="password" onChange={e => setPassword(e.target.value)} />
            
<PasswordChecklist
    rules={["minLength", "maxLength","specialChar","number","capital","match"]}
    minLength={7}
    maxLength={16}
    value={password}  
    valueAgain={repassword}
    onChange={(isValid) => {setPasswordValid(isValid)}}
/>
<br></br>
<p className='heading-h3'>Re-enter your password</p>
             <input type="password" onChange={e => setRepassword(e.target.value)} />
             <br></br>
<br></br>   {passwordvalid?
             <ButtonLogin type="submit" value="Set Pattern">Register</ButtonLogin>: <ButtonLoginDisabled type="submit" disabled value="Set Pattern">Register</ButtonLoginDisabled>
}
        </form>
        </div> 
        }
    {errormessage && <p className='message'>{errormessage}</p>}
    {message && 
    <div>
    { !buttonDisabled &&
    <div className='register'>
        <span className='heading-h1'>Safety first... because we care about you!</span>
    <div className='home2-outer'>{qrcodex}</div>
    <p className='message-register'><span className='heading-h2'>Before you complete your registration...</span><br></br> <br></br>
    Multi-factor authentication needs to be set up for protecting your account.<br></br><br></br> Please scan this QR code on a trusted authenticator app (such as Google Authenticator or Microsoft Authenticator) for your MFA key.</p>
    <br></br>
    <form> 
        {/* onSubmit={submitHandler} */}
    <div className='note-register'>
    <span className='heading-h2'>If you can't scan the code </span>
<br></br>
    <span>Enter use the URL below for key (time-based) in your authenticator app</span>
    <br></br>
    <br></br>
    <span>URL: {url}</span>
    <br></br>
    </div>
    <p className='message'>Please be aware that this is the only time you can set this up, this QR code will not be available ever again.<br></br> Access to your account will not be possible without an MFA key</p>

        {/* <label className='heading-h5'>Multi-factor Key<br/><input type="text" className='input-login' placeholder='Key' onChange={event => setOtp(event.target.value)}/><br/></label><br/>
        <ButtonLogin type="submit" value="verify"> Verify</ButtonLogin> */}
        <br></br>
        <ButtonSelect onClick={gotologinHandler}>Done</ButtonSelect>
    </form>
    </div>
    }
    
    { buttonDisabled &&
        <div className='home2-outer-user'>
            <div className='home2'>
                <div className='home2-logo'>
        <span className='home2-outer'>{message}<br></br></span>
        <br></br>
        <br></br>
 
{       
        <ButtonSelect onClick={gotologinHandler}>Done</ButtonSelect>
}
        </div>
        </div>
        </div>
    }

    </div>}
    </div>
   )
}

export default Register;