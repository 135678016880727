import axios from 'axios';

const askUrl = 'https://66plz5argg.execute-api.eu-west-2.amazonaws.com/beta/api/v1/itihasakelu/';
const setUrl = 'https://66plz5argg.execute-api.eu-west-2.amazonaws.com/beta/api/v1/itihasahaku/';

export async function loadHistory(username, revolvingID, token){
    const requestConfig = {
      headers: {
          'x-api-key': 'zPGenFQ1BUa65I6xJcWpe1bdlKBkwBVI6Tg6VMvh'
      }
    }

    const requestBody = {
      UserName: username,
      RevolvingID: revolvingID,
      Token: token
    }

    axios.post(askUrl, requestBody, requestConfig).then(
    response=>{
       console.log(response);
      if (response.data.message==="IVD"){
        sessionStorage.setItem('sessionInvalid', true);
      }
      else{
        sessionStorage.setItem('sessionInvalid', false);
        if (response.data.message==="empty"){
        sessionStorage.setItem('contentEmptyFlag', true);
        sessionStorage.setItem('content', 'NA');
        sessionStorage.setItem('sessionInvalid', false);
        }
        else{
           console.log('Im here')
           sessionStorage.setItem('sessionInvalid', false);
          sessionStorage.setItem('contentEmptyFlag', false);
          sessionStorage.setItem('content', JSON.stringify(response.data.message));
        }
       }
      }
      
    ).catch((error)=>{sessionStorage.setItem('contentEmptyFlag', true);})
    return

}

export function setHistory(username, revolvingID, token, dictlist){
    const requestConfig = {
      headers: {
          'x-api-key': 'zPGenFQ1BUa65I6xJcWpe1bdlKBkwBVI6Tg6VMvh'
      }
    }
    
    const requestBody = {
      UserName: username,
      RevolvingID: revolvingID,
      Token: token,
      DictList: dictlist
   
    }
    
    axios.post(setUrl, requestBody, requestConfig).then(response=>(console.log(response)))
//   .then(
//     response=>{
    //   if (response.data.message==="IVD"){
    //     null;
    //   }
    //   else{
    //     null;
    //     }
    //   }
//   )
  
    }