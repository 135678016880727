import React, {useEffect, useState, useRef} from "react";
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { setUserSession } from './service/AuthService';
import styled from 'styled-components';
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Input from "@mui/material/Input";


import { KMSClient, EncryptCommand } from "@aws-sdk/client-kms";
import { Buffer } from "buffer";
// const CryptoJS = require('crypto-js');
// const NodeRSA = require('node-rsa');
// import JSEncrypt from 'jsencrypt';
// import 'crypto';

// const encryptData = async (publicKeyFilePath, data) => {
//   const encrypt = new JSEncrypt();

//   const formattedKey = `-----BEGIN PUBLIC KEY-----
//   MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAoo3TVJzBseghzgoKlaQ7
//   8tGPwy+zpoO9Q8GAD8FfK+s+E2cfRy7xAo7HGpWajTdgx+ZwtsCOBlf+gAGSlRsG
//   EZ2qCAIPplqgkHZzpsq832ToxifoD6nj4ut7HLwpBYDaeYaxImeA+0XEBaETMaZn
//   RR7ZyzQ5y4vEGym0U5g2XJ/3amGbUThPW1z8ilnxhmX6fXEfUCO2S27eVZYSKcRi
//   stPYbfcm10aQHuXtsJUgEU/9GuZCq3nJwVmg84jpN9UL61InVWMKj9LcTDQS2gPw
//   dipLn3DQ/yx3rbqCZTJXSiU3xl6nZvtS3SbWsmaM/me8nA2TO3pu9RH9ooV3+QzS
//   YftgkJy8cpxGVeQk4Bo/owhR7bBPL1QHcuqFuhc+pQyVIRBzT5KxVOOzALtNjhmg
//   l/oHN0N1lOpKR49QJfzfrDWSyxOHHE3+8hxKfz14920FzmSdLG0kFfINTp+cbKMN
//   qUNKbM7v/WFzJoh4oMkBx9YXL2jy0FfyuL/aHkA2DMG1gBjRjwbk2AIR48BM2cmP
//   6YBLjP0TFGbGWifjYabMZBehXPqVSaCpX9niLF7GVAAPyly4TgYwd9rmDF71agt5
//   aAEPcLN7hsnFWAe1u67SnbDWNzk0HZbVtG+lxwcDXNWxn4ecMFr7Gr8vMZB2t9X/
//   T5F+QI/x7Ur6D9ka/753p7sCAwEAAQ==
//   -----END PUBLIC KEY-----`;
//   encrypt.setPublicKey(formattedKey);
//   const encrypted = encrypt.encrypt(data);
//   return encrypted;
// };
// const encryptData = async(publicKeyPath, data) => {
//   const publicKey = `-----BEGIN PUBLIC KEY-----
//   MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAoo3TVJzBseghzgoKlaQ7
//   8tGPwy+zpoO9Q8GAD8FfK+s+E2cfRy7xAo7HGpWajTdgx+ZwtsCOBlf+gAGSlRsG
//   EZ2qCAIPplqgkHZzpsq832ToxifoD6nj4ut7HLwpBYDaeYaxImeA+0XEBaETMaZn
//   RR7ZyzQ5y4vEGym0U5g2XJ/3amGbUThPW1z8ilnxhmX6fXEfUCO2S27eVZYSKcRi
//   stPYbfcm10aQHuXtsJUgEU/9GuZCq3nJwVmg84jpN9UL61InVWMKj9LcTDQS2gPw
//   dipLn3DQ/yx3rbqCZTJXSiU3xl6nZvtS3SbWsmaM/me8nA2TO3pu9RH9ooV3+QzS
//   YftgkJy8cpxGVeQk4Bo/owhR7bBPL1QHcuqFuhc+pQyVIRBzT5KxVOOzALtNjhmg
//   l/oHN0N1lOpKR49QJfzfrDWSyxOHHE3+8hxKfz14920FzmSdLG0kFfINTp+cbKMN
//   qUNKbM7v/WFzJoh4oMkBx9YXL2jy0FfyuL/aHkA2DMG1gBjRjwbk2AIR48BM2cmP
//   6YBLjP0TFGbGWifjYabMZBehXPqVSaCpX9niLF7GVAAPyly4TgYwd9rmDF71agt5
//   aAEPcLN7hsnFWAe1u67SnbDWNzk0HZbVtG+lxwcDXNWxn4ecMFr7Gr8vMZB2t9X/
//   T5F+QI/x7Ur6D9ka/753p7sCAwEAAQ==
//   -----END PUBLIC KEY-----`;
//   const encrypted = CryptoJS.AES.encrypt(data, publicKey).toString();
//   return encrypted;
// };
const forge = require('node-forge');

//Function to encrypt data using a public RSA key
const encryptData = async(publicKeyPath, data) => {
    const publicKey = process.env.REACT_APP_PWD_PUBLIC_SECRET_KEY;
  const publicKeyObj = forge.pki.publicKeyFromPem(publicKey);
  const encryptedBytes = publicKeyObj.encrypt(data, 'RSA-OAEP', {
    md: forge.md.sha256.create(),
  });
  const encrypted = forge.util.encode64(encryptedBytes);
  return encrypted;
};


// const encryptData = async (publicKeyPath, data) => {
//   const publicKey = `-----BEGIN PUBLIC KEY-----
//   MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAoo3TVJzBseghzgoKlaQ7
//   8tGPwy+zpoO9Q8GAD8FfK+s+E2cfRy7xAo7HGpWajTdgx+ZwtsCOBlf+gAGSlRsG
//   EZ2qCAIPplqgkHZzpsq832ToxifoD6nj4ut7HLwpBYDaeYaxImeA+0XEBaETMaZn
//   RR7ZyzQ5y4vEGym0U5g2XJ/3amGbUThPW1z8ilnxhmX6fXEfUCO2S27eVZYSKcRi
//   stPYbfcm10aQHuXtsJUgEU/9GuZCq3nJwVmg84jpN9UL61InVWMKj9LcTDQS2gPw
//   dipLn3DQ/yx3rbqCZTJXSiU3xl6nZvtS3SbWsmaM/me8nA2TO3pu9RH9ooV3+QzS
//   YftgkJy8cpxGVeQk4Bo/owhR7bBPL1QHcuqFuhc+pQyVIRBzT5KxVOOzALtNjhmg
//   l/oHN0N1lOpKR49QJfzfrDWSyxOHHE3+8hxKfz14920FzmSdLG0kFfINTp+cbKMN
//   qUNKbM7v/WFzJoh4oMkBx9YXL2jy0FfyuL/aHkA2DMG1gBjRjwbk2AIR48BM2cmP
//   6YBLjP0TFGbGWifjYabMZBehXPqVSaCpX9niLF7GVAAPyly4TgYwd9rmDF71agt5
//   aAEPcLN7hsnFWAe1u67SnbDWNzk0HZbVtG+lxwcDXNWxn4ecMFr7Gr8vMZB2t9X/
//   T5F+QI/x7Ur6D9ka/753p7sCAwEAAQ==
//   -----END PUBLIC KEY-----`;

//   const rsaKey = new NodeRSA(publicKey);
//   rsaKey.setOptions({ encryptionScheme: 'pkcs1' });
  
//   const encrypted = rsaKey.encrypt(data, 'base64');

//   return encrypted;
// }
// AWS.config.update({
//   accessKeyId: 'AKIA5BVXAPCFWHBVC3YR', 
//   secretAccessKey: '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',
//   region: 'eu-west-2'
// });

// const encryptPassword = async (password) => {
//   console.log('in enc hub')
//   const kms = new AWS.KMS();

//   const params = {
//     KeyId: 'd484c0e4-38db-4c83-a00f-b8e5cb4359dd',
//     Plaintext: password,
//     EncryptionAlgorithm: 'RSAES_OAEP_SHA_256'
//   };

//   const response = await kms.encrypt(params).promise();
//   // const encryptedPassword = response.CiphertextBlob.toString('base64');
//   const encryptedPassword = response.CiphertextBlob.toString('base64');
//   console.log('in enc hub 2')
//   console.log(encryptedPassword)
//   return encryptedPassword;
// };
const encrypt = async (text) =>{
  //console.log('in enc hub')
  // const kms = new AWS.KMS({
  //     accessKeyId: 'AKIA5BVXAPCFWHBVC3YR', //credentials for your IAM user
  //     secretAccessKey: 'RZ6lY59CPLthMVEDS8ktsUis02vzrahOwFJsfhI6', //credentials for your IAM user
  //     region: 'eu-west-2'
  // });
  const creds = {
    accessKeyId: 'AKIA5BVXAPCFWHBVC3YR', //credentials for your IAM user
    secretAccessKey: 'RZ6lY59CPLthMVEDS8ktsUis02vzrahOwFJsfhI6'}

  const kms = new KMSClient({ //credentials for your IAM user
  region: 'eu-west-2', credentials: creds });

        const params = {
          KeyId: 'd484c0e4-38db-4c83-a00f-b8e5cb4359dd', // The identifier of the CMK to use for encryption. You can use the key ID or Amazon Resource Name (ARN) of the CMK, or the name or ARN of an alias that refers to the CMK.
          Plaintext: text,// The data to encrypt.
          EncryptionAlgorithm: 'RSAES_OAEP_SHA_1',

      };
  // const response = await kms.encrypt(params).promise();
  const command = new EncryptCommand(params);
const encryptPromise = await kms.send(command);
  // const encryptPromise = kms.encrypt(params).promise();

const timeoutPromise = new Promise((resolve, reject) => {
  setTimeout(() => {
    reject(new Error('Encryption operation timed out.'));
  }, 10000); // Adjust the timeout value (in milliseconds) as needed
});

try {
  const response = await Promise.race([encryptPromise, timeoutPromise]);
  const encryptedPassword = response.CiphertextBlob;
  // console.log(response.CiphertextBlob)
  // console.log('in enc hub 2')
  // console.log(encryptedPassword)
  return encryptedPassword;
} catch (error) {
  //console.log('jdfg')
  //console.log(error)
  // Handle the timeout error or any other potential errors
}
    // const encryptedPassword = response.CiphertextBlob.toString('base64');

  // return new Promise((resolve, reject) => {
  //     const params = {
  //         KeyId: 'd484c0e4-38db-4c83-a00f-b8e5cb4359dd', // The identifier of the CMK to use for encryption. You can use the key ID or Amazon Resource Name (ARN) of the CMK, or the name or ARN of an alias that refers to the CMK.
  //         Plaintext: text,// The data to encrypt.
  //         EncryptionAlgorithm: 'RSAES_OAEP_SHA_256'
  //     };
  //     console.log('in enc hub 2');
  //     kms.encrypt(params, (err, data) => {
  //         if (err) {
  //             console.log(err)
  //             console.log('gg 4')
  //             reject(err);
  //         } else {
  //             console.log('in enc hub 3');
  //             resolve(data.CiphertextBlob.toString('base64'));
  //         }
  //     });
  // });
}

function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }

function getPoints(pattern){

        //console.log('im in')
        const xs = [];
        const ys = [];

        pattern.map((ptn)=>{
            var element = document.getElementById(ptn);
            xs.push(Math.floor(getOffset(element).left));
            ys.push(Math.floor(getOffset(element).top));
            //console.log(xs)
            //console.log(ys)
        })
        if (xs.length > 1){
        //console.log('sdnff')
        var c = document.getElementById("login-canvas");
        var ctx = c.getContext("2d");

        // ctx.width = window.innerWidth;
        // ctx.height = window.innerHeight;

        for (let i = 0; i < xs.length-1; i++) {
 
            ctx.beginPath();
            //ctx.moveTo(0, 0);
            
            ctx.moveTo(xs[i], ys[i]);
            //console.log('pone');
            //
            
            //ctx.lineTo(500, 700);
     
            //console.log(xs[i], ys[i]); 
            //console.log(xs[i+1], ys[i+1]);   
            
            ctx.lineTo(xs[i+1], ys[i+1]);

            ctx.stroke();   
        } 
    }
        

            
}
const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #ccc;
`;
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #ccc;
    &::after {
      content: "\f005";
      font-family: "FontAwesome";
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
  &:checked + ${Item} {
    background: yellowgreen;
    border: 2px solid yellowgreen;
  }
  &:checked + ${RadioButtonLabel} {
    background: yellowgreen;
    border: 1px solid yellowgreen;
    &::after {
      content: "\f005";
      font-family: "FontAwesome";
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
`;

const Button = styled.button({
    color: 'grey',
    background: 'transparent',
    'border-radius': '0.2em',
    border: '1px solid purple',
    color:'purple',
    'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
    'font-size': '100%',
    '&:active':{transform: 'translateY(4px)'},
    '&:hover':{transform: 'translateY(2px)'},
    margin: '0 0.25em 0 0.25em',
    'box-shadow': '0.18em 0.22em black'
  });

const loginUrl = 'https://66plz5argg.execute-api.eu-west-2.amazonaws.com/beta/api/v1/verify/';

const Login = () => {
    const [username, setUsername] = useState('');   
    const [ptn, setPtn] = useState([]);
    const [password, setPassword] = useState("");
    const [bvh, setBvh] = useState("");
    const [otp, setOtp] = useState(0);
    const newLogin = useRef(true);
    const [loginComplete, setLogincomplete] = useState(false);
    const [rememberMe, setRememberme] = useState(false);
    const [MFA, setMFA] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const revolvingID = useRef('');
    const [startLogin, setStartLogin] = useState(true);
    const [message, setMessage] = useState(null);
    const PointsA = ['A1', 'A2', 'A3'];
    const PointsB = ['N1', 'N2', 'N3'];
    const PointsC = ['X5', 'X6', 'X7'];
    const cookies = new Cookies();
    const navigate = useNavigate();
    const publicKey = 'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAACAQC52CLbkWX1eD/iJSGyFMHIC8UwmaGM+v7tDkQTkInaAH7ZSHf3mTUkiIm0cdr78djllvvZZlJSvgZG0v2kozo3VJ2NYjFFe9NdeQonQclDctgDWyQqWF45x2Vdwr7PqJmV207GY2E/9l/PwRv7p5SFNY+x+Mu78mtxYcF1G7Uvty5uOf+kFeYnPYTgeCjvUpzKNM0sFDWoEsAd9Vc6kM92TEJRYh/UhYwi9hRRg+Pp+VEkhL7KkJ++7gWxGbeH4yv4EdXiQzSSATIcrz5/HMaLj+dsyGhXc0hR0ZbE4/FfoNyoAbHmJoMYzV3fUXNsPj0THwV1pC+LPZcGdhii2HyNLw5Y9T0GJz+fAkYHssI3Cb+t1WzQuJOT8ORTPAFqkVkuB6AAe8yxPiuQm5dhQ953FcQzuzCyO5bXDnYAZ77KnTeWQ6mtjJ/1eRhjRYzeJKLxlCDkivIefRopoEyUSi/9si2g+JLMMR8ieowmP0XOj0QPPXBAHTiKNUcBrRcRcadG0HUc4awzh1VJnhzsTE1fP/BmdBjFbhnYOhXUyYCpmjAgm81Ka0NoZOwrh2/ipJ/wMR8ddQP6dtNKd4Kw2Oa+t2i6ENQ4QtHxTOMr/NfHe7iLmNsYmokSRJwpAx1E5a30GFKXAi6kI6cihADrEynTTDqXpUwj03VxrvxZt/EWGw== neerajkavanchakshu@192.168.1.103';
    const [values, setValues] = React.useState({
      password: "",
      showPassword: false,
     });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    useEffect(() => {
      document.title = "FivSix Login"
    }, []);

    function Get_rid(){
        const rid = cookies.get('rid-'+username);
        const ksh = cookies.get('ksh-'+username);
        //console.log(ksh);
        if (rid === 'undefined' || rid === undefined || rid === null || !rid){
            //console.log('srfaerf')
            return;
          } else if(ksh === 'undefined' || ksh === undefined || ksh === null || ksh === 'no'){
            //console.log('fererre')
            return;
          }else{
            //console.log('qwefqwef')
            newLogin.current = false;
            revolvingID.current = rid;
        }
        return (rid)
        };
    
    useEffect(() => {
        Get_rid();
        //console.log(revolvingID);
        }, [])

    const handleClick = event => {
        event.currentTarget.disabled = true;
        //console.log('button clicked');
      };

    const handleOnChange = () => {
        setRememberme(!rememberMe);
      };

    const ResetHandler = (event) => {
        // console.log('hujddnodndj')
        document.getElementById("pattern").reset();
        setPtn([]);
        setButtonDisabled(false);
        // document.getElementById("pattern").refresh();
        // setReseting(false);
        PointsA.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
        PointsB.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
        PointsC.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
        document.getElementById('password').reset();
        document.getElementById('username').reset();
        setMessage(null);
        // window.location.reload(false);
    }
    const SubmitHandler = async (event) => {
  event.preventDefault();
  setButtonDisabled(true);
  setLogincomplete(false);
  const rid = Get_rid();
  if (username.trim() === '' || ptn.join() !== '') {
    setLogincomplete(true);
    setButtonDisabled(false);
    setMessage('All fields are required.');
    return;
  }
  if (ptn.join().length < 0) {
    setMessage('Too short pattern');
    document.getElementById('pattern').reset();
    setPtn([]);
    setButtonDisabled(false);
    PointsA.map((Point) => (document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
    PointsB.map((Point) => (document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
    PointsC.map((Point) => (document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
    return;
  }
  //console.log('submit pressed');
  setMessage('');
  
  const requestConfig = {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY
    }
  }
  
  try {
    // const plaintext = await encrypt(password);
    // const encoder = new TextEncoder();
    // const utf8String = encoder.encode(plaintext);

    // // Encoding the UTF-8 string
    // const base64String = btoa(utf8String);
    // const buffer = Buffer.from(plaintext);

    // // // Encoding the Buffer as Base64
    // const base64String = buffer.toString('base64');
    // var ciphertextBlob = "your ciphertext blob";
    // var base64StringOld = btoa(String.fromCharCode.apply(null, plaintext));
    // var base64StringOld = Array.from(plaintext);
    // var base64String = JSON.stringify({ blob: base64StringOld });

// Transfer the JSON string to Python
// (You can use any method to transfer the data, such as sending it over HTTP or saving it to a file)

    // const base64String = btoa(String.fromCharCode(...plaintext));
    // console.log(base64String);
    // setBvh(plaintext);
    // console.log(bvh);
    var numencrypt = await encryptData("fivsix_14623.pub",password)
    // const buffer = Buffer.from(numencrypt);
    // const base64String = buffer.toString('base64');
    // console.log('tetsing waters')
    // console.log(numencrypt)
    //console.log(password)
    const requestBody = {
      UserName: username.toLowerCase(),
      PTN: numencrypt, // ptn.join(),
      OTP: otp,
      NewLogin: newLogin.current,
      RevolvingID: revolvingID.current,
      RememberMe: rememberMe
    }

    axios.post(loginUrl, requestBody, requestConfig)
      .then(response => {
        if (response.data.status === 'MFA') {
          setStartLogin(false);
          setMFA(true);
          setButtonDisabled(false);
          setOtp('');
        } else if (response.data.status === 'TMD') {
          setLogincomplete(true);
          setButtonDisabled(false);
          setMessage('Too many devices are using this account. Only 10 devices are allowed. If you want to connect more devices, please contact info@fivsix.com');
        } else if (response.data.status === 'invalid_p') {
          setLogincomplete(true);
          setButtonDisabled(false);
          setPtn([]);
          setMessage('Login Failed. Incorrect username or pattern.');
        } else if (response.data.status === 'True') {
          setUserSession(username, response.data.rid, response.data.token, response.data.moolah);
          setLogincomplete(true);
          if (newLogin.current && rememberMe === true) {
            cookies.set('rid-' + username, response.data.rid, { path: '/', secure: true, maxAge: 2592000 });
            cookies.set('ksh-' + username, 'yes', { path: '/', secure: true, maxAge: 2592000 });
          } else if (newLogin.current && !rememberMe) {
            cookies.set('ksh-' + username, 'no', { path: '/', secure: true, maxAge: 2592000 });
            cookies.remove('rid-' + username)
          }
          window.location.reload(false);
        } else if (response.data.status === 'False') {
          setLogincomplete(true);
          setPtn([]);
          setMessage('Login failed');
          setButtonDisabled(false);
          navigate('/login');
        }
      })
      .catch(error => {
        if (error === undefined || error === 'undefined') {
          setMessage('Login failed. Please try again.');
          setPtn([]);
          setLogincomplete(true);
          setMessage('Login failed');
          setButtonDisabled(false);
          navigate('/login');
        } else if (error.response.status === 422) {
          setMessage(error.response.data.message);
        } else {
          setMessage("Oops.... We're facing problems on our servers. Please try again later.")
        }
      });
  } catch (error) {
    //console.error(error);
  }
}
const handlePasswordChange = (prop) => (event) => {
  setValues({ ...values, [prop]: event.target.value });
  setPassword(event.target.value)

};


//     const SubmitHandler = (event) => {
//         event.preventDefault();
//         setButtonDisabled(true);
//         setLogincomplete(false);
//         const rid = Get_rid();
//         if (username.trim() === '' || ptn.join() !== ''){
//             setLogincomplete(true);
//             setButtonDisabled(false);
//             setMessage('All fields are required.');
//             return;
//         }
//         if (ptn.join().length < 0){
//             setMessage('Too short pattern');
//             document.getElementById("pattern").reset();
//             setPtn([]);
//             setButtonDisabled(false);
//             // document.getElementById("pattern").refresh();
//             // setReseting(false);
//             PointsA.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
//             PointsB.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
//             PointsC.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false));
//             return;
//         }
//         console.log('submit pressed');
//         setMessage('');
//         // Get_rid();
//         // if (rid && !termsValidation.current) {
//         //     termsValidation.current = true;
//         //     setNewLogin(false);
//         //     const rid = cookies.get('rid-'+username);
//         //     console.log('im in')
//         //     console.log(rid)
//         //     setRevolvingID(rid);
//         //     termsValidation.current = false;
//         //   }
        
    
//     const requestConfig = {
//         headers: {
//             'x-api-key': process.env.LOGIN_VAR
//         }
//     }
//     // const cancelCourse = (
//     //     setPtn([]),
//     //     setButtonDisabled(false),
//     //         // document.getElementById("pattern").refresh();
//     //         // setReseting(false);
//     //     PointsA.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false)),
//     //     PointsB.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false)),
//     //     PointsC.map((Point)=>(document.getElementById(Point).value = Point, document.getElementById(Point).checked = false))
//     // )

//     // (async () => {
//     //   try {
//     //     const plaintext = await encrypt('abc');
//     //     console.log(plaintext);
//     //     setBvh(plaintext);
//     //   } catch (error) {
//     //     // Handle any potential errors from the encrypt function
//     //     console.error(error);
//     //   }
//     // })();
//     const encryptedPromise = encrypt(password);

//     encryptedPromise
//       .then((plaintext) => {
//         // const plaintextString = plaintext.toString('utf-8');
//         console.log(plaintext);
//         setBvh(plaintext);
//       })
//       .catch((error) => {
//         // Handle any potential errors from the encrypt function
//         console.error(error);
//       });
//     const requestBody = {
//       UserName: username.toLowerCase(),
//       PTN: bvh,//ptn.join(),
//       OTP: otp,
//       NewLogin: newLogin.current,
//       RevolvingID: revolvingID.current,
//       RememberMe: rememberMe
//   }


//     const requestBodyDummy = {}
//     // console.log(requestBody)
//     axios.post(loginUrl, requestBody, requestConfig).then(response=>{
//         if (response.data.status==='MFA'){
//             setStartLogin(false);
//             setMFA(true);
//             setButtonDisabled(false);
//             setOtp('');
//         }
//         else if (response.data.status==='TMD'){
//             setLogincomplete(true);
//             setButtonDisabled(false);
//             setMessage('Too many devices are using this account. Only 10 devices are allowed. If you want to connect more devices, please contact info@fivsix.com');
//         }
//         else if (response.data.status==='invalid_p'){
//             setLogincomplete(true);
//             setButtonDisabled(false);
//             setPtn([]);
//             setMessage('Login Failed. Incorrect username or pattern.');
//         }
//         else if (response.data.status==='True'){
//             setUserSession(username, response.data.rid, response.data.token, response.data.moolah);
//             // console.log(response.data.rid);
//             setLogincomplete(true);
//  //secure: true, ,
//             if (newLogin.current && rememberMe === true){
//                 cookies.set('rid-'+username, response.data.rid, {path:'/', secure: true, maxAge: 2592000});
//                 cookies.set('ksh-'+username, 'yes', {path:'/', secure: true, maxAge: 2592000}); 
//             }
//             else if (newLogin.current && !rememberMe) {
//                 cookies.set('ksh-'+username, 'no', {path:'/', secure: true, maxAge: 2592000}); 
//                 cookies.remove('rid-'+username)
//             }
//             window.location.reload(false);
//             //this.forceUpdate();
//             // navigate('/user');
//             //setMessage('Login successfull');
//         }
//         else if (response.data.status==='False'){
//             setLogincomplete(true);
//             setPtn([]);
//             setMessage('Login failed');
//             setButtonDisabled(false);
//             navigate('/login');
        
//         }
//         // console.log(response);
        
//     }).catch(error=>{
//         // console.log(error);
//         if (error === undefined || error === 'undefined'){
//             setMessage('Login failed. Please try again.');
//             setPtn([]);
//             setLogincomplete(true);
//             setMessage('Login failed');
//             setButtonDisabled(false);
//             navigate('/login');
            
//         }
//         else if (error.response.status === 422){
//             setMessage(error.response.data.message);
//         }
//         else{
//             setMessage('Oops.... We\'re facing problems on our servers. Please try agian later.')
//         }
//     })
// } 
if (buttonDisabled && !loginComplete){
    return <div className="loading-content"><div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
}

    return (

    <div className="home2-outer-user">
      <div className="login">
        { startLogin &&
    <form id='pattern' onSubmit={SubmitHandler}>
        <p className="heading-h1">Login to your way of travelling!</p>
       <label>Your cool username<br></br>
       <input type="text" value={username} className='input-login' id="username" onChange={event => setUsername(event.target.value)}  placeholder="Username"/>
       {/* <Input type="text" value={username} id="username" onChange={event => setUsername(event.target.value)}  placeholder="Username"/> */}
       </label><br/>
       <br></br>
         
        <div>
        
               {/* <table class='tablelogin'>
               <canvas className='login-canvas' id='login-canvas'></canvas>  
        <tr>{PointsA.map((Point)=>(<td><label class="container"><input type="radio" name={Point} id={Point} value={Point} onChange={event=>(ptn.push(event.target.value), getPoints(ptn))}/>
        <span class="checkmark"></span></label></td>))}</tr>
        <tr>{PointsB.map((Point)=>(<td><label class="container"><input type="radio" name={Point} id={Point} value={Point} onChange={event=>(ptn.push(event.target.value), getPoints(ptn))}/>
        <span class="checkmark"></span></label></td>))}</tr>    
        <tr>{PointsC.map((Point)=>(<td><label class="container"><input type="radio" name={Point} id={Point} value={Point} onChange={event=>(ptn.push(event.target.value), getPoints(ptn))}/>
        <span class="checkmark"></span></label></td>))}</tr>    
        
        </table> */}
        <label>Shhh! it's your password<br></br>
        {/* <input type="password" id="password" className='input-login' placeholder="Password" onChange={e => setPassword(e.target.value)} /> */}
      
        {/* <InputLabel htmlFor="standard-adornment-password">
                Enter your Password
            </InputLabel> */}
            <div className='input-login-password'>
            <Input
                type={values.showPassword ? "text" : "password"}
                disableUnderline={true} 
                onChange={handlePasswordChange("password")}
                value={values.password}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            </div>
              </label>
        </div>
        <br></br>
        <span>If you have forgotten your password, please contact <a href="mailto:info@fivsix.com">info@fivsix.com</a></span>
        <br></br>
        <br></br>
        <form>
        <div class="checkboxes">
       <label><input type="checkbox" id="rememberme"  name="rememberme" checked={rememberMe} onChange={handleOnChange}/><span>Remember this device</span></label>

       </div>
       </form>
       {message && <p className="message">{message}<br></br></p>}

        <div class='submit-button'>
        <Button color="success" type="submit" shape="rounded-pill" disabled={buttonDisabled} value="login">Login</Button>
        <Button color="success" type="reset" shape="rounded-pill" disabled={buttonDisabled} onClick={ResetHandler}>Reset</Button>
        </div>
    

        
    </form>}
    {MFA && 
        <div>
        <p className="heading-h2">Authenticate your login (via your pre-chosen Authenticator app)<br/></p>
        <br></br>
        <form onSubmit={SubmitHandler}>
        <label className="heading-h2">Multi-factor Authentication <input type="text" value={otp} className='input-login' placeholder="Your code" onChange={event => setOtp(event.target.value)}/><br/></label>
        {/* <input type="submit" disabled={buttonDisabled} value="verify" /> */}
        <br></br>
        {message && <p className="message">{message}</p>}
        <br></br>
        <Button color="success" type="submit" shape="rounded-pill" disabled={buttonDisabled} value="verify">Authenticate</Button>
        <Button color="success" type="reset" shape="rounded-pill" disabled={buttonDisabled} onClick={ResetHandler}>Reset</Button>
        </form>
        </div>
    }
 
   

    </div> </div>)
} 

export default Login;