// import React from 'react';
// import styled from 'styled-components';
// import land from './LandingBackground.webp'

// const LandingPageContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   background: url('LandingBackground.webp') no-repeat center center fixed;
//   height: 150vh;
//   margin-top: -10vh;
//   background-size: cover;
// `;

// const Heading = styled.h1`
//   font-size: 48px;
//   margin-bottom: 20px;
//   margin-top:-10vh;
// `;

// const Subheading = styled.p`
//   font-size: 24px;
//   margin-bottom: 40px;
// `;

// const Button = styled.button`
//   padding: 10px 20px;
//   font-size: 18px;
//   background-color: #fff;
//   color: #000;
//   border: 2px solid purple;
//   border-radius: 5px;

//   cursor: pointer;
//   transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

//   &:hover {
//     background-color: #000;
//     color: #fff;
//   }
// `;

// const LandingPage = () => {
//   return (
//     <LandingPageContainer>
//       <Heading>Welcome to FivSix Travel</Heading>
//       <Subheading>Discover your next adventure planning with us</Subheading>
//       <Button>Explore Destinations</Button>
//     </LandingPageContainer>
//   );
// };

// export default LandingPage;

import React from 'react';
import './Landingstyles.css'; // Assuming the CSS file is named 'styles.css'
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import Carousel from './carousel';
import LazyLoad from 'react-lazy-load';
import logo from './josh_logo_dup.webp';


const ButtonSelect = styled.button({
  color: 'grey',
  background: 'white',
  width: "46%",
  height: "30%",
  'border-radius': '0.2em',
  border: '1px solid purple',
  color:'purple',
  'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
  'font-size': '100%',
  margin: '0 2em 0 2em',
  '&:hover':{background: 'gold'},
  '&:hover':{transform: 'translateY(2px)'},
  '&:selection':{background: 'gold'}, 
  '&:active':{transform: 'translateY(4px)'},
  'box-shadow': '0.18em 0.22em black'
});

const ButtonLoginDisabled = styled.button({
  color: 'grey',
  background: 'lightgray',
  'border-radius': '0.2em',
  border: '1px solid purple',
  color:'purple',
  'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
  'font-size': '100%',
  margin: '0 0.25em 0 0.25em',
  // '&:active':{transform: 'translateY(4px)'},
  // '&:hover':{transform: 'translateY(2px)'},
  'box-shadow': '0.18em 0.22em black'
});  

const LandingPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    let path = '/checklists_homepage'; 
    navigate(path);
  };
  
  return (
    <div className="landing-page">
        <LazyLoad>
  <img class="main_image_landing" src={logo}/>
  </LazyLoad>
      <div className="media-section">
        {/* <video className="background-video" autoPlay loop muted>
          <source src="Volcano_Lava_Sample.webm" type="video/webm" />
        </video> */}
        {/* <img className="foreground-image" src="landing_page_1.webp" alt="Foreground Image" /> */}
        <Carousel />
        <br></br>
        <h1 className="title">Trailblazing travel solutions</h1>
      </div>
      <div className="bottom-section">
        <div className="section">
          <img className="logo" src="checklist_logo.png" alt="Logo 1" />
          <p className="text">Gist-a-lists</p>
          <ButtonSelect onClick={handleClick}>Travel gist-a-lists</ButtonSelect>
          <p className='textsmall'> Scientifically designed checklists for streamlined and efficient verfification. </p>
        </div>
        <div className="section">
          <img className="logo" src="maps.svg" alt="Logo 2" />
          <p className="text">The Complete Itinerary Planner</p>
          <ButtonLoginDisabled disabled onClick={handleClick}>Plan your trips</ButtonLoginDisabled>
          <p className='textsmall'>Oops! This is under maintenance.</p>

          <p className='textsmall'>The whole nine yards of travel planning, with each bit tailored to your taste.</p>
        </div>
        <div className="section">
          <img className="logo" src="walking.svg" alt="Logo 3" />
          <p className="text">Stroll, Patrol, Soul</p>
          <p className='textsmall'>Personalised walks for your soul .... Aum...</p>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;