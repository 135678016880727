import React, { useState, useEffect, useRef } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo_pdf from './josh_logo_dup.png';
import checkmarkImage from './Correct.png';
import checkmarkImageFalse from './Cross.png';
import styled from 'styled-components';
import './background_checklists.css';
import Helmet from 'react-helmet';
import imgd from './bkg.webp';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { S3 } from 'aws-sdk';

const ButtonSelect = styled.button({
  color: 'grey',
  background: 'white',
  width: "36%",
  height: "4.5vh",
  'border-radius': '0.2em',
  border: '1px solid purple',
  color:'purple',
  'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
  'font-size': '100%',
  margin: '0 2em 0 2em',
  // '&:hover':{background: 'gold'},
  cursor: 'pointer', 
  zIndex:'3',
  '&:hover':{transform: 'translateY(5px)'},
  // '&:selection':{background: 'gold'}, 
  '&:active':{transform: 'translateY(4px)'},
  'box-shadow': '0.18em 0.22em black'
});
const ButtonSelect2 = styled.button({
  color: 'grey',
  background: 'white',
  width: "60%",
  height: "4.5vh",
  'border-radius': '0.2em',
  border: '1px solid purple',
  color:'purple',
  'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
  'font-size': '100%',
  margin: '0 2em 0 2em',
  // '&:hover':{background: 'gold'},
  cursor: 'pointer', 
  zIndex:'3',
  '&:hover':{transform: 'translateY(5px)'},
  // '&:selection':{background: 'gold'}, 
  '&:active':{transform: 'translateY(4px)'},
  'box-shadow': '0.18em 0.22em black'
});

const ChecklistPagePacking = () => {
  const [answers, setAnswers] = useState({
    Howlong: [],

  });
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [activeQuestioncount, setActiveQuestioncount] = useState(0);
  const completedQuestions = useRef([])
  const [skipQuestion, setSkipQuestion] = useState(false);
  const [activeQuestions, setActiveQuestions] = useState(['Howlong']); // Set the initial active questions
  // const [basicQuestions, setBasicQuestions] = useState(['Travelmode', 'BabyMode', 'Lights','Refrigerator']);
  const [showQuestion, setShowQuestion] = useState(false);
  const [introductory, setIntroductory] = useState(true);
  const [showWaiting, setShowWaiting] = useState(false);
  const [complete, setComplete] = useState(false);
  const navigate = useNavigate();

  const handleIntroductory = () => {
    setIntroductory(false);
    setShowQuestion(true);
  }
  const returnHomepage = () => {
    let path = '/checklists_homepage';
    navigate(path);
  }

  useEffect(() => {
    const container = document.querySelector('.checklist-container');
    container.classList.add('fade-in');

    return () => {
      container.classList.remove('fade-in');
    };
  }, [currentQuestion]);
 

  // const saveAnswersToS3 = (answers) => {
  //   const bucketName = 'your-bucket-name';
  //   const accessKeyId = 'your-access-key-id';
  //   const secretAccessKey = 'your-secret-access-key';
  //   const region = 'your-region';
  //   const filename = 'answers.pickle';
  
  //   const s3 = new S3({
  //     accessKeyId,
  //     secretAccessKey,
  //     region,
  //   });
  
  //   const params = {
  //     Bucket: bucketName,
  //     Key: filename,
  //     Body: JSON.stringify(answers),
  //   };
  
  //   s3.upload(params, (err, data) => {
  //     if (err) {
  //       console.log('Error uploading file:', err);
  //     } else {
  //       console.log('File uploaded successfully:', data.Location);
  //     }
  //   });
  // };
  
  // const ChecklistPagePacking = () => {
  //   const [answers, setAnswers] = useState({
  //     Howlong: [],
  //     // ... other answer fields
  //   });
  
  //   const handleSaveAnswers = () => {
  //     saveAnswersToS3(answers);
  //   };
  

const generatePDF = () => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  let yPos = 20;

  // Add logo to the first page
  const logoPath = 'image.png';
  const logoSize = 60;
  const logoX = (pageWidth - logoSize) / 2;
  const logoY = yPos;
  doc.addImage(logo_pdf, 'PNG', logoX, logoY, logoSize, logoSize);

  // Set font and size for the title on the first page
  doc.setFont('Helvetica', 'bold');
  doc.setFontSize(20);
  doc.setTextColor('#333');
  doc.text('Questionnaire Answers', pageWidth / 2, logoY + logoSize + 20, { align: 'center' });

  // Add space between logo and questions
  yPos += logoSize + 50;

  const checkmarkSize = 10; // Size of the checkmark image

  activeQuestions.forEach((questionId) => {
    const question = questions.find((q) => q.id === questionId);
    if (!question) return;

    const answer = answers[questionId];
    const checkmark = answer ? checkmarkImage : 'X'; // Use checkmark image or 'X' for cross
    const color = (answer==='yes'||answer===true) ? 'green' : question.multi?'black':'red';

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(12);
    doc.setTextColor(color);

    const checkmarkX = 20; // X position of the checkmark image
    const checkmarkY = yPos + 4; // Y position of the checkmark image
    const checkmarkWidth = checkmarkSize; // Width of the checkmark image

    if (answer==='yes'||answer===true) {
      doc.addImage(checkmarkImage, checkmarkX, checkmarkY, checkmarkWidth, checkmarkSize);
    } else {

      if (!question.multi){
          doc.addImage(checkmarkImageFalse, checkmarkX, checkmarkY, checkmarkWidth, checkmarkSize);
      }
    }

    const questionTextX = checkmarkX + checkmarkWidth + 5; // X position of the question text
    const questionTextY = yPos + 10; // Y position of the question text

    if (question.multi && Array.isArray(answer) && answer.length > 0) {
      const selectedAnswers = answer.join(', ');

      const textWidth = doc.getStringUnitWidth(selectedAnswers);
      const availableWidth = pageWidth - questionTextX - 10;

      if (textWidth > availableWidth) {
        doc.setFontSize(10);
        if (question.multi || (answer==='yes'||answer===true)){
        doc.text(questionTextX, questionTextY, question.response, { maxWidth: availableWidth });
        }
        // else if (answer==='n/a'){
        //   doc.text(questionTextX, questionTextY, question.responsefalse, { maxWidth: availableWidth }); 
        //   }
        else{
        doc.text(questionTextX, questionTextY, question.responsefalse, { maxWidth: availableWidth }); 
        }

        yPos += 5;
      } else {
        if (question.multi || (answer==='yes'||answer===true)){
          doc.text(questionTextX, questionTextY, question.response);
          }
          else{
          doc.text(questionTextX, questionTextY, question.responsefalse); 
          }
      }

      doc.setFontSize(12);
      doc.text(questionTextX, questionTextY + 10, selectedAnswers, { maxWidth: availableWidth });
      yPos += 20;
    } else {
      if (question.multi || (answer==='yes'||answer===true)){
        doc.text(questionTextX, questionTextY, question.response);
        }
        else{
        doc.text(questionTextX, questionTextY, question.responsefalse); 
        }
      yPos += 10;
    }

    // Check if there's enough space for the footer
    if (yPos > pageHeight - 30) {
      doc.addPage();
      yPos = 20;
    }
  });


// Add footer to every page
const disclaimer = 'Disclaimer: Fivsix.com and its parent company \'NKCHAKSHU UK LTD\', along with any related entities, shall not be held responsible for any issues arising from the use of the checklists provided on this website. For detailed legal information, please refer to the full legal disclaimer on this website. This concise disclaimer serves as a summary and reminder that the Company is not liable for any consequences resulting from the use of the checklists. Please read the full legal disclaimer for complete details.';
const timestamp = new Date().toLocaleString('en-US', { timeZone: 'UTC' });;

const footerX = 10; // X position of the footer
const footerY = pageHeight - 10; // Y position of the footer
const maxFooterWidth = pageWidth - 20; // Maximum width of the footer

doc.setFont('Helvetica', 'normal');
doc.setFontSize(6);
doc.setTextColor('#999');
doc.text(`Generated on (UTC): ${timestamp}`, 5, pageHeight/2, { align: 'left', maxWidth: maxFooterWidth, angle: 90, valign: 'middle' });


doc.setFont('Helvetica', 'italic');
doc.setFontSize(7);
doc.setTextColor('#999');
doc.text(disclaimer, footerX, footerY, { align: 'left', maxWidth: maxFooterWidth });



  doc.save('questionnaire.pdf');
};

  
  // useEffect(() => {
  //   // Filter and set the active questions based on their availability
  //   setActiveQuestions(questions.filter(question => answers[question.id] === null).map(question => question.id));
  // }, [answers]);

  const findActiveQuestionsByPrefix = (questions, prefix) => {
    const activeQuestionsLocal = questions.filter(question => question.id.startsWith(prefix));
    if (activeQuestionsLocal !==[]){
    return activeQuestionsLocal.map(question => question.id);
    }
    else{
      return null
    }
  };
  // const activeQuestions = findActiveQuestionsByPrefix(questions, 'x');  
  const removeDuplicates = (list) => {
    const uniqueList = Array.from(new Set(list));
    return uniqueList;
  };
  const handleAnswerChange = (question, answer, type, checked) => {
    if (type==='multi'){
      setAnswers(prevAnswers => {
        console.log(prevAnswers)
        console.log(answer)
        console.log(prevAnswers[question])
        if (prevAnswers[question].includes(answer) && !checked) {
          // Remove the answer from the array
          const updatedAnswers = prevAnswers[question].filter(a => a !== answer);
          return {
            ...prevAnswers,
            [question]: updatedAnswers,
          };
        } else {
          // Add the answer to the array
          return {
            ...prevAnswers,
            [question]: [...prevAnswers[question], answer],
          };
        }
      });
  }
  else{
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [question]: answer,
    }));
  }
    console.log(answers);
  };
  const removeValuesFromList = (list, valuesToRemove) => {
    const updatedList = list.filter(item => !valuesToRemove.includes(item));
    return updatedList;
  };
  
  const handleNextQuestion = () => {
    setShowQuestion(false);
    setShowWaiting(true);
    completedQuestions.current.push(currentQuestion);
    setTimeout(() => {
      if (activeQuestioncount < activeQuestions.length - 1){
        var i = currentQuestion;
        while (i < questions.length - 1){
          console.log(currentQuestion)
          console.log('fjk')
        if (activeQuestions.includes(questions[i + 1].id)) {
          setActiveQuestioncount(prevQuestion => prevQuestion + 1);
          i++
          break;
        }
        else{
          console.log(currentQuestion);
          i++;
          
        }
      }
      if (questions[currentQuestion].multi){
        const localActiveqs = []
        const removeOlds = []
        activeQuestions.map(qs=>localActiveqs.push(qs))
        answers[questions[currentQuestion].id].map(categories => (findActiveQuestionsByPrefix(questions, categories).map(found => localActiveqs.push(found))));
        questions[currentQuestion].ans.map(categories => ((!answers[questions[currentQuestion].id].includes(categories))? findActiveQuestionsByPrefix(questions, categories).map(found => removeOlds.push(found)):null));
        const uniqueList = removeDuplicates(localActiveqs);
        console.log(removeOlds)
        const uniqueList2 = removeValuesFromList(uniqueList, removeOlds)
        setActiveQuestions(uniqueList2)
      }
      // setCurrentQuestion(prevQuestion => prevQuestion + 1);
      setCurrentQuestion(i);
      setShowWaiting(false);
      setShowQuestion(true);
      
    }
      else{
        setComplete(true);
      }
      console.log(activeQuestions)
      console.log(questions[currentQuestion])
    }, 500); // Wait for the fade-out animation to complete (0.5 seconds)
  };

  const handlePreviousQuestion = () => {
    setShowQuestion(false);
    setShowWaiting(true);
    setTimeout(() => {
      var i = currentQuestion;
      while (i > 0){
        if (activeQuestions.includes(questions[i - 1].id)) {
          setActiveQuestioncount(prevQuestion => prevQuestion - 1);
          i--
          break;
          }
        else{
          i--;
        }
      }
      setCurrentQuestion(i);
      setShowWaiting(false);
      setShowQuestion(true);
    }, 500); // Wait for the fade-out animation to complete (0.5 seconds)
  };

  const questions = [
    {
      id: 'ToiletriesPassport',
      text: 'Do you have all of your passports?',
      multi: false,
      single: true,
      response: 'Have the passport(s).',
      responsefalse: 'Don\'t have the passport(s)',
      ans: [],
      adLabel: 'None',
    },

    {
      id: 'ToiletriesFlightTickets',
      text: 'Have you kept your flight tickets?',
      multi: false,
      single: true,
      ans: [],
      response: 'Have kept your flight tickets.',
      responsefalse: 'Have not kept your flight tickets.',
      additionaldata: 'If you have e-tickets, make sure they are accessible. If it\'s in an email put a star to it.',
      adLabel: 'None',
    },
        {
      id: 'ToiletriesBus/CoachTickets',
      text: 'Have you kept your bus/coach tickets?',
      multi: false,
      single: true,
      ans: [],
      response: 'Have kept your flight tickets.',
      responsefalse: 'Have not kept your flight tickets.',
      additionaldata: 'If you have e-tickets, make sure they are accessible. If it\'s in an email put a star to it.',
      adLabel: 'None',
    },
        {
      id: 'ToiletriesTrainTickets',
      text: 'Have you kept your train tickets?',
      multi: false,
      single: true,
      response: 'Have the train ticket(s).',
      responsefalse: 'Do not have the train ticket(s).',
      ans: [],
      additionaldata:'If you have e-tickets, make sure they are accessible. If it\'s in an email put a star to it.',
      adLabel: 'None',
    },
        {
      id: 'ToiletriesTrainRailCard',
      text: 'Have you kept your railcard?',
      multi: false,
      single: true,
      ans: [],
      response: 'Have the railcard(s).',
      responsefalse: ' Do not have the railcard(s).',
      additionaldata:'If you are using RailCard App make sure it is downloaded (or not offloaded) and active.',
      adLabel: 'None',
    },
            {
      id: 'ToiletriesChargers',
      text: 'Have you packed chargers for all of your devices (Phones, Tablets, Laptops, e-Readers etc.)?',
      multi: false,
      single: true,
      response: 'Have packed the chargers for all my devices.',
      responsefalse: 'Have not packed the chargers for all my devices.',
      ans: [],
      adLabel: 'None',
    },
        {
      id: 'ToiletriesPowerbanks',
      text: 'If you prefer, have you packed your powerbanks ? (Make sure you packed it in the right luggage and does not exceed capacity limits, check TSA/local security guidelines for more information.)',
      multi: false,
      single: true,
      response: 'Packed Powerbanks.',
      responsefalse: 'Did not pack Powerbanks',
      ans: [],
      adLabel: 'None',
    },
        {
      id: 'ToiletriesMotionSicknessMedicine',
      text: 'Have you packed basic emergency kit and required/critical medicines (such as Epinephrine/Adrenaline auto-injectors, Asthma inhalers, Motion sickness medication etc)?',
      multi: false,
      single: true,
      response: 'Medicines and emergency kit packed.',
      responsefalse: 'Medicines and emergency kit not yet packed.',
      ans: [],
      adLabel: 'None',
    },
        {
      id: 'ToiletriesVisa',
      text: 'Do you have the legal permissions required to enter and have it on you (Visas, eVisas, Residence permits, Identity Cards, Border office permissions, etc.)?',
      multi: false,
      single: true,
      response: 'Have the legal permission to enter(s) (Visas, eVisas, Residence permits, Identity Cards, Border office permissions, etc.).',
      responsefalse: 'Don\'t have the legal permission to enter(s) (Visas, eVisas, Residence permits, Identity Cards, Border office permissions, etc.).',
      ans: [],
      adLabel: 'None',
    },
        {
      id: 'ToiletriesTravelMoney',
      text: 'Have you kept your travel money ready? (including foreign currencies, travel money cards etc.)',
      multi: false,
      single: true,
      response: 'Travel money ready.',
      responsefalse: 'Travel money not yet ready.',
      ans: [],
      adLabel: 'None',
    },	 	
        {
      id: 'ToiletriesTravelInsurance',
      text: 'Have you bought/kept your travel insurance (including Medical cover)? Please note that travel insurance is mandatory to enter majority of the countries.',
      multi: false,
      single: true,
      response: 'Travel insurance ready.',
      responsefalse: 'Travel insurance not yet ready.',
      ans: [],
      adLabel: 'None',
    },
        {
      id: 'ToiletriesBankDocuments',
      text: 'If you are travelling to a diffrent country, do you have the necessary bank/financial documents (such as statements) to show you can financially sustain during your trip?',
      multi: false,
      single: true,
      response: 'Have the necessary(s) financial documents.',
      responsefalse: 'Don\'t have the necessary(s) financial documents.',
      ans: [],
      adLabel: 'None',
    },
    {
      id: 'ToiletriesToothbrush',
      text: 'Packed toothbrush?',
      multi: false,
      single: true,
      ans: [],
      response: 'Toothbrush packed.',
      responsefalse: 'Toothbrush not yet packed.',
      additionaldata: '',
      adLabel: 'None',
    },
    {
      id: 'ToiletriesToothpaste',
      text: 'Packed toothpaste?',
      multi: false,
      single: true,
      ans: [],
      response: 'Toothpaste packed.',
      responsefalse: 'Toothpaste not yet packed.',
      additionaldata: '',
      adLabel: 'None',
    },
    {
      id: 'ToiletriesShampoo',
      text: 'Packed shampoo?',
      multi: false,
      single: true,
      ans: [],
      response: 'Shampoo packed.',
      responsefalse: 'Shampoo not yet packed.',
      additionaldata: '',
      adLabel: 'None',
    },
    {
      id: 'ToiletriesConditioner',
      text: 'Packed conditioner?',
      multi: false,
      single: true,
      ans: [],
      response: 'Conditioner packed.',
      responsefalse: 'Conditioner not yet packed.',
      additionaldata: '',
      adLabel: 'None',
    },
    {
      id: 'ClothingUndergarments',
      text: 'Packed a fresh set of undergarments?',
      multi: false,
      single: true,
      ans: [],
      response: 'Fresh set of undergarments packed.',
      responsefalse: 'Fresh set of undergarments not yet packed.',
      additionaldata: '',
      adLabel: 'None',
    },
    {
      id: 'ClothingSocks',
      text: 'Packed socks?',
      multi: false,
      single: true,
      ans: [],
      response: 'Socks packed.',
      responsefalse: 'Socks not yet packed.',
      additionaldata: '',
      adLabel: 'None',
    },
    {
      id: 'ClothingTops',
      text: 'Packed tops/shirts?',
      multi: false,
      single: true,
      ans: [],
      response: 'Tops/shirts packed.',
      responsefalse: 'Tops/shirts not yet packed.',
      additionaldata: '',
      adLabel: 'None',
    },
    {
      id: 'ClothingBottoms',
      text: 'Packed bottoms/pants?',
      multi: false,
      single: true,
      ans: [],
      response: 'Bottoms/pants packed.',
      responsefalse: 'Bottoms/pants not yet packed.',
      additionaldata: '',
      adLabel: 'None',
    },
    {
      id: 'ToiletriesSunscreen',
      text: 'Packed sunscreen?',
      multi: false,
      single: true,
      ans: [],
      response: 'Sunscreen packed.',
      responsefalse: 'Sunscreen not yet packed.',
      additionaldata: '',
      adLabel: 'None',
    },
    {
      id: 'ToiletriesMedications',
      text: 'Packed necessary medications?',
      multi: false,
      single: true,
      ans: [],
      response: 'Medications packed.',
      responsefalse: 'Medications not yet packed.',
      additionaldata: '',
      adLabel: 'None',
    },

      {
        id: 'ToiletriesMakeup',
        text: 'Packed makeup?',
        multi: false,
        single: true,
        ans: [],
        response: 'Makeup packed.',
        responsefalse: 'Makeup not yet packed.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'AccessoriesBelts',
        text: 'Packed belts?',
        multi: false,
        single: true,
        ans: [],
        response: 'Belts packed.',
        responsefalse: 'Belts not yet packed.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'AccessoriesCaps',
        text: 'Packed caps?',
        multi: false,
        single: true,
        ans: [],
        response: 'Caps packed.',
        responsefalse: 'Caps not yet packed.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'ClothingSweatersJackets',
        text: 'Packed sweaters/jackets (if cold)?',
        multi: false,
        single: true,
        ans: [],
        response: 'Sweaters/jackets packed.',
        responsefalse: 'Sweaters/jackets not yet packed.',
        additionaldata: '',
        adLabel: 'None',
      },
      {
        id: 'EmergencyFood',
        text: 'Packed emergency food?',
        multi: false,
        single: true,
        ans: [],
        response: 'Emergency food packed.',
        responsefalse: 'Emergency food not yet packed.',
        additionaldata: '',
        adLabel: 'None',
      },
 
    
  ]
  
  useEffect(() => {
    // Run the code when the component mounts or reloads
    let klp = [];
    let klp_pre = ['Clothing', 'Toiletries', 'Accessories', 'Emergency']
    klp_pre.map(hj=> findActiveQuestionsByPrefix(questions, hj).map((obj) => klp.push(obj)));
    console.log(klp);
    setActiveQuestions(klp);
  }, []); 
  // setActiveQuestions(klp);

  const renderQuestion = (question, index) => {
    const { id, text, multi, ans, single } = question;
    const answer = answers[id];

    if (!activeQuestions.includes(id)) {
      console.log(id);
      return null; // Skip rendering if the question is not in activeQuestions
    }

        return (
      <div key={id}>
        <h3>{text}</h3>
        {multi?
         single?
        <form>
          {ans.map(val=>
          <label key={val}>
            <input
              type="radio"
              name={id}
              value={val}
              checked={answer.includes(val)}
              // checked={answer === 'yes'}
              onChange={(e) => (handleAnswerChange(id, e.target.checked),handleNextQuestion)}
            />
            {val}
          </label>)
          }
        </form>
        :
        <form>
        {ans.map(val=>
        <label key={val}>
          <input
            type="checkbox"
            // name={id}
            value={val}
            checked={answer.includes(val)}
            // checked={answer === 'yes'}
            onChange={(e) => handleAnswerChange(id, e.target.value, 'multi', e.target.checked)}
          />
          {val}
        </label>)}
        {/* <input type="checkbox" value='House' checked={true} hidden={true} onLoad={(e) => handleAnswerChange(id, e.target.value, 'multi', e.target.checked)}/> */}
      </form>
        :
        <div>
          <form>
          <label>
            <input
              type="radio"
              name={id}
              value="yes"
              checked={answer === 'yes'}
              onChange={(e) => (handleAnswerChange(id, 'yes'), handleNextQuestion())}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name={id}
              value="no"
              checked={answer === 'no'}
              onChange={(e) => (handleAnswerChange(id, 'no'), handleNextQuestion())}
            />
            No
          </label>
          <label>
            <input
              type="radio"
              name={id}
              value="n/a"
              checked={answer === 'n/a'}
              onChange={(e) => (handleAnswerChange(id, 'n/a'), handleNextQuestion())}
            />
            N/A
          </label>
          </form>
        </div>
        
        }
      </div>
    );
  };

  // return (
  //   <div className="checklist-container">
  //     {showQuestion && renderQuestion(questions[currentQuestion], currentQuestion)}
  //     <button disabled={currentQuestion === 0} onClick={handlePreviousQuestion}>
  //       Back
  //     </button>
  //     {currentQuestion < activeQuestions.length - 1 ? (
  //       <button onClick={handleNextQuestion}>Next</button>
  //     ) : (
  //       <button>Submit</button>
  //     )}
  //   </div>
  // );
  const yht = {
    background: 'bkg.webp'
  }
  const [backgroundImage, setBackgroundImage] = React.useState('./ijk.webp');
  const rft = `url('/Users/neerajkavanchakshu/My Drive/R&D/TravelAI/Website/frontend/src/ijk.webp')`
  const introductoryMessage = 'Scientific checklist to help you pack your suitcase before leaving for holidays.'
  const introductoryTitle = ' Packing gist-a-list'
  const introductoryWarning =  'By clicking on Start, you confirm that you have read and agreed to our Terms and Conditions, as well as our disclaimers.'
const PageContainer = styled.div`
  background-image: url(${props => props.backgroundImage});
  /* Additional styles for the page container */
`;
  if (complete){
    return(
      <div className="checklist-container">
      <Helmet bodyAttributes={{style: `background-color: ${rft}`}}/>
      <h1>Hooray!</h1>
      <br></br>
      Gist-a-list Complete! Enjoy your trip!

      <ButtonSelect2 onClick={generatePDF}>Download PDF</ButtonSelect2>
      <ButtonSelect2 onClick={returnHomepage}>Use other gist-a-lists</ButtonSelect2>
      </div>
        

    )
  }

  return (
<div>
<Helmet bodyAttributes={{style: `background-image : url('bkg.webp')`}}/>
   {/* <Helmet>
             <style>
                {`
                   html, body {
                       height: 100%;
                  }

                 body {
                    background-image: url("bkg.webp");
                    background-size: 80% 100%, 101% 100%;
                    background-repeat: no-repeat;
                    background-position: 1% 60%, 80% 110%;
                  }
                `}
            </style>
         </Helmet> */}
         
    <div className="checklist-container">
      {showWaiting &&
    <div class="lds-ripple"><div></div><div></div></div>
      }

{introductory && <h3>{introductoryTitle}</h3>}
      {introductory && <div><h5>{introductoryMessage}</h5><br></br><br></br></div>}
      {introductory && <ButtonSelect onClick={handleIntroductory}>Start</ButtonSelect>}

      {introductory && <p className='fineprint'>{introductoryWarning}</p>}

      {showQuestion && renderQuestion(questions[currentQuestion], currentQuestion)}


      { showQuestion &&
      (((activeQuestioncount < activeQuestions.length - 1) && questions[currentQuestion].multi) || completedQuestions.current.includes(currentQuestion)) ? (
        <ButtonSelect onClick={handleNextQuestion}>Next</ButtonSelect>
      ) :  showQuestion && questions[currentQuestion].multi? (
        <ButtonSelect onClick={handleNextQuestion}>
          {(activeQuestioncount < activeQuestions.length - 1) ? 'Next' : 'Submit'}
        </ButtonSelect>
        
      ):null}
            {
      showQuestion && currentQuestion>0?
      <ButtonSelect disabled={currentQuestion === 0} onClick={handlePreviousQuestion}>
        Back
      </ButtonSelect>:null
      }
    </div>
    </div>
  );
 

};

export default ChecklistPagePacking;



