import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './LoadingPortal.css'; // import the CSS file for styling

function LoadingPortal({ isLoading, children }) {
  const [portalNode] = useState(document.createElement('div')); // create a new div for the portal
  const loadingAnimation = isLoading ? (
    <div className="loading-animation">
      <div className="loading-spinner"></div>
    </div>
  ) : null;

  // render the portal outside of the main document flow
  return ReactDOM.createPortal(
    <div className="loading-portal">
      {loadingAnimation}
      {children}
    </div>,
    portalNode
  );
}

export default LoadingPortal;
