import { MapContainer, TileLayer, Marker, Popup, Routing, Polyline, Rectangle} from 'react-leaflet';
import { useMap } from 'react-leaflet/hooks';
import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';  
import { useAnimation, motion } from "framer-motion";
import {loadHistory, setHistory} from "./service/itihasa";
import SimpleImageSlider from "react-simple-image-slider";
import cool from './cool.jpg';
import ritzy from './ritzy-2.jpg';
import mediumroom from './mediumroom.jpg';
import Alert from '@mui/material/Alert';
import styled from 'styled-components';
import AWS from 'aws-sdk';
import L from 'leaflet';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo_pdf from './josh_logo_dup.png';
import { useInView } from 'react-intersection-observer';
import LoadingPortal from './loadingportal.js';  
import { getToken, getUserName, getRevolvingID, setUserSession} from "./service/AuthService";

import { fontSize } from '@mui/system';
import { ImageList } from '@mui/material';

const resURL = 'http://127.0.0.1:8000/destination'
var greenIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

var redIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

function CenterLeafletMapOnMarker(map,marker) {
  var latLngs = [ marker.getLatLng() ];
  var markerBounds = L.latLngBounds(latLngs);
  map.fitBounds(markerBounds, {maxZoom:13});
}

const squareVariants = {
  visible: { opacity: 1, scale: 1.2, transition: { duration: 1 } },
  partvisible: { opacity: 0.5, scale: 1.2, transition: { duration: 1 } },
  hidden: { opacity: 0.5, scale: 0.5 }
};

async function RetrieveImage(locations) {
  //   const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);
  //       setIsLoading(true);
  //     setError(null);
  //console.log('heheheheheheheh')
  let ImageList = {}
  // Configure the AWS SDK with the provided access key and secret key
  // AWS.config.update({
  //   accessKeyId: process.env.AS_KEY,
  //   secretAccessKey: process.env.AS_SEC,
  //   region:'eu-west-2'
  // });
  AWS.config.update({

      accessKeyId: process.env.REACT_APP_MY_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_MY_SECRET_ACCESS_KEY,

    region: 'eu-west-2'
  });
  console.log(process.env);
  console.log('Access key ID:', process.env.REACT_APP_MY_ACCESS_KEY_ID);
console.log('Secret access key:', process.env.REACT_APP_MY_SECRET_ACCESS_KEY);

  // Create an S3 client
  const s3 = new AWS.S3();
  
  // Try to retrieve the image from the S3 bucket
  console.log('dfdfdfcrow')
  //console.log(locations)
  if (locations !== undefined) {
    try {
      const promises = locations.map(async (location,index) => {
        let gh = [];
        //console.log(index);
        //console.log(location);
        await Promise.all(location.map(async (fileName) => {
          const params = {
            Bucket: 'images-fivsix',
            Key: 'images_wiki/' + decodeURIComponent(fileName.slice(5)).replace(/%/g, '+')
          };
          const data = await s3.getObject(params).promise();
          // Convert the image data to a data URL
          const imageUrl = URL.createObjectURL(new Blob([data.Body]));
          // console.log(imageUrl)
          gh.push(imageUrl);
          //console.log('dsfdf')
        }));
        //console.log(gh)
        // if (gh.length === 0) {
        //   gh = ['cool', 'cool', 'cool'];
        // }
        ImageList[index] = gh;
        //console.log(ImageList)
      });
      await Promise.all(promises);
      // sessionStorage.setItem('images', ImageList)
    } catch (error) {
      console.error()
      console.error(error);
    }
  }
  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  return ImageList;
}

// async function retrieveImage(locations) {
//   const ImageList = []
//   // Configure the AWS SDK with the provided access key and secret key
//   AWS.config.update({
//     accessKeyId: 'AKIA5BVXAPCFWHBVC3YR',
//     secretAccessKey: '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'
//   });

//   // Create an S3 client
//   const s3 = new AWS.S3();

//   // Try to retrieve the image from the S3 bucket
//   console.log('dfdfdfcrow')
//   console.log(locations)
//   if (locations !== undefined) {
//     try {
//       const promises = locations.map(async (location) => {
//         const gh = [];
//         console.log('dfdfdf');
//         await Promise.all(location.map(async (fileName) => {
//           const params = {
//             Bucket: 'images-fivsix',
//             Key: 'images_wiki/' + decodeURIComponent(fileName.slice(5))
//           };
//           const data = await s3.getObject(params).promise();
//           // Convert the image data to a data URL
//           const imageUrl = URL.createObjectURL(new Blob([data.Body]));
//           console.log(imageUrl)
//           gh.push(imageUrl);
//         }));
//         console.log(gh)
//         ImageList.push(gh);
//       });
//       await Promise.all(promises);
//     } catch (error) {
//       console.error(error);
//     }
//   }
//   return ImageList;
// }

 
// async function retrieveImage(locations) {
//   const ImageList = []
//   // Configure the AWS SDK with the provided access key and secret key
//   AWS.config.update({
//     accessKeyId: '',
//     secretAccessKey: ''
//   });

//   // Create an S3 client
//   const s3 = new AWS.S3();

//   // Try to retrieve the image from the S3 bucket
//     console.log('dfdfdfcrow')
//     console.log(locations)
//     if (locations !== undefined) {
    
//     locations.map((location)=>{
//     const gh = []
//     console.log('dfdfdf')
//     location.map(async (fileName)=>{
//       const params = {
//         Bucket: 'images-fivsix',

//         Key: 'images_wiki/' + decodeURIComponent(fileName.slice(5))
//       };
//       const data = await s3.getObject(params).promise();

      
//       // Convert the image data to a data URL
//       const imageUrl = URL.createObjectURL(new Blob([data.Body]));
//       console.log(imageUrl)
//       // const imageUrl = data.Body.toString('binary');
//       gh.push(imageUrl)
//     })
//     console.log(gh)
//     ImageList.push(gh)
//   });
    // const params = {
    //   Bucket: 'images-fivsix',

    //   Key: 'images_wiki/' + decodeURIComponent(fileName.slice(5))
    // };
    // const data = await s3.getObject(params).promise();
    // console.log('bhn')
    
    // // Convert the image data to a data URL
    // const imageUrl = URL.createObjectURL(new Blob([data.Body]));
    // console.log(imageUrl)
    // // const imageUrl = data.Body.toString('binary');
    // setImg(imageUrl);

// };
// return(ImageList)

// }
const toTitleCase = (s)=>{
  if (typeof(s)==='string'&&s.length>0) {
    const words = s.split(' ')
    if (Array.isArray(words)&&words.length>0) {
      if (words.length===1) {
        const word = words[0]
        const matches = word.charAt(0).match(/\w+/i)
        const lines = word.split('\n')
        if (Array.isArray(lines)&&lines.length>1) {
          return lines.map(line=>{
            return toTitleCase(line)
          }).join('\n')
        } else if (Array.isArray(matches)) {
          return word.split('').map((c,i)=>{
            if (i===0) {
              return c.toUpperCase()
            }
            return c.toLowerCase()
          }).join('')
        } else {
          return word.charAt(0).concat(toTitleCase(word.slice(1)))
        }
      } else {
        return words.map(word=>toTitleCase(word)).join(' ')
      }
    }
  }
  return ''
}

const ButtonSelect = styled.button({
  color: 'grey',
  background: 'white',
  width: "36%",
  height: "110%",
  'border-radius': '0.2em',
  border: '1px solid purple',
  color:'purple',
  'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
  'font-size': '100%',
  margin: '0 2em 0 2em',
  '&:hover':{background: 'gold'},
  '&:hover':{transform: 'translateY(2px)'},
  '&:selection':{background: 'gold'}, 
  '&:active':{transform: 'translateY(4px)'},
  'box-shadow': '0.18em 0.22em black'
});



const Results = () => {

  var places_name = [];
  // const [places_lat, setLat] =  useState('');
  // const [places_lng, setLng] = useState('');
  var places_lat = [];
  var places_lng = [];
  var imageFiles = []
  const [isLoading, setLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [error, setError] = useState(null);

  const [act, setAct] = useState([]);
  const [activeMarker, setActmarker] = useState(0);
  const [saved, setSaved] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [refreshMap, setRefreshMap] = useState(false);
  const [currentDay, setCurrentday] = useState(0);
  const [nextdaybuttonVisible, setNextdaybutton] = useState(true);
  const [prevdaybuttonVisible, setPrevdaybutton] = useState(false);
  const [map, setMap] = useState();
  const token = getToken();
  const [img, setImg] = useState(null);
  const [imgList, setImglist] = useState([])
  const container = useRef(null)
  const ref = useRef(null)
  const isInView = useInView({ root: container })
  const controls = useAnimation();
  const [usable, setUsable] = useState(null);
  const itinerary = JSON.parse(sessionStorage.getItem('itinerary'));
  
  
  // useEffect(() => {

  //   async function getImageList() {
  //     setIsLoading2(true);
  //     setError(null);
  //     const result = await RetrieveImage(itinerary[currentDay].Images);
  //     setImglist(result);
  //     setIsLoading2(true);
  //   }
  //   getImageList();
  // }, []);

  async function getImageList(index) {
        // setIsLoading2(true);
        // setError(null);
        const result = await RetrieveImage(itinerary[currentDay].Images);
        setImglist(result);
        // setIsLoading2(true);
      }

  async function GeneratePDF() {
    // Retrieve the array of JSON objects from session storage
   
    const items = JSON.parse(sessionStorage.getItem('itinerary'));
    // const selectedHotel = JSON.parse(sessionStorage.getItem('selHotel'));
    // const selectedFlight = JSON.parse(sessionStorage.getItem('selFlight'));
    // Create a new PDF document
    const doc = new jsPDF();
  
    // Define the columns for the table
    const columns = ['Time','Name', 'Location'].map((key) => ({ header: key, dataKey: key }));
    var footerText = 'Time of itinerary generation:'+ new Date() +'.\n The itinerary generated by Fivsix is provided for informational purposes only and is not intended to be relied upon as a definitive travel plan. Fivsix is a planning tool and not a travel agent.\n As such, Fivsix and its related entities, including NKCHAKSHU UK LTD, are not responsible for any results or consequences arising from the use of the suggested itinerary/itineraries, including but not limited to disruptions and cancellations.\n The user assumes all responsibility for any actions taken based on the suggested itinerary/itineraries generated by Fivsix. Fivsix does not guarantee the accuracy, completeness, or reliability of the suggested itinerary/itineraries and does not provide any warranty or guarantee, express or implied, regarding the information provided.\n Fivsix recommends that users verify all information contained in the suggested itinerary/itineraries, including but not limited to flight schedules, hotel reservations, and transportation arrangements, with the respective service providers before making any travel arrangements.\n By using the Fivsix website and the suggested itinerary/itineraries, the user agrees to indemnify and hold Fivsix and its related entities harmless from any and all claims, damages, losses, or expenses, including attorneys’ fees, arising from the use of the suggested itinerary/itineraries.';
  
    var fontSize = 10;
    var lineHeight = fontSize * 1.0;
    
    // define the function that adds the footer content to each page
    var addFooter = function() {
      var pageCount = doc.internal.getNumberOfPages();
      for(var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        var footerX = doc.internal.pageSize.width / 2;
        var footerY = doc.internal.pageSize.height - (lineHeight * 2);
        doc.setFontSize(4);
        doc.text(footerText, footerX, footerY, 'center');
      }
    };
    // Define the data for the table
    
    var count = 0
    doc.addImage(logo_pdf, 'JPEG', 74, 7, 57, 60, '','FAST',0)
    doc.setFontSize(10)
    doc.text('Suggested trip itinerary', 81, 70)
    var init = 88
    var init2 = 78

    items.map((item, dayindex) => {
      var data = []
      if ((init+count + (item.Time.length*13))>290){
        doc.addPage()
        count=0
        init=30
        init2=20
      }
      item.Name.map((ite, index) => 
      
      data.push([item.Time[index], ite,"https://www.google.com/maps/place/"+item.Coordinates[index][0]+','+item.Coordinates[index][1]]))
        // 'https://www.google.com/maps/place/'+item.Coordinates[index][0]+','+item.Coordinates[index][1]});
      doc.setFontSize(11).setFont(undefined, 'bold');
      const fvn = dayindex+1
      doc.text('Day '+fvn, 95, init2+count)
      //console.log(count)
      doc.autoTable({
        head:[['Time','Name', 'Location']],
        body: data,
        startY:init+count
      });
      if (item.Time.length>6){
      count = count + (item.Time.length*14)
      }
      else{
        count = count + (item.Time.length*15)
      }
      if (count>297){
        console.log('fefe')
        count = count%297
        init = 0
      }
    });
    //console.log(count)
    if (count>297){
      //console.log('fefe')
      count = count%297
      init = 0
    }
    console.log('antharyami')
    if (itinerary.length>1){
    if (JSON.parse(sessionStorage.getItem('selHotel'))==="undefined"){ setLoading(true)}
    if (JSON.parse(sessionStorage.getItem('selHotel'))!==null&&JSON.parse(sessionStorage.getItem('selHotel'))!==undefined&&JSON.parse(sessionStorage.getItem('selHotel'))!=='undefined'&&JSON.parse(sessionStorage.getItem('selHotel'))!=="null"){
      const selectedHotel = JSON.parse(sessionStorage.getItem('selHotel'))
    doc.addPage()
    count=0
    doc.setFontSize(12).setFont(undefined, 'bold');
    doc.text('Stay details', 15, count+20)
    doc.setFontSize(12).setFont(undefined, 'normal');
    count=count+10
        // Set the column widths
        const col1Width = 30;
        const col2Width = 80;
    
        // Define the row heights and spacing
        const rowHeight = 10;
        const spacing = 5;
    
        // Define the content for column 1 and column 2
        const column1 = ['Name:', 'Location:', 'Cost:', 'Beds:', 'Type:'];
        const column2 = [selectedHotel.Name, "https://www.google.com/maps/place/"+selectedHotel.Coordinates[0]+','+selectedHotel.Coordinates[1], JSON.stringify(selectedHotel.Price)+' '+selectedHotel.Currency, JSON.stringify(selectedHotel.No_beds), selectedHotel.Room_type];

        const tableWidth = col1Width + col2Width + spacing * 2;
        const tableHeight = (column1.length + 1) * (rowHeight + spacing);

        // Loop through the rows and add them to the table
        let y = count+20;
        for (let i = 0; i < column1.length; i++) {
          if (y>270){
            doc.addPage()
            y=20
          }
          doc.setFontSize(12).setFont(undefined, 'bold');
          doc.text(column1[i], 15, y);
          doc.setFontSize(12).setFont(undefined, 'normal');
          doc.text(column2[i], col1Width + spacing, y);
          y += rowHeight + spacing;
          // if (y>297){
          //   console.log('fefe')
          //   count = y%297
          //   init = 0
          // }
        }
        doc.rect(10, 10, tableWidth + 10, tableHeight + 20);
    }
  }
    

    doc.setFontSize(5).setFont(undefined, 'normal');
    doc.autoTable({
      startY: 650,
      // html: '#table',
      didDrawPage: addFooter
    });
   
    // doc.text('The itinerary generated by Fivsix is only suggestive in nature. Fivsix is only a planning tool and not a travel agent.', 40, 800)
    // doc.text('Fivsix (or its related entities, including NKCHAKSHU UK LTD) are not liable for any results, consequence(s) arising as a result of actions taken using suggested itinerary/itineraries, including but not limited to disruptions and cancellations.', 10, 803)
  
    
    // Add the table to the PDF document
  
    let isFileSaved = false;
    // Download the PDF document when the button is clicked
    doc.save('itinerary.pdf', () => {
      isFileSaved = true;
    });
    // while(!isFileSaved){
    //   setLoading(true)
    // }
    setLoading(false)
    return null
  }


  React.useEffect(() => {
    const X = require("leaflet");

    delete X.Icon.Default.prototype._getIconUrl;

    X.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
  }, []);



  useEffect(() => {
    if (currentDay === 0){
      getImageList();
      setPrevdaybutton(false)
    }
    else{
      setPrevdaybutton(true)
    }
    if (currentDay == itinerary.length-1){
      setNextdaybutton(false)
    }
    else{
      setNextdaybutton(true)
    }
  }, []);
  //   var places_l1 = []
  //   var places_l2 = []
  //   var imagesLocal = []
    
  // const requestConfig = {
  //   headers: {
  //   }
  // }



  // const requestBody = {
  //   destination: "London",
  //   start_point: "string",
  //   budget: 0
  // }
  // // const resp = axios.post(resURL,requestBody).then(response=>{
  // //   response.data.Location.map((Location, index) =>(

  // //   places_l1.push(response.data.Location[index][0]),
  // //   places_l2.push(response.data.Location[index][1]),
  // //   places_name.push(response.data.Name[index]),
  // //   imagesLocal.push(response.data.Images[index][0])))
  // //   // retrieveImage(response.data.Images[index][0])))
  // //   console.log('test in');
  // //   console.log(places_l1);
  // //   console.log(places_l2);
  // //   sessionStorage.setItem('l1', JSON.stringify(places_l1));
  // //   sessionStorage.setItem('l2', JSON.stringify(places_l2));
  // //   sessionStorage.setItem('poi', JSON.stringify(places_name));
  // //   sessionStorage.setItem('images',JSON.stringify(imagesLocal));
  // //   imgList.current = Array(places_l1.length);
  // //   setLoading(false)
  // // });




 

  // useEffect(() => {
  //   places_lat.push(sessionStorage.getItem('l1'));
  //   places_lng.push(sessionStorage.getItem('l2'));
  //   console.log('dff')
  //   console.log(places_lat)
  //   })


    // places_lat = JSON.parse(sessionStorage.getItem('l1'));
    // places_lng = JSON.parse(sessionStorage.getItem('l2'));
    // places_name = JSON.parse(sessionStorage.getItem('poi'));
    // imageFiles = JSON.parse(sessionStorage.getItem('images'));
    const saveHandler = (event)=>{
      const username = getUserName();
      const localToken = getToken();
      const revolvingID = getRevolvingID();
      setHistory(username, revolvingID,localToken, JSON.parse(sessionStorage.getItem('itineraryStorage')));
      setSaved(true);
    }


    const prevHandler = (event)=>{
      if (currentDay > 0){
        setCurrentday(currentDay-1)
        if (currentDay-1<=0){
          setNextdaybutton(true)
          setPrevdaybutton(false)
        }
        else{
          setNextdaybutton(true)
        }


    }
    setAct([]);
    setMarkers([]);
    setRefreshMap(!refreshMap);
    window.scrollTo(0, 0);

  }



    const dayHandler = (event)=>{
      event.preventDefault();
      if (currentDay <= itinerary.length-1){

      //console.log(currentDay)
      setCurrentday(currentDay+1)
      //console.log(currentDay)
      if (currentDay>0 || currentDay+1>0){
        setPrevdaybutton(true)
      }
    }
   
    if(currentDay+1 >= itinerary.length-1){
        setNextdaybutton(false);
        if (currentDay>0){
          setPrevdaybutton(true)
        }
      }
      setAct([]);
      setMarkers([]);
      setRefreshMap(!refreshMap);
      window.scrollTo(0, 0);
      // imgList.current= retrieveImage(itinerary[currentDay].Images);
      }
      function MyComponent(props) {
        const map = useMap();
        setMap(map);
        var L = require('leaflet');
        //console.log(props.refresh)
  
          itinerary[currentDay].Coordinates.map((place, index) => (
            markers.push(new L.Marker([place[0], place[1]])),
            act.push(false)
          ));
          // console.log('test in');
          // console.log(places_lat);
          // console.log(places_name);
          // console.log(markers);
          itinerary[currentDay].Name.map((place, index) => (
            // console.log('rerer'),
            // console.log(markers[index]),
            markers[index].title = index,
            markers[index].bindPopup(place).addTo(map),
            markers[index].on('click', function(e) {
            markers[index].setIcon(greenIcon);
            CenterLeafletMapOnMarker(map, markers[index]);
            }).addTo(map)
            ));

      
    
      
        return null
      }
      if (isLoading2) {
        return <div>Loading...</div>;
      }
    
      if (error) {
        return <div>Error: {error}</div>;
      }

  return (
    
    <div className='map-outer-cover' ref={container}>
      
      <div className='home2-logo-results'>
      <span class='heading-h1-results'> Ta da! Here's your itinerary...</span><span className='home2-outer'> <ButtonSelect onClick={GeneratePDF}>{isLoading? "Generating PDF...":"Download PDF"}</ButtonSelect>{ token && (saved?<ButtonSelect disabled>Itinerary Saved!</ButtonSelect>:<ButtonSelect onClick={saveHandler}>Save Itinerary</ButtonSelect>)}</span>
      </div>
    <div className='map-child'>
      
    <MapContainer key={refreshMap ? "refreshed" : "not-refreshed"} center={[51.505, -0.09]} zoom={4} style={{ height: '81vh', width: '81vh' }} className='map-container'>
  <TileLayer
    attribution='&copy;Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | <a href="https://openmaptiles.org/" rel="nofollow" target="_blank">© OpenMapTiles</a> <a href="https://www.openstreetmap.org/copyright" rel="nofollow" target="_blank">© OpenStreetMap</a> contributors'
    url='https://maps.geoapify.com/v1/tile/maptiler-3d/{z}/{x}/{y}.png?apiKey=f25398151728411991ee08b1184d003c'
  />
  {/* <RoutingMachine/> */}
  { true &&
  <MyComponent refresh={refreshMap? "refreshed" : "not-refreshed"}/>
  }
</MapContainer>
  
</div>
      
<div className='map-child-list' >
<div className='heading-day-number'>
      <span class='heading-h1'> Day {currentDay+1}</span>
      <br></br>   
      </div>
  <div className='timeline'>
<div className='list-test'>
          {/* {console.log(itinerary[currentDay])} */}
          
          {itinerary[currentDay].Coordinates.map((place, index) => (
              
              <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              whileInView="visible"
              viewport={{amount:0.6}}
              onAnimationComplete={() => {
                  
                  markers[activeMarker].setIcon(greenIcon);
                  markers[index].openPopup();
                  CenterLeafletMapOnMarker(map, markers[index]);
                  markers[index].setIcon(redIcon);
                  CenterLeafletMapOnMarker(map, markers[index]);
                  setActmarker(index);
                }}
              // onViewportEnter="partvisible"
              // onViewportLeave="partvisible"
              variants={squareVariants}
              
            >
          <ul
          // >
          //   <input type='radio'
          //   name='reslis' id={index} value={index}
          tabindex="-1"
          className="layout"
          // style={(inView?'opacity:10%':'opacity:100%')}
            onClick={() => {
              markers[activeMarker].setIcon(greenIcon)
              markers[index].openPopup();
              CenterLeafletMapOnMarker(map, markers[index]);
              markers[index].setIcon(redIcon);
              setActmarker(index);
              
            }}>
            
            {/* {console.log('hdiuhi')} */}
            
            
            {/* {imgList[index] ? <img src={imgList[index]} alt="" /> : img? <img src={img} alt="" /> :<p>Loading image...</p> }
            {imgList[index]? null: imgList.current[index]=img} */}
            <div class='notes'>
            <span>{toTitleCase(itinerary[currentDay].Name[index])}</span>
            </div>
            <div class='time'>{itinerary[currentDay].Time[index]}</div>
          <div class='details'>"{itinerary[currentDay].Description[index]}"</div>
          <div class='opc'>
          <SimpleImageSlider
        width={"100%"}
        height={"35vh"}
        style={{marginLeft:'0px', zIndex:'2000', border:'0px', left:'0px', opacity:'1.0', background:'transparent'}}
        navMargin={30}
        
        images={imgList[index]===[]||imgList[index]===undefined?[cool, cool, cool]:imgList[index]}
        // images={itinerary[currentDay].Images[index]===[]?[cool, cool, cool]:itinerary[currentDay].Images[index]}
        showBullets={false}
        showNavs={true}
      />
</div>
          </ul>

           </motion.div>
          ))}
  
          
          
</div>
</div>
<div class='daybuttons'>
{ prevdaybuttonVisible &&
           <ButtonSelect onClick={prevHandler}>Previous day</ButtonSelect> 
          }
          <br></br>
          { nextdaybuttonVisible &&
           <ButtonSelect onClick={dayHandler}>Next day</ButtonSelect> 
          }



</div>
<div className='daybuttons'>
<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=48&l=ur1&category=piv&banner=0E183RXMV88X6J8GJ002&f=ifr&linkID=9991e19a1180ad477ea634dba92591ae&t=nkchakshuuklt-21&tracking_id=nkchakshuuklt-21" width={728} height={90} scrolling="no" border={0} marginWidth={0} style={{border: 'none'}} frameBorder={0} sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation" />
</div>
</div>
{isLoading &&
<div>
      <LoadingPortal isLoading={isLoading}>
        <span>herreerer</span>
      </LoadingPortal>
    </div>

}

</div>


  );
  

};

export default Results;