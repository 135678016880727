import logo from './josh_logo_dup.png';
import { MapContainer, TileLayer, Marker, Popup, Routing, Polyline, Rectangle} from 'react-leaflet';
import { List, ListItemButton, ListItem } from "@mui/material";
import { useMap } from 'react-leaflet/hooks'
// import { map, tileLayer, Browser } from 'leaflet';
import React, {useEffect, useRef, useState} from 'react';
import { useAnimation, motion } from "framer-motion";
import render from 'react-router-dom';
// import MyMap from './service/myMap';
import L from 'leaflet';
import maplibre, { OpacityState } from 'maplibre-gl';
import { useInView } from 'react-intersection-observer';

// import Directions from 'openrouteservice-js';
import 'leaflet/dist/leaflet.css';

import { createControlComponent } from "@react-leaflet/core";


// const createRoutineMachineLayer = (props) => {
//   var L = require('leaflet');
//   require('leaflet-routing-machine'); // Adds L.Routing onto L
//   require('lrm-graphhopper');



//   const instance = L.Routing.control({
//     // router: new L.Routing.openrouteserviceV2(
//     // '{{5b3ce3597851110001cf62482a17798eddce445db748dd805ed22b6d}}',
//     // {
//     //         profile: "driving-car",
//     //         geometry_simplify: true // Needs to do _decodePolyline and smaller request
//     // }),

//     waypoints: [
//       L.latLng(52.52, 13.4050),
//       L.latLng(52.461065, 13.335211),
//       L.latLng(52.461065, 13.55211)
//     ],
//     routeWhileDragging:false,
//     addWaypoints:false,
//     show:false,
    

//   //   router: L.Routing.graphHopper(undefined /* no api key */, {
//   //     serviceUrl: 'http://localhost:8989/route'
//   // })
//   // router: L.Routing.graphHopper('2f27fb65-81a1-4820-b1f8-4d34d32f31ea')
//     // L.Routing.graphHopper('2f27fb65-81a1-4820-b1f8-4d34d32f31ea')
//   });

//   return instance;
// };
var greenIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

var redIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

// var reg_icon = new L.Icon.Default.mergeOptions({
//   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
//   iconUrl: require("leaflet/dist/images/marker-icon.png"),
//   shadowUrl: require("leaflet/dist/images/marker-shadow.png")
// });

function CenterLeafletMapOnMarker(map,marker) {
  var latLngs = [ marker.getLatLng() ];
  var markerBounds = L.latLngBounds(latLngs);
  map.fitBounds(markerBounds);
}

const squareVariants = {
  visible: { opacity: 1, scale: 1.2, transition: { duration: 1 } },
  partvisible: { opacity: 0.5, scale: 1.2, transition: { duration: 1 } },
  hidden: { opacity: 0.5, scale: 0.5 }
};

// const createPlaceRoutes = (props) => {
//   var L = require('leaflet');
//   var map = L.map('map')
//   var marker = new L.Marker([50.52, -0.4050]);

  
//   marker.bindPopup('hello there');
//   marker.on('click', function(e) {
//     marker.setIcon(greenIcon);
//     CenterLeafletMapOnMarker(marker);
//   })
//   return marker;
//   // marker.addTo(map);
// };
// const RoutingMachine = createControlComponent(createRoutineMachineLayer);
// const Routes = createControlComponent(createPlaceRoutes);


// function About() {
//   const mapIsReadyCallback = (map) => {
//     console.log(map);
//   };

//   return (
//       <div className="map-container">
//     <MyMap mapIsReadyCallback={mapIsReadyCallback}/>
//     </div>
//   );
// }
const marker_p = (lat, lng) =>{
<Marker position={[lat, lng]}>
<Popup>
  A pretty CSS3 popup. <br /> Easily customizable.
</Popup>
</Marker>
}





const About = () => {
  useEffect(() => {
    document.title = "FivSix: About us"
  }, []);

  // const [places, setPlaces] = useState([]);

  const places_name = ['A', 'B'];
  const places_lat = [52.51, 52.53, 12.9716, 52.51, 52.53, 12.9716];
  const places_lng = [13.4053, 13.4050, 77.5946, 13.4050, 13.4055, 77.5949];
  const [act, setAct] = useState([]);
  const [activeMarker, setActmarker] = useState(0);
  const [markers, setMarkers] = useState([]);
  const [map, setMap] = useState();
  const container = useRef(null)
  const ref = useRef(null)
  const isInView = useInView({ root: container })
  const controls = useAnimation();

  function clickAction(id, lat, lng) {
    map.setState({ marker: id, zoom: 16, center: [lat, lng] });
    this.markerRefs[id].leafletElement.openPopup()
  }
  function openPopUp(map, marker) {
    // console.log(id);
    marker.setIcon(greenIcon).addTo(map);
    CenterLeafletMapOnMarker(map, markers);
};

  function MyComponent() {
    const map = useMap();
    setMap(map);
    var L = require('leaflet');
    
    places_lat.map((place, index) => (
      markers.push(new L.Marker([place, places_lng[index]])),
      act.push(false)
    ));
    //console.log(markers);
    places_lat.map((place, index) => (
      //console.log('rerer'),
      //console.log(markers[index]),
      markers[index].title = index,
      markers[index].bindPopup('hello there').addTo(map),
      markers[index].on('click', function(e) {
      markers[index].setIcon(greenIcon);
      CenterLeafletMapOnMarker(map, markers[index]);
      }).addTo(map)
      ));
      // React.useEffect(() => {
      //   places_lat.map((place, index) => (
      //   markers[index].isPopupOpen()? markers[index].setIcon(redIcon):markers[index].setIcon(greenIcon)
      //   ));
      // }, []);
      
    return null
  }
  function ClickAction( id, lat, lng) {
    const map = useMap();
    // map.setState({ marker: id, zoom: 16, center: [lat, lng] });
    markers[id].openPopup();
  }
//   const select_div_handler = (event) =>{
//     markers[index].setIcon(greenIcon);
//       CenterLeafletMapOnMarker(map, markers[index]);
// .addTo(map);
//   }

    React.useEffect(() => {
        const X = require("leaflet");
    
        delete X.Icon.Default.prototype._getIconUrl;
    
        X.Icon.Default.mergeOptions({
          iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
          iconUrl: require("leaflet/dist/images/marker-icon.png"),
          shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        });
      }, []);





      // let orsDirections = new Directions({ api_key: "5b3ce3597851110001cf62482a17798eddce445db748dd805ed22b6d"});
   
      
    return(<div>
        <img class="main_image" src={logo}/>
        {/* <div className='home-logo'><span className="heading-h10">Fivsix</span></div> */}
        <div className='aboutus'>
        We are building AI-powered personalised itinerary planning solutions to assist in convenient, safe, intelligent and economical travel. Be it daily trips, holiday plans, business trips or even your evening walk, we want to add a little bit of that oomph, every time you step out.
  
        <br></br>
        Every time we go out or plan our trips, almost all of us have spent hours planning, calculating, quarrelling and deciding, and still end up being disappointed. We want to save you from that pain by helping you plan every step from your doorstep just the way you like.
        <br></br>
        <br></br>
        FivSix your journey!
        </div >
        {/* <div className='map-outer-cover' ref={container}>
    <div className='map-child'>
    <MapContainer center={[51.505, -0.09]} zoom={4} style={{ height: '81vh', width: '81vh' }} className='map-container'> */}
  {/* <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="http://18.130.195.1:8080/tile/{z}/{x}/{y}.png" /> */}
  {/* <TileLayer
    attribution='&copy;Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | <a href="https://openmaptiles.org/" rel="nofollow" target="_blank">© OpenMapTiles</a> <a href="https://www.openstreetmap.org/copyright" rel="nofollow" target="_blank">© OpenStreetMap</a> contributors'
    url='https://maps.geoapify.com/v1/tile/maptiler-3d/{z}/{x}/{y}.png?apiKey=f25398151728411991ee08b1184d003c'
  /> */}
  {/* <RoutingMachine/> */}
  {/* <MyComponent/> */}

  {/* {places_lat.map((place, index) => {
            return (
              <Marker
                position={[place, places_lng[index]]}
                ref={ref => markers[index] = ref}
              >
                <Popup> {index} </Popup>
              </Marker>
            );
          })} */}
  {/* <Routes/> */}
  {/* {places_lat.map((place, index) => (
  <Marker position={[place, places_lng[index]]} id={index}>
<Popup>
  A pretty CSS3 popup. <br /> Easily customizable.
</Popup>
</Marker>
))} */}
{/* </MapContainer>
</div> */}

{/* <div className='map-child'> */}
{/* {places_lat.map((place, index) => (
  <div className='textbox-places' onClick={openPopUp(document.getElementById('map'), document.getElementById(index))}>{places_name[index]}</div>
))} */}
{/* <div className='map-child-list' >
  <div className='timeline'>
<div className='list-test'  > */}
          {/* <List style={{ width: "20%", float: "left" }}>
            {places_lat.map((place, index) => (
                console.log(index),
                <ListItemButton

                  key={index}
         
                  onClick={() => {
                    markers[index].openPopup();
                    CenterLeafletMapOnMarker(map, markers[index]);
                  }}
                >
                  Id {index}
           
                </ListItemButton>
              
            ))}
          </List> */}
          {/* {places_lat.map((place, index) => (
              <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              whileInView="visible"
              // whileInView={() => {
              //   markers[activeMarker].setIcon(greenIcon)
              //   markers[index].openPopup();
              //   CenterLeafletMapOnMarker(map, markers[index]);
              //   markers[index].setIcon(redIcon);
              //   setActmarker(index);}}
              // viewport={{ margin: "10% 0 10% 0"}}
              viewport={{amount:0.8}}
              onAnimationComplete={() => {
                  markers[activeMarker].setIcon(greenIcon)
                  markers[index].openPopup();
                  CenterLeafletMapOnMarker(map, markers[index]);
                  markers[index].setIcon(redIcon);
                  CenterLeafletMapOnMarker(map, markers[index]);
                  setActmarker(index);
                }}
              onViewportEnter="partvisible"
              onViewportLeave="partvisible"
              variants={squareVariants}
              className="square"
            >
          <ul
          // >
          //   <input type='radio'
          //   name='reslis' id={index} value={index}
          tabindex="-1"
          // style={(inView?'opacity:10%':'opacity:100%')}
            onClick={() => {
              markers[activeMarker].setIcon(greenIcon)
              markers[index].openPopup();
              CenterLeafletMapOnMarker(map, markers[index]);
              markers[index].setIcon(redIcon);
              setActmarker(index);
            }}>
            <span>{index}</span>
            
          </ul>
           </motion.div>
          ))}
</div>
</div>
</div>
</div> */}
{/* </div> */}
    {/* <MyMap mapIsReadyCallback={mapIsReadyCallback} /> */}

{/* </div> */}
        </div>
        

    )
}

export default About;