import React, {useEffect, useState} from "react";
import styled from 'styled-components'
import logo from './josh_logo_dup.webp';
import {useNavigate} from 'react-router-dom';
// import { usePlacesWidget } from "react-google-autocomplete";
import Autocomplete from './service/Autocomplete';
import ReactGA from 'react-ga4';
import cities from './cities.txt';
import coordinates from './coordinates.txt';
import './index_iphone.css';
import './index_ipad.css';
import { getToken, getUserName, getRevolvingID, setUserSession} from "./service/AuthService";
import LazyLoad from 'react-lazy-load';
import './background_basic.css';

const ButtonLogin = styled.button({
  color: 'grey',
  background: 'white',
  'border-radius': '0.2em',
  border: '1px solid purple',
  color:'purple',
  'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
  'font-size': '100%',
  margin: '0.5em 1em 0 0.25em',
  '&:active':{transform: 'translateY(4px)'},
  '&:hover':{transform: 'translateY(2px)'},
  'box-shadow': '0.18em 0.22em black'
});  

const ButtonSelect = styled.button({
  color: 'grey',
  // background: 'transparent',
  background:'white',
  width: "25%",
  height: "50%",
  'border-radius': '0.5em',
  border: '1px solid purple',
  color:'purple',
  'font-family': 'Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif',
  'font-size': '100%',
  margin: '0.1em 2em 0 2em',
  padding: '0.1em 0em 0.1em 0em',
  '&:hover':{background: 'gold'},
  '&:hover':{transform: 'translateY(2px)'},
  '&:selection':{background: 'gold'}, 
  '&:active':{transform: 'translateY(4px)'},
  'box-shadow': '0.18em 0.22em black'
});

const Home = () => {
  const [options, setOptions] = useState([]);
  const [coors, setCoordinates] = useState([]);
  const [pointB, setPointB] = useState('')
  const token = getToken();
  const username = getUserName();
  useEffect(() => {
    document.title = "FivSix!"
  }, []);

  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: "/home" });
  },[]);

  const navigate = useNavigate();
  
  const routeChange = (event) =>{ 
    event.preventDefault();
    //console.log(sessionStorage.getItem('pointB'))
    var bext = coors[options.indexOf(sessionStorage.getItem('pointB'))]
    sessionStorage.setItem('d_lat', bext.split(',')[0])
    sessionStorage.setItem('d_lon', bext.split(',')[1])
    if (bext!=undefined){
      let path = '/home2'; 
      navigate(path);
    }
  }
  const handleClick = () => {
    let path = '/checklists_homepage'; 
    navigate(path);
  };

  useEffect(() => {
    fetch(cities)
      .then(response => response.text())
      .then(text => setOptions(text.split('\n').filter(city => city)))
      .catch(error => console.error(error));
    fetch(coordinates)
      .then(response => response.text())
      .then(text => setCoordinates(text.split('\n').filter(city => city)))
      .catch(error => console.error(error));
  }, []);
  const changeHandler = (event) =>{
    event.preventDefault();
    sessionStorage.setItem('pointB', pointB)
  }

    // const { ref } = usePlacesWidget({
    //   apiKey: 'AIzaSyD6-MW_M5vDBIBVG2dPw4sRwMowU-HPBUA',
    //   onPlaceSelected: (place) => {
    //     console.log(place);
    //   },
    //   options: {
    //     types: ["(regions)"],
    //     componentRestrictions: { country: "uk" },
    //   },
    // });
  

    return (<div>
        {/* <img className="foreground-image" src="architecture.webp"/> */}
      <div className='home-logo'>
  {/* <div className='heading-h10'>FivSix</div> */}
      
  {/* <br></br> */}
  {/* <div className='heading-h3'>Anonymous . Incognito . Personalised</div> */}
  </div>
  {token?<div className="home2-outer"><p className="heading-h1-user">Welcome {username} !</p></div>:null}
  <LazyLoad>
  <img class="main_image" src={logo}/>
  </LazyLoad>
  <br></br> 
  <br></br>
  {/* <div class='heading-h2-curve'>
  <ReactCurvedText 
      width={370}
      height={300}
      cx={190}
      cy={120}
      rx={150}
      ry={100}
      startOffset={0}
      text="Plan your trips just the way you like!"
    />
    </div> */}

  {/* <div className='heading-h2-curve'>Plan your trips just the way you like!</div> */}
  
  {/* <span className='home-logo'><div className='heading-h1'>Fivsix</div></span> */}

  <form id='firstrun' onSubmit={routeChange}>
  <div className="home-outer">
  
    <div class="autocomplete2">
   
    <Autocomplete disabled suggestions={options} />
</div>
</div>
    {/* <input  type="text" disabled id="PointB" name="PointB" placeholder="To where shall I make plans for?" />
    <input type="hidden" id="city2" name="city2" />
    <input type="hidden" id="cityLat" name="cityLat" />
    <input type="hidden" id="cityLng" name="cityLng" /> */}
    
    <br></br>
    <div className="home-outer">
  <ButtonLogin type="submit" name="Run" value="Plan" id="submitpointb" > Fivsix Plan </ButtonLogin>
  </div>
  {/* </div> */}
  
  <div className='home-logo'>
  <div className='heading-h1-home'>Plan your trips just the way you like!</div>
  <br></br>
  <br></br>
  <br></br>
  <span className="heading-h3">A digital twin powered travel planning tool for personalized itineraries</span>
  </div>
    </form>
    <div className='beta'>
    <p class="beta_warning"><span> Make your travel easier. Check out our new comprehensive travel checklists</span></p>
    <ButtonSelect onClick={handleClick}>Travel Checklists</ButtonSelect>
  {/* <p class="beta_warning"><span> This website is in an early access stage &#40;MVP&#41;. All elements may not work as intended. We are aiming to build a personalised solution for all your travel needs. Only a few European locations are allowed at the moment. Meanwhile, we ask any user wishing to provide us feedback to email us at <a href="mailto:info@fivsix.com">info@fivsix.com</a></span></p> */}
  {/* <p class="beta_warning"><span> Coming up next in v0.9.0 (30 Jan): Travel planning from your doorstep to Place B, with provision to add places to visit within Place B and get personalised hotel recommendations.</span></p> */}
  </div>
    </div>)
} 

export default Home;