import {BrowserRouter, NavLink, Route, Routes} from "react-router-dom";
import { Button, View, Text } from "react-native";
import React, {useEffect, useState} from "react";
import Home from "./Home";
import Home2 from "./Home2";
import Login from "./Login";
import User from "./User";
import About from "./About";
import { Helmet } from 'react-helmet';
import Register from "./Register";
import ExampleMap from "./results";
import LandingPage from "./LandingPage";
import ResultsOld from "./resultsold";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import Checklists from "./Checklists";
import ChecklistPageHomeList from "./Checklists_homelist";
import RockPaperScissors from "./RockPaperScissors";
import ChecklistsMenuPage from "./Checklists_homepage";
import InstagramIcon from '@mui/icons-material/Instagram';
import { getToken, getUserName, getRevolvingID, setUserSession, resetUserSession, getMoolah } from "./service/AuthService";
import CookieBot from "react-cookiebot";
import axios from "axios";
import logo from './Logo2.png';
import ReactGA from 'react-ga4';
import {FaInstagram} from 'react-icons/fa';
import posthog from 'posthog-js';
import GoogleAnalyticsInitializer from './GoogleAnalyticsInitializer';
import ChecklistPagePacking from "./Checklists_packing";
const verifyAPItokenUrl = 'https://66plz5argg.execute-api.eu-west-2.amazonaws.com/beta/api/v1/auth/';
const domainGroupId = '893596c4-822b-452d-a7cb-7fb38f651752';

const TRACKING_ID = "G-69HLY34YRW"; // OUR_TRACKING_ID
// if(window.Cookiebot.consent.statistics){
//   ReactGA.initialize(TRACKING_ID);
//   ReactGA.set({ anonymizeIp: true });
// }



posthog.init('phc_EMyzEUCr6uJRTkv4e0tEhoRC8dDGHb9MEsEdDaugD9J', { api_host: 'https://eu.posthog.com' })
posthog.capture('my event', { property: 'value' })


function App() {
  const [hasCookieBot, setHasCookieBot] = useState(undefined);
  useEffect(() => {
    document.title = "FivSix!"
  }, []);
  const [isAuthenticating, setAuthenticating] = useState(true);
  useEffect(() =>{
  const token = getToken();
  if (token === 'undefined' || token === undefined || token === null || !token){
    return;
  }

  const requestConfig = {
    headers: {
        'x-api-key': process.env.LOGIN_VAR
    }
}

const requestBody = {
  Token: token,
  UserName: getUserName(),
  RevolvingID: getRevolvingID(),
  Moolah: getMoolah()
}
axios.post(verifyAPItokenUrl, requestBody, requestConfig).then(response=>{
  setUserSession(response.data.username, response.data.rid, response.data.token, response.data.moolah);
  setAuthenticating(false);
}).catch(() => {
  resetUserSession();
  setAuthenticating(false);
})
}, []);
const mapIsReadyCallback = (map) => {
  console.log(map);
};

const token = getToken();
const moolah = getMoolah();
const username = getUserName();
if (isAuthenticating && token){
  return <div className="loading-content">Authenticating...</div>
}
  return (
    <div className="App">
      <BrowserRouter>
      <div className="header">
      <Helmet>
        <title>FivSix</title>
        <meta name="description" content="AI-powered personalised itinerary planning solutions to assist in convenient, safe, intelligent and economical travel. Be it daily trips, holiday plans, business trips or even your evening walk, it adds oomph! every time you step out!" />
        <meta property="og:title" content="FivSix" />
        <meta property="og:description" content="AI-powered personalised itinerary planning solutions to assist in convenient, safe, intelligent and economical travel. Be it daily trips, holiday plans, business trips or even your evening walk, it adds oomph! every time you step out!" />
        <meta property="og:image" content={logo} />
        <meta property="og:logo" content={logo} />
      </Helmet>

        <NavLink exact activeClassName="active" to='/'><img class="logo-left" src={logo}/></NavLink>
        <NavLink exact activeClassName="active" to='/about'>About</NavLink>
      </div>
        { !token &&
        <div className="rightstuff-public">
        {/*<NavLink activeClassName="active" disabled to='/'>Login</NavLink> to='/login'*/}
        {/*<NavLink activeClassName="active" disabled to='/'>Register</NavLink> to='/register'*/}
        </div>
        }
        { token &&
          <div className="rightstuff-public-activated">
          <div className="pointsbar"><span className="heading-h2">{username+'  '}<br/></span>
          {/* <span className="moolah">{moolah} Points</span> */}
          </div>
          <NavLink activeClassName="active" to='/User'>User</NavLink> 
          <NavLink activeClassName="active" onClick={resetUserSession}>Logout</NavLink>
          </div>
        }
        {/* <NavLink activeClassName="active" to="/register">Register</NavLink> */}
     
      <div className="content">
        <Routes>
          {/* <Route index element={<Home />}/> */}
          <Route index element={<LandingPage />}/>
          <Route path='/home' element={<Home />}/>
          <Route path='/home2' element={<Home2 />}/>
          <Route path='/about' element={<About />}/>
          {/* <Route path='/landing_page' element={<LandingPage />}/> */}
          {/* <Route path='/rockpaperscissors' element={<RockPaperScissors />}/> */}
          <Route path='/checklists_homepage' element={<ChecklistsMenuPage />}/>
          <Route path='/checklists' element={<Checklists />}/>
          <Route path='/checklists_homelist' element={<ChecklistPageHomeList />}/>
          <Route path='/checklists_packing' element={<ChecklistPagePacking />}/>
          <Route path='/results' element={<ExampleMap />}/>
          
          <Route element={<PublicRoute/>}>
          <Route path='/login' element={<Login />}/>
          <Route path='/register' element={<Register />}/>
          </Route>
          <Route element={<PrivateRoute/>}>
          <Route path='/user' element={<User/>}/>
          <Route path='/resultsold' element={<ResultsOld />}/>
          </Route>       
        </Routes>
      </div>
      <View style={{ flex: 1, alignItems: 'center' }}>
      <CookieBot domainGroupId={domainGroupId} />
      {/* <Text>Click to test Cookiebot</Text>
      <Button title="TEST" onPress={() => setHasCookieBot(!!document.querySelector('#CookieBot'))} /> */}
      {/* <Text style={{ color: 'red', marginVertical: 10 }}>
        {hasCookieBot && `Has CookieBot: ${JSON.stringify(hasCookieBot)}`}
      </Text> */}
    </View>
    <GoogleAnalyticsInitializer/>
    <div class="footer">
      <br></br>
      {/* <hr></hr> */}
{/* 
    BY USING THE SITE, YOU AGREE TO OUR <a href={process.env.PUBLIC_URL + "TC.htm"} >TERMS AND CONDITIONS</a> and <a href={process.env.PUBLIC_URL + "PP.htm"}>PRIVACY POLICY</a>; IF YOU DO NOT AGREE, PLEASE DO NOT USE THE SITE.
    <p>Contact us :<a href="mailto:info@fivsix.com">info@fivsix.com</a></p> */}

    <div className="home2-outer" onClick={() => window.open('https://www.instagram.com/fivsixed/', '_blank')} >
  <FaInstagram style={{height: '50px', width: '50px'}}/>
  </div>

    <span class='fineprint'>
    Copyright © 2023 NKCHAKSHU UK LTD. Background image by Freepik
    <br></br>
    <a href={process.env.PUBLIC_URL + "TC.htm"} >TERMS AND CONDITIONS</a> | <a href={process.env.PUBLIC_URL + "PP.htm"}>PRIVACY POLICY</a> | <a href={process.env.PUBLIC_URL + "legal.htm"} >DISCLAIMER</a>
    <br></br>
     By using the site, you agree to our <a href={process.env.PUBLIC_URL + "TC.htm"} >TERMS AND CONDITIONS</a>, <a href={process.env.PUBLIC_URL + "legal.htm"} >DISCLAIMER</a> and <a href={process.env.PUBLIC_URL + "PP.htm"}>PRIVACY POLICY</a>; if you do not agree, please do not use the site. 
     <br></br>
    

 

    </span>
    </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
